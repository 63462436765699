import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "../routes";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Main from "../containers/Main";
import ThemedSuspense from "../components/ThemedSuspense";
import { SidebarContext } from "../context/SidebarContext";
import ErrorModal from "../components/Modals/ErrorModal";
import { AuthContext } from "../context/AuthContext";
import ChildThemeWrapper, { useTheme } from "../context/ThemeContext";
import { roleName } from "../constants_map";
import { handleHelpClick } from "./helpers";
var Page404 = lazy(function () { return import("../pages/404"); });
function Layout() {
    var isSidebarOpen = useContext(SidebarContext).isSidebarOpen;
    var _a = useContext(AuthContext), showSessionExpiredModal = _a.showSessionExpiredModal, setShowSessionExpiredModal = _a.setShowSessionExpiredModal, logout = _a.logout, userRole = _a.userRole, collapsed = _a.collapsed;
    var theme = useTheme().theme;
    var location = useLocation();
    var isStudioModule = location.pathname.includes("/app/studio/");
    var errorModal = function () {
        logout();
        setShowSessionExpiredModal(false);
    };
    var loggedInRole = userRole === roleName.enterpriseAdmin
        ? "EA"
        : userRole === roleName.enterpriseUser
            ? "EU"
            : userRole === roleName.platformAdmin
                ? "PA"
                : "NA";
    var handleHelp = function () {
        handleHelpClick(location.pathname, loggedInRole);
    };
    var shouldShowHelpButton = location.pathname !== "/app/home" &&
        location.pathname !== "/app/billing-pricing/invoicing" &&
        location.pathname !== "/app/policy/dnd/msisdn/upload" &&
        location.pathname !== "/app/policy/QuietTime/_add" &&
        location.pathname !== "/app/Routing/SMS/_create";
    return (_jsxs("div", { className: "scrollbar-hide h-screen w-screen", style: {
            fontFamily: theme.font,
        }, children: [_jsx(Header, {}), _jsxs("div", { className: "flex flex-row h-full lg:relative  ".concat(isStudioModule ? "md:px-5" : "md:p-5", "  ").concat(isSidebarOpen && "overflow-hidden"), children: [_jsx("div", { className: "lg:fixed", children: _jsx(Sidebar, {}) }), _jsx("div", { className: "flex flex-col  flex-1 w-full ".concat(collapsed
                            ? "lg:ltr:ml-[70px] lg:rtl:mr-[70px]"
                            : "lg:ltr:ml-[201px] lg:rtl:mr-[201px]"), children: _jsx(Main, { children: _jsx(Suspense, { fallback: _jsx(ThemedSuspense, {}), children: _jsx(ChildThemeWrapper, { children: _jsxs(Routes, { children: [routes.map(function (route, i) {
                                                return route.component ? (_jsx(Route, { path: "".concat(route.path), element: _jsx(route.component, {}) }, i)) : null;
                                            }), _jsx(Route, { path: "/app", element: _jsx(Navigate, { to: "/app/home" }) }), _jsx(Route, { path: "*", element: _jsx(Page404, {}) })] }) }) }) }) }), shouldShowHelpButton && (_jsx("div", { className: "inline relative flex", style: {
                            position: "fixed",
                            right: "-12px",
                            top: "53%",
                        }, children: _jsxs("button", { onClick: handleHelp, className: "font-bold cursor-pointer inline", style: {
                                fontSize: "12px",
                                cursor: "pointer",
                                color: "white",
                                backgroundColor: theme.primaryColor,
                                display: "block",
                                padding: "2px 7px",
                                transform: "rotate(90deg)",
                                borderRadius: "5px",
                            }, children: [_jsx("span", { children: "H" }), _jsx("span", { children: "E" }), _jsx("span", { children: "L" }), _jsx("span", { children: "P" })] }) }))] }), _jsx(ErrorModal, { errorModal: showSessionExpiredModal, deleteError: t("_Session_expired._Please_login_again."), setErrorModal: errorModal, buttonTxt: "Log In" })] }));
}
export default Layout;
