var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
var ThemeContext = createContext();
export var useTheme = function () { return useContext(ThemeContext); };
import getIP from "../getIP.js";
var IP = getIP();
import { useQuery } from "react-query";
import { getGlobalConfigNoAuth } from "../lib/login-api.js";
export var ThemeProvider = function (_a) {
    var children = _a.children;
    var _b = useQuery(["noAuthConfig"], getGlobalConfigNoAuth, {
        onError: function (err) {
            console.log("error while getting config no auth: ", err);
        },
    }), configData = _b.data, isAuthConfigLoading = _b.isLoading;
    var _c = useState({
        font: "Nunito",
        logos: "".concat(IP, "/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg"),
        loginLogo: "".concat(IP, "/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg"),
        enableLoginLogo: "Enable",
        primaryColor: "#E00800",
        secondaryColor: "#353ef2",
        tertiaryColor: "#777578",
        sideMenuColor: "#2d2e42",
        backgroundColor: "#f5f5fa",
        tncUrl: "",
        policyUrl: "",
        backgroundImageLandingPage: "".concat(IP, "/static/media/comviva-background.18edf10ea57d4ea52fba.jpg"),
        announcementText: "Hello, Brand Setting page is being defined.........",
        announcementUrl: IP,
        announcementFlag: "Enable",
        faviconUrl: "".concat(IP, "/favicon.ico"),
        menuTextColor: "#FFFFFF",
        menuTextColorOnHover: "#ED1C24",
        radioCheckboxColor: "#353ef2",
        enablePolicy: "Disable",
        enableTerms: "Disable",
        selectedLanguages: [
            {
                title: "English",
                code: "en",
            },
        ],
        enableSignUp: "Enable",
        footer: "All rights reserved.",
        display: "",
        url: "",
        // primaryColor: "#4B0F1E",
        // secondaryColor: "#E00800",
        // fontFamily: "SuisseIntl",
        // tertiaryColor: "#232323",
        // sideMenuColor: "#4B0F1E",
        // backgroundColor: "white",
        // Add more theme properties as needed
    }), theme = _c[0], setTheme = _c[1];
    // useEffect(()=>{
    //   sessionStorage.setItem('theme', JSON.stringify(theme));
    // },[])
    var refetch = useQuery(["getBrandList"], fetchColorsFromApi, {
        enabled: !isAuthConfigLoading,
        onSuccess: function (data) {
            var _a, _b, _c, _d, _e;
            if (data) {
                var defaultSettings = {
                    font: "Nunito",
                    logos: "".concat(IP, "/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg"),
                    loginLogo: "".concat(IP, "/static/media/comviva-logo.45738fc35a5d95d312d062c1f0ff94cf.svg"),
                    enableLoginLogo: "Enable",
                    primaryColor: "#E00800",
                    secondaryColor: "#353ef2",
                    tertiaryColor: "#777578",
                    sideMenuColor: "#2d2e42",
                    backgroundColor: "#f5f5fa",
                    radioCheckboxColor: "#353ef2",
                    tncUrl: "",
                    policyUrl: "",
                    backgroundImageLandingPage: "".concat(IP, "/static/media/comviva-background.18edf10ea57d4ea52fba.jpg"),
                    announcementText: "Hello, Brand Setting page is being defined.........",
                    announcementUrl: IP,
                    announcementFlag: "Enable",
                    faviconUrl: "".concat(IP, "/favicon.ico"),
                    menuTextColor: "#FFFFFF",
                    menuTextColorOnHover: "#ED1C24",
                    enablePolicy: "Disable",
                    enableTerms: "Disable",
                    selectedLanguages: [
                        {
                            title: "English",
                            code: "en",
                        },
                    ],
                    enableSignUp: "Enable",
                    footer: "All rights reserved.",
                    display: "",
                    url: "",
                };
                var color = ((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.brandSettingJson) || defaultSettings;
                document.body.style.backgroundColor = color.backgroundColor;
                var bflag = ((_c = configData === null || configData === void 0 ? void 0 : configData.find(function (e) { return e.key === "bflag"; })) === null || _c === void 0 ? void 0 : _c.value) || "false";
                var defaultStyle = ((_d = configData === null || configData === void 0 ? void 0 : configData.find(function (e) { return e.key === "defaultStyleFlag"; })) === null || _d === void 0 ? void 0 : _d.value) ||
                    "true";
                var enableBackgroundLogo = ((_e = configData === null || configData === void 0 ? void 0 : configData.find(function (e) { return e.key === "enableBackgroundLogo"; })) === null || _e === void 0 ? void 0 : _e.value) ||
                    "true";
                var flags = {};
                flags.bflag = bflag;
                flags.defaultStyle = defaultStyle;
                flags.enableBackgroundLogo = enableBackgroundLogo;
                var linkElement = document.querySelector("link[rel*='icon']") ||
                    document.createElement("link");
                linkElement.type = "image/png";
                linkElement.rel = "icon";
                linkElement.href = color.faviconUrl;
                document.title =
                    color.enableLabel === "true" ? color.labelName : "CPaaS";
                document.head.appendChild(linkElement);
                setTheme(__assign(__assign(__assign({}, defaultSettings), color), flags));
                document.documentElement.style.setProperty("--primaryColor", color.primaryColor);
                document.documentElement.style.setProperty("--secondaryColor", color.secondaryColor);
                document.documentElement.style.setProperty("--tertiaryColor", color.tertiaryColor);
                document.documentElement.style.setProperty("--backgroundColor", color.backgroundColor);
                sessionStorage.setItem("theme", JSON.stringify(__assign(__assign(__assign({}, defaultSettings), color), flags)));
                setIsThemeLoading(false);
            }
            else {
                var res = sessionStorage.getItem("theme");
                if (!res) {
                    sessionStorage.setItem("theme", JSON.stringify(theme));
                }
                else {
                    setTheme(JSON.parse(res));
                }
                setIsThemeLoading(false);
            }
        },
        onError: function () {
            var res = sessionStorage.getItem("theme");
            if (!res) {
                sessionStorage.setItem("theme", JSON.stringify(theme));
            }
            else {
                setTheme(JSON.parse(res));
            }
            setIsThemeLoading(false);
        },
    }).refetch;
    var _d = useState(true), isThemeLoading = _d[0], setIsThemeLoading = _d[1];
    return (_jsx(ThemeContext.Provider, { value: { theme: theme, setTheme: setTheme, isThemeLoading: isThemeLoading, isAuthConfigLoading: isAuthConfigLoading, refetch: refetch }, children: children }));
};
function fetchColorsFromApi() {
    return __awaiter(this, void 0, void 0, function () {
        var IP, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    IP = getIP();
                    return [4 /*yield*/, axios("".concat(IP) + "/api/v1/config/brand")];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
            }
        });
    });
}
export default ThemeProvider;
