var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
export var multiStepFormContext = React.createContext();
var StepContext = function (_a) {
    var children = _a.children;
    var _b = useState(1), currentStep = _b[0], setCurrentStep = _b[1];
    var _c = useState([]), stepsCompleted = _c[0], setStepsCompleted = _c[1];
    var _d = useState([]), formData = _d[0], setFormData = _d[1];
    var _e = useState([]), finalData = _e[0], setFinalData = _e[1];
    var _f = useState({}), WAdynamicVarObj = _f[0], setWAdynamicVarObj = _f[1];
    var _g = useState({}), aiComposerSmsChannelData = _g[0], setAiComposerSmsChannelData = _g[1];
    var _h = useState(null), markerPosition = _h[0], setMarkerPosition = _h[1];
    var handleNextClick = function (increment) {
        var jumpNextStepBy = increment || 1;
        setCurrentStep(currentStep + jumpNextStepBy);
        if (jumpNextStepBy > 1) {
            stepsCompleted.splice(currentStep - 1, 5, currentStep);
        }
        else {
            if (!stepsCompleted.some(function (x) { return x === currentStep; }))
                setStepsCompleted(__spreadArray(__spreadArray([], stepsCompleted, true), [currentStep], false));
        }
        // else () {
        //
        // }
    };
    var handleCancelClick = function () {
        setCurrentStep(1);
        setStepsCompleted([]);
        setFormData([]);
        setFinalData([]);
    };
    var handlePrevClick = function () {
        var index = stepsCompleted.indexOf(currentStep);
        if (currentStep !== 1) {
            if (index.toString() === "-1") {
                setCurrentStep(stepsCompleted[stepsCompleted.length - 1]);
            }
            else {
                setCurrentStep(stepsCompleted[index - 1]);
            }
        }
    };
    return (_jsx("div", { children: _jsx(multiStepFormContext.Provider, { value: {
                currentStep: currentStep,
                setCurrentStep: setCurrentStep,
                stepsCompleted: stepsCompleted,
                setStepsCompleted: setStepsCompleted,
                formData: formData,
                setFormData: setFormData,
                finalData: finalData,
                setFinalData: setFinalData,
                handleNextClick: handleNextClick,
                handlePrevClick: handlePrevClick,
                handleCancelClick: handleCancelClick,
                aiComposerSmsChannelData: aiComposerSmsChannelData,
                setAiComposerSmsChannelData: setAiComposerSmsChannelData,
                WAdynamicVarObj: WAdynamicVarObj,
                setWAdynamicVarObj: setWAdynamicVarObj,
                markerPosition: markerPosition,
                setMarkerPosition: setMarkerPosition,
            }, children: children }) }));
};
export default StepContext;
