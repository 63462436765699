import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { t } from "i18next";
import error from "../../assets/img/error.svg";
import { useTheme } from "../../context/ThemeContext";
var ErrorModal = function (props) {
    var styles = {
        formStyle: "grid grid-col border p-10 rounded-lg place-self-center bg-white max-h-[319px] min-w-[400px] max-w-[417px] w-fit place-items-center",
    };
    var theme = useTheme().theme;
    return (_jsx(_Fragment, { children: props.errorModal && (_jsx("div", { className: "modal fade fixed z-10 w-screen inset-0 bg-black bg-opacity-40 h-screen flex place-items-center justify-center shadow-md outline-none", children: _jsxs("div", { className: styles.formStyle, children: [_jsx("div", { children: _jsx("img", { src: error, className: "w-12 h-12", alt: "error" }) }), _jsx("div", { className: "font-bold text-xl mt-5", children: props.title ? props.title : (props.isWarning ? t("_Warning") : t("_Failed")) }), _jsx("div", { className: "text-base break-words mt-3 text-[#7B7B7B] font-semibold text-center", children: props.deleteError
                            ? props.deleteError
                            : t("_Sorry!_Something_went_wrong._Please_try_again") }), !props.hideShowAgain && (_jsx("div", { children: _jsx("button", { style: { backgroundColor: theme.primaryColor }, className: " rounded-full font-bold text-sm px-10 py-3 text-white mt-6", onClick: function () {
                                props.setErrorModal(false);
                            }, children: props.buttonTxt || t("_TRY_AGAIN") }) }))] }) })) }));
};
export default ErrorModal;
