import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../../containers/Layout";
// import HeaderLayout from "../../containers/headerLayout";
import { AuthContext } from "../../context/AuthContext";
import SessionExpireModal from "../Modals/SessionExpireModal";
export default function ProtectedRoute() {
    var _a = useContext(AuthContext), user = _a.user, isAppTimeout = _a.isAppTimeout;
    return _jsx(_Fragment, { children: user !== null ? _jsxs(_Fragment, { children: [_jsx(Layout, {}), isAppTimeout && _jsx(SessionExpireModal, {})] }) : _jsx(Navigate, { to: "/auth" }) });
}
