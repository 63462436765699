var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ErrorModal from "../components/Modals/ErrorModal";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, modal: true, errorPath: '' };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function () {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, modal: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        console.log('errorinfo', info);
        console.error(error);
        // @todo: Call api logger serice, to store error in backend db, to keep track of gui errors
        //   logErrorToMyService(error, info.componentStack);
    };
    ErrorBoundary.prototype.render = function () {
        var _this = this;
        if (this.state.hasError) {
            if (this.props.fallback) {
                return this.props.fallback;
            }
            return (_jsx(ErrorModal, { errorModal: this.state.modal, setErrorModal: function () {
                    _this.setState({ modal: false, hasError: false, errorPath: window.location.pathname });
                    // window.location.href = '/app/home';
                } }));
        }
        if (this.state.errorPath && window.location.pathname === this.state.errorPath) {
            return null;
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
