export var hexToRgb = function (hex) {
    // Remove the leading '#' if present
    hex = hex === null || hex === void 0 ? void 0 : hex.replace(/^#/, "");
    // Parse the hex value into its RGB components
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
    return "".concat(r, ", ").concat(g, ", ").concat(b);
};
