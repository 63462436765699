var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { ReactComponent as ArrowupIcon } from "../../icons/arrowupicon.svg";
import { ReactComponent as DropdownIcon } from "../../icons/dropdownn.svg";
import * as Icons from "../../icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";
function Icon(_a) {
    var icon = _a.icon, props = __rest(_a, ["icon"]);
    var Icon = Icons[icon];
    return Icon ? _jsx(Icon, __assign({}, props)) : _jsx("div", { className: "px-6", children: "I" });
}
var MENU = function (_a) {
    var _b, _c;
    var route = _a.route;
    var _d = React.useState(false), subRoute = _d[0], setSubRoute = _d[1];
    var _e = React.useState(0), subRouteIndex = _e[0], setSubRouteIndex = _e[1];
    var collapsed = useContext(AuthContext).collapsed;
    var t = useTranslation().t;
    var theme = useTheme().theme;
    var showSubRoutes = function () {
        setSubRoute(!subRoute);
    };
    var hexToRgb = function (hex) {
        // Remove the leading '#' if present
        hex = hex === null || hex === void 0 ? void 0 : hex.replace(/^#/, "");
        // Parse the hex value into its RGB components
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return "".concat(r, ", ").concat(g, ", ").concat(b);
    };
    return (_jsxs("div", { className: "flex flex-col ".concat(subRoute ? "".concat(theme.sideMenuColor) : null), children: [_jsx("div", { className: "flex-row cursor-pointer ", style: { color: theme.menuTextColor }, onClick: route.subRoutes && showSubRoutes, children: _jsxs(NavLink, { onClick: function () {
                        setSubRouteIndex(0);
                    }, to: (route === null || route === void 0 ? void 0 : route.subRoutes) && ((_b = route === null || route === void 0 ? void 0 : route.subRoutes) === null || _b === void 0 ? void 0 : _b.length) > 0
                        ? route.subRoutes[subRouteIndex].path
                        : route.path, style: function (_a) {
                        var isActive = _a.isActive;
                        return isActive
                            ? {
                                color: theme.menuTextColorOnHover,
                                background: "".concat(!route.subRoutes
                                    ? "rgba(".concat(hexToRgb(theme === null || theme === void 0 ? void 0 : theme.menuTextColorOnHover), ", 0.05)")
                                    : null),
                            }
                            : { color: theme.menuTextColor };
                    }, className: "flex py-[10px]", children: [_jsx(Icon, { className: "ml-[21px] mr-[15px] h-[18px] w-[17px]", icon: route.icon }), !collapsed && (_jsxs(_Fragment, { children: [_jsx("h1", { className: "text-xs mt-0.5 text-wrap w-28 cursor-pointer", children: t("".concat(route.name)) }), route.subRoutes && subRoute ? (_jsx("div", { className: "flex flex-row-reverse flex-grow self-center mx-5", children: _jsx(ArrowupIcon, { className: "w-[10px] h-[6px]" }) })) : route.subRoutes ? (_jsx("div", { className: "flex flex-row-reverse flex-grow self-center mx-5", children: _jsx(DropdownIcon, { className: "w-[10px] h-[6px]" }) })) : null] }))] }) }), (route === null || route === void 0 ? void 0 : route.subRoutes) &&
                subRoute &&
                ((_c = route === null || route === void 0 ? void 0 : route.subRoutes) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                !collapsed
                ? route.subRoutes.map(function (subRoute, i) {
                    // route.path+subRoute.path
                    return (_jsxs("div", { className: "mb-[5px]", children: [_jsx(NavLink, { onClick: function () { return setSubRouteIndex(i); }, to: subRoute.path, style: function (_a) {
                                    var isActive = _a.isActive;
                                    return isActive
                                        ? { color: theme.menuTextColorOnHover }
                                        : { color: theme.menuTextColor };
                                }, children: _jsx("h1", { className: "text-xs ltr:ml-[52px] rtl:mr-[52px] cursor-pointer ".concat(subRoute ? "inline-block" : "hidden"), children: t("".concat(subRoute.name)) }) }, "".concat(subRoute.name).concat(i)), _jsx(Routes, { children: _jsx(Route, { path: subRoute.path, exact: route.exact }) })] }, "".concat(subRoute.name).concat(i)));
                })
                : null] }));
};
export default MENU;
