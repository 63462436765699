var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// src/mocks/handlers.js
//import { data } from "autoprefixer";
import { rest } from "msw";
var roles = [];
var invoiceResponse = {
    totalRecords: 10,
    pgIndex: 1,
    pgSize: 5,
    data: [
        {
            id: 0,
            customerName: "XYZ enterprises",
            contactName: "Tommy Robinson",
            contactNumber: "+353495782456",
            status: 1, // active
            lastInvoiceDt: "2022-11-30T13:26:33+05:30",
        },
        {
            id: 1,
            customerName: "Global Connect",
            contactName: "Henry Hill",
            contactNumber: "8654872101",
            status: 0, // active
            lastInvoiceDt: "2022-11-29T17:26:35+05:30",
        },
        {
            id: 2,
            customerName: "ABC Enterprises",
            contactName: "Adam Jones",
            contactNumber: "7569845210",
            status: 1, // active
            lastInvoiceDt: "2022-11-26T17:26:30+05:30",
        },
    ],
};
export var handlers = [
    rest.post("/login", function (req, res, ctx) {
        if (req.body.password === "" || !req.body.password) {
            return res(ctx.status(400), ctx.json({
                errorMessage: "password not found",
            }));
        }
        return res(ctx.status(200));
    }),
    rest.get("/user", function (req, res, ctx) {
        return res(ctx.status(200), ctx.json({
            username: "admin",
        }));
    }),
    //rolesapi
    //create roles
    rest.post("/accounts/{accountId}/roles/", function (req, res, ctx) {
        var _a;
        if (req.body.rolename === "" ||
            req.body.template === "" ||
            !req.body.template ||
            !req.body.rolename ||
            req.body.grants == [] ||
            !req.body.grants) {
            return res(ctx.status(400), ctx.json({
                errorMessage: "Missing required parameter",
            }));
        }
        var id = ((_a = roles[roles.length - 1]) === null || _a === void 0 ? void 0 : _a.id) + 1 || 1;
        var newItem = {
            id: id.toString(),
            rolename: req.body.rolename,
            templatename: req.body.templatename,
            grants: req.body.grants,
            // completed: false
        };
        roles = __spreadArray(__spreadArray([], roles, true), [newItem], false);
        return res(ctx.status(200), ctx.json({ msg: "roles created successfully" }));
    }),
    //Number Mock APIs
    rest.get("/numbers", function (req, res, ctx) {
        return res(ctx.status(200), ctx.json({
            id: 1,
            number: "831 2485347",
            numberCat: "Premium",
            country: "India",
            type: "Landline",
            capabalities: ["SMS", "VOICE"],
            mrc: "30",
            otc: "30",
            sms: "260",
            smsMt: "200",
            smsMo: "60",
            out: "60",
            in: "20",
            voice: "80",
            mms: "",
            campaigns: ["testcampaign", "campaign1"],
        }));
    }),
    //Returns the list of customers having recent transactions with the system.
    rest.get("/invoices/customers", function (req, res, ctx) {
        return res(ctx.status(200), ctx.json(invoiceResponse));
    }),
    rest.get("/invoices/customers/:id", function (req, res, ctx) {
        var response = [
            {
                id: 1,
                customerName: "Microtech Digital",
                status: "DEACTIVATED",
                createdDate: "2020-10-23T05:27:40.000Z",
                industry: "Telecom",
                companyInfo: {
                    contactName: "Adam Jones",
                    companyName: "XYZ Enterprises",
                    email: "ajones_1@gmail.com",
                    address: "No. 214, Lavell Road, Bangalore",
                },
                contactInfo: {
                    contactName: "Thomas",
                    vpaNo: "1254548",
                    countryCode: "91",
                    mobileNumber: "9856212344",
                    email: "thomas1@gmail.com",
                },
            },
            {
                id: 2,
                customerName: "Microtech Digital",
                status: "DEACTIVATED",
                createdDate: "2020-10-23T05:27:40.000Z",
                industry: "Telecom",
                companyInfo: {
                    contactName: "Henry Hill",
                    companyName: "Global Connect",
                    email: "ajones_1@gmail.com",
                    address: "No. 214, Lavell Road, Bangalore",
                },
                contactInfo: {
                    contactName: "Henry Hill",
                    vpaNo: "1254548",
                    countryCode: "91",
                    mobileNumber: "9856212344",
                    email: "thomas1@gmail.com",
                },
            },
            {
                id: 3,
                customerName: "ABC Enterprises",
                status: "DEACTIVATED",
                createdDate: "2020-10-23T05:27:40.000Z",
                industry: "Telecom",
                companyInfo: {
                    contactName: "Adam Jones",
                    companyName: "ABC Enterprises",
                    email: "ajones_1@gmail.com",
                    address: "No. 214, Lavell Road, Bangalore",
                },
                contactInfo: {
                    contactName: "Thomas",
                    vpaNo: "1254548",
                    countryCode: "91",
                    mobileNumber: "7569845210",
                    email: "thomas1@gmail.com",
                },
            },
        ];
        return res(ctx.status(200), ctx.json(response[req.params.id]));
    }),
    rest.get("/invoices/customers/:id/history", function (req, res, ctx) {
        var sampleInvoiceHistory = {
            data: [
                {
                    id: 1,
                    invoiceNo: "INV23156546",
                    invoiceDate: "2020-10-23T05:27:40.000Z",
                    transactionId: "12587856445",
                    amount: "$4,000",
                    paymentMode: "Payment Gateway",
                },
                {
                    id: 2,
                    invoiceNo: "INV28556323",
                    invoiceDate: "2021-10-23T05:27:40.000Z",
                    transactionId: "25842456445",
                    amount: "$3,058",
                    paymentMode: "Payment Gateway",
                },
                {
                    id: 3,
                    invoiceNo: "INV12357885",
                    invoiceDate: "2020-09-23T05:27:40.000Z",
                    transactionId: "12587856445",
                    amount: "$2,540",
                    paymentMode: "Payment Gateway",
                },
                {
                    id: 4,
                    invoiceNo: "INV21365490",
                    invoiceDate: "2020-10-23T05:27:40.000Z",
                    transactionId: "12587856445",
                    amount: "$4,000",
                    paymentMode: "Payment Gateway",
                },
                {
                    id: 5,
                    invoiceNo: "INV43852207",
                    invoiceDate: "2020-10-23T05:27:40.000Z",
                    transactionId: "12587856445",
                    amount: "$3,058",
                    paymentMode: "Payment Gateway",
                },
            ],
        };
        return res(ctx.status(200), ctx.json(sampleInvoiceHistory));
    }),
    rest.get("/invoices/:id/_view", function (req, res, ctx) {
        return res(ctx.status(200));
    }),
    rest.get("/invoices/customers/:id/transactionhistory", function (req, res, ctx) {
        var data = [
            {
                date: "15/09/2022",
                transactionId: "TNS3156546",
                description: "Credit received from payment Gateway",
                amount: "$123",
                availableBalance: "$123",
                Type: "Credit",
            },
            {
                date: "15/09/2022",
                transactionId: "TNS3156546",
                description: "Credit received from Enterprise Admin2",
                amount: "$123",
                availableBalance: "$123",
                Type: "Credit",
            },
            {
                date: "15/09/2022",
                transactionId: "TNS3156546",
                description: "Credit received from Enterprise Admin2",
                amount: "$123",
                availableBalance: "$123",
                Type: "Credit",
            },
            {
                date: "15/09/2022",
                transactionId: "TNS3156546",
                description: "Credit received from Enterprise Admin2",
                amount: "$123",
                availableBalance: "$123",
                Type: "Credit",
            },
            {
                date: "15/09/2022",
                transactionId: "TNS3156546",
                description: "Credit received from Enterprise Admin2",
                amount: "$123",
                availableBalance: "$123",
                Type: "Credit",
            },
        ];
        return res(ctx.status(200), ctx.json(data));
    }),
];
