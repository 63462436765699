import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo } from 'react';
import Snackbar from '../components/Snackbar/Snackbar';
// create context
export var SnackbarContext = React.createContext();
export var SnackbarProvider = function (_a) {
    var children = _a.children;
    var _b = useState('Loading..'), message = _b[0], setMessage = _b[1];
    var _c = useState('neutral'), type = _c[0], setType = _c[1];
    var _d = useState(false), isSnackbarOpen = _d[0], setIsSnackbarOpen = _d[1];
    var value = useMemo(function () {
        var timeoutRef = null;
        var openSnackbar = function (message) {
            clearTimeout(timeoutRef);
            setMessage(message);
            setType('neutral');
            setIsSnackbarOpen(true);
        };
        var closeSnackbar = function () {
            setMessage('Done!');
            setType('success');
            timeoutRef = setTimeout(function () {
                setIsSnackbarOpen(false);
            }, 1000);
        };
        return ({
            openSnackbar: openSnackbar,
            closeSnackbar: closeSnackbar,
        });
    }, []);
    return (_jsxs(SnackbarContext.Provider, { value: value, children: [_jsx(Snackbar, { isOpen: isSnackbarOpen, message: message, type: type }), children] }));
};
