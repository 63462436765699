import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import ErrorModal from './ErrorModal';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
var SessionExpireModal = function () {
    var _a = useContext(AuthContext), isAppTimeout = _a.isAppTimeout, setUser = _a.setUser;
    var navigate = useNavigate();
    var t = useTranslation().t;
    return (_jsx(ErrorModal, { title: t("_Logged_out"), deleteError: t("_Session_expired._Please_login_again."), errorModal: isAppTimeout, buttonTxt: t('_Sign_In'), setErrorModal: function () {
            setUser(null);
            navigate('/auth');
        } }));
};
export default SessionExpireModal;
