import { ReactComponent as ButtonsIcon } from "./buttons.svg";
import { ReactComponent as CardsIcon } from "./cards.svg";
import { ReactComponent as ChartsIcon } from "./charts.svg";
import { ReactComponent as FormsIcon } from "./forms.svg";
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as ModalsIcon } from "./modals.svg";
import { ReactComponent as PagesIcon } from "./pages.svg";
import { ReactComponent as TablesIcon } from "./tables.svg";
import { ReactComponent as HeartIcon } from "./heart.svg";
import { ReactComponent as EditIcon } from "./pencil.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as ForbiddenIcon } from "./forbidden.svg";
import { ReactComponent as GithubIcon } from "./github.svg";
import { ReactComponent as TwitterIcon } from "./twitter.svg";
import { ReactComponent as MailIcon } from "./mail.svg";
import { ReactComponent as CartIcon } from "./cart.svg";
import { ReactComponent as MoneyIcon } from "./money.svg";
import { ReactComponent as PeopleIcon } from "./people.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as MoonIcon } from "./moon.svg";
import { ReactComponent as SunIcon } from "./sun.svg";
import { ReactComponent as BellIcon } from "./bell.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as DropdownIcon } from "./dropdown.svg";
import { ReactComponent as OutlinePersonIcon } from "./outlinePerson.svg";
import { ReactComponent as OutlineCogIcon } from "./outlineCog.svg";
import { ReactComponent as OutlineLogoutIcon } from "./outlineLogout.svg";
import { ReactComponent as DashboardIcon } from "./dashboard.svg";
import { ReactComponent as ReportsIcon } from "./reportsIcon.svg";
import { ReactComponent as ContactsIcon } from "./contactsIcon.svg";
import { ReactComponent as NumbersIcon } from "./numbersIcon.svg";
import { ReactComponent as SmsIcon } from "./smsIcon.svg";
import { ReactComponent as WhatsappIcon } from "./whatsappIcon_.svg";
import { ReactComponent as DevtoolIcon } from "./devtoolIcon.svg";
import { ReactComponent as NotificationIcon } from "./notificationIcon.svg";
import { ReactComponent as ThreeVerticalDots } from "./threeVerticalDots.svg";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import { ReactComponent as BillingIcon } from "./billingIcon.svg";
import { ReactComponent as Enableclone } from "./enableclone.svg";
import { ReactComponent as VoiceIcon } from "./voice.svg";
import { ReactComponent as CloudIcon } from "./cloud.svg";
import { ReactComponent as PhoneForwardedIcon } from "./phoneforwarded.svg";
import { ReactComponent as ChatIcon } from "./chat.svg";
import { ReactComponent as PhoneBackwardIcon } from "./phonebackward.svg";
import { ReactComponent as ContactIcon } from "./contactIcon.svg";
import { ReactComponent as UserIcon } from "./user.svg";
import { ReactComponent as DownloadNewIcon } from "./download-new.svg";
import { ReactComponent as MyPlanIcon } from "./myplan.svg";
import { ReactComponent as InsightsIcon } from "./insights.svg";
import { ReactComponent as AccountsIcon } from "./Accounts.svg";
import { ReactComponent as AccountsActiveIcon } from "./accountsActiveIcon.svg";
import { ReactComponent as BlueDownloadIcon } from "./BlueDownloadIcon.svg";
import { ReactComponent as GreyDownloadIcon } from "./GreyDownloadIcon.svg";
import { ReactComponent as TextGrayIcon } from "./gray-text-icon.svg";
import { ReactComponent as StudioIcon } from "./StudioIcon.svg";
import { ReactComponent as EmailIcon } from "./mail.svg";
import { ReactComponent as ChatbotIcon } from "./chatbot.svg";
import { ReactComponent as RcsIcon } from "./rcs.svg";
import { ReactComponent as VRIcon } from "./vr.svg";
import { ReactComponent as RightArrow } from "./right-arrow-blue.svg";
import { ReactComponent as UploadIcon } from "./upload.svg";
import { ReactComponent as BrandSettingsUploadLogo } from "./BrandSettingsUploadLogo.svg";
import { ReactComponent as TemplateIcon } from "./template.svg";
import { ReactComponent as WalletIcon } from "./wallet.svg";
import { ReactComponent as CreditIcon } from "./Creditunits.svg";
import { ReactComponent as DebitIcon } from "./Debitunits.svg";
import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { ReactComponent as AddIcon } from "../icons/addCircle.svg";
export { CloseIcon, AddIcon, UserIcon, ContactIcon, BillingIcon, ButtonsIcon, CardsIcon, ChartsIcon, FormsIcon, HomeIcon, ModalsIcon, PagesIcon, TablesIcon, HeartIcon, EditIcon, TrashIcon, ForbiddenIcon, GithubIcon, TwitterIcon, MailIcon, CartIcon, ChatIcon, MoneyIcon, PeopleIcon, SearchIcon, MoonIcon, SunIcon, BellIcon, MenuIcon, DropdownIcon, OutlinePersonIcon, OutlineCogIcon, OutlineLogoutIcon, NotificationIcon, ThreeVerticalDots, DashboardIcon, ReportsIcon, ContactsIcon, NumbersIcon, SmsIcon, DevtoolIcon, WhatsappIcon, CalendarIcon, Enableclone, VoiceIcon, CloudIcon, PhoneForwardedIcon, PhoneBackwardIcon, DownloadNewIcon, MyPlanIcon, InsightsIcon, AccountsIcon, AccountsActiveIcon, BlueDownloadIcon, GreyDownloadIcon, TextGrayIcon, DeleteIcon, StudioIcon, EmailIcon, ChatbotIcon, RcsIcon, VRIcon, RightArrow, UploadIcon, BrandSettingsUploadLogo, TemplateIcon, WalletIcon, CreditIcon, DebitIcon, };
