var origin = window.location.origin;
var urlConfig = {
    homePage: {
        PA: "".concat(origin, "/Admin/Modifying_My_Profile.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Viewing_Home_Page.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Viewing_Home_Page.htm"),
    },
    usageReports: {
        PA: "".concat(origin, "/Admin/Generating_Reports.htm"),
        EU: "".concat(origin, "/Account/Reports.htm"),
        EA: "".concat(origin, "/Account/Reports.htm"),
    },
    insightsDashboard: {
        PA: "".concat(origin, "/Admin/Home_Page.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Viewing_Dashboard.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Viewing_Dashboard.htm"),
    },
    smsSenderAddress: {
        PA: "".concat(origin, "/Admin/Managing_Sender_IDs.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Sender_Addresses.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Sender_Addresses.htm"),
    },
    smsSenderAddressPA: {
        PA: "".concat(origin, "/Admin/Managing_Sender_IDs.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Sender_Addresses.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Sender_Addresses.htm"),
    },
    smsTemplates: {
        PA: "".concat(origin, "/Admin/Viewing_Templates.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Message_Templates/Viewing_Template.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Message_Templates/Managing_Message_Templates.htm"),
    },
    smsCampaigns: {
        PA: "".concat(origin, "/Admin/Campaign_Management.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Campaigns.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Campaigns.htm"),
    },
    smppSession: {
        PA: "".concat(origin, "/Admin/Campaign_Management.htm"),
        EU: "".concat(origin, "/Admin/Campaign_Management.htm"),
        EA: "".concat(origin, "/Account/Managing_SMPP.htm"),
    },
    voiceCampaign: {
        PA: "".concat(origin, "/Admin/Viewing_Voice_Campaigns.htm"),
        EU: "".concat(origin, "/User/Viewing_Voice_Campaigns.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Message_Templates/Voice_Management.htm"),
    },
    voiceFileManager: {
        PA: "",
        EU: "".concat(origin, "/User/Web-based_Operations/Campaign_Management/Uploading_Audio_Files.htm"),
        EA: "",
    },
    whatsappProfile: {
        PA: "".concat(origin, "/Admin/Viewing_WhatsApp_Profile.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/WhatsApp_Profile_Management.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/WhatsApp_Profile_Management/WhatsApp_Profile_Management.htm"),
    },
    whatsappProfilePA: {
        PA: "".concat(origin, "/Admin/Viewing_WhatsApp_Profile.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/WhatsApp_Profile_Management.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/WhatsApp_Profile_Management/WhatsApp_Profile_Management.htm"),
    },
    whatsappTemplates: {
        PA: "".concat(origin, "/Admin/Viewing_WhatsApp_Templates_List.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/WhatsApp_Template_Management.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/WhatsApp_Template_Management%2FWhatsApp_Template_Management.htm"),
    },
    whatsAppCampaign: {
        PA: "".concat(origin, "/Admin/Viewing_WhatsApp_Campaign_List.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/WhatsApp_Campaign_Management.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/WhatsApp_Campaign_Management/WhatsApp_Campaign_Management.htm"),
    },
    studioDashboard: {
        EU: "".concat(origin, "/docs/StudioEU/Studio/Studio_Operations/Studio_Operations.htm"),
        EA: "".concat(origin, "/docs/StudioEA/Web-based_Operations/Studio/Studio_Operations/Studio_Operations.htm"),
    },
    packages: {
        PA: "".concat(origin, "/Admin/Package_Management.htm"),
        EU: "".concat(origin, "/User/Buying_Packages_-_SOB.htm"),
        EA: "".concat(origin, "/Account/Buying_Packages_-_SOB.htm"),
    },
    ratesheets: {
        PA: "".concat(origin, "/Admin/Rate_Sheet_Management.htm"),
        EU: "".concat(origin, "/User/Viewing_Rate_List.htm"),
        EA: "".concat(origin, "/Account/Viewing_Rate_List.htm"),
    },
    roles: {
        PA: "".concat(origin, "/Admin/Creating_Roles.htm"),
        EU: "".concat(origin, "/User/Role_Management.htm"),
        EA: "".concat(origin, "/Account/Role_Management.htm"),
    },
    users: {
        PA: "".concat(origin, "/Admin/managing_cp_users.htm"),
        EU: "".concat(origin, "/User/Managing_Users.htm"),
        EA: "".concat(origin, "/Account/Managing_Users.htm"),
    },
    distributionList: {
        PA: "",
        EU: "".concat(origin, "/User/Management_of_Distribution_List.htm"),
        EA: "".concat(origin, "/Account/Management_of_Distribution_List.htm"),
    },
    contactList: {
        PA: "",
        EU: "".concat(origin, "/User/Web-based_Operations%2FManaging_Contacts/Creating_Contact.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Contacts/Managing_Contacts.htm"),
    },
    contactGroup: {
        PA: "",
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Groups/Managing_Groups.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Groups/Managing_Groups.htm"),
    },
    segmentation: {
        PA: "",
        EU: "",
        EA: "",
    },
    logsActivity: {
        PA: "".concat(origin, "/Admin/Viewing_Platform_Admin_Activity_Logs.htm"),
        EU: "".concat(origin, "/User/Viewing_Activities.htm"),
        EA: "".concat(origin, "/Account/Viewing_Activities.htm"),
    },
    logsDebug: {
        PA: "".concat(origin, "/Admin/Viewing_Logs.htm"),
        EU: "".concat(origin, "/User/Viewing_Logs.htm"),
        EA: "".concat(origin, "/Account/Viewing_Logs.htm"),
    },
    logsEnterprise: {
        PA: "".concat(origin, "/Admin/Viewing_Enterprise_Logs.htm"),
        EU: "",
        EA: "",
    },
    notificationPA: {
        PA: "".concat(origin, "/Admin/Viewing_Alerts.htm"),
        EU: "".concat(origin, "/User/Creating_Alerts.htm"),
        EA: "".concat(origin, "/Account/Creating_Alerts.htm"),
    },
    numbersEA: {
        PA: "".concat(origin, "/Admin/Managing_Candidates.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Numbers.htm"),
        EA: "".concat(origin, "/Account/Viewing_Number_List.htm"),
    },
    reportsPA: {
        PA: "".concat(origin, "/Admin/Generating_Reports.htm"),
        EU: "",
        EA: "",
    },
    notification: {
        PA: "".concat(origin, "/Admin/Configuring_Notification_Messages.htm"),
        EU: "".concat(origin, "/User/Viewing_Notifications.htm"),
        EA: "".concat(origin, "/Account/Viewing_Notifications.htm"),
    },
    numbersPA: {
        PA: "".concat(origin, "/Admin/Managing_Candidates.htm"),
    },
    rateSheetPA: {
        PA: "".concat(origin, "/Admin/Rate_Sheet_Management.htm"),
    },
    packagesPA: {
        PA: "".concat(origin, "/Admin/Package_Management.htm"),
    },
    walletsPA: {
        PA: "".concat(origin, "/Admin/Transferring_Credits.htm"),
    },
    invoicingPA: {
        PA: "",
    },
    accountsPA: {
        PA: "".concat(origin, "/Admin/Account_List.htm"),
    },
    studioEditor: {
        EU: "".concat(origin, "/docs/StudioEU/Studio/Studio_Components/Canvas/Canvas.htm"),
        EA: "".concat(origin, "/docs/StudioEA/Web-based_Operations/Studio/Studio_Components/Canvas/Canvas.htm"),
    },
    studioEditorLogs: {
        EU: "".concat(origin, "/docs/StudioEU/Studio/Studio_Components/Logs.htm"),
        EA: "".concat(origin, "/docs/StudioEA/Web-based_Operations/Studio/Studio_Components/Logs.htm"),
    },
    emailCampaigns: {
        PA: "".concat(origin, "/Admin/Viewing_Email_Campaign_List.htm"),
        EU: "".concat(origin, "/User/Viewing_Email_Campaigns.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Pushing_Messages/Viewing_Email_Campaigns.htm"),
    },
    emailTemplates: {
        PA: "".concat(origin, "/Admin/Viewing_Email_Template_List.htm"),
        EU: "".concat(origin, "/User/Web-based_Operations/Managing_Message_Templates/Managing_Email_Templates.htm"),
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Message_Templates/Managing_Email_Templates.htm"),
    },
    emailAddressEA: {
        PA: "",
        EU: "",
        EA: "".concat(origin, "/Account/Web-based_Operations/Managing_Email_IDs.htm"),
    },
    providersPA: {
        PA: "".concat(origin, "/Admin/index.htm#t=Managing_Providers.htm"),
        EU: "",
        EA: "",
    },
    routersPA: {
        PA: "".concat(origin, "/Admin/Creating_Rules.htm"),
        EU: "",
        EA: "",
    },
    emailAddressPA: {
        PA: "".concat(origin, "/Admin/index.htm#t=Viewing_Address_Specification.htm"),
        EU: "",
        EA: "",
    },
    quietTime: {
        PA: "".concat(origin, "/Admin/Viewing_Quiet_Time_Storgae_List.htm"),
        EU: "",
        EA: "".concat(origin, "/Account/Web-based_Operations/Creating_Quiet_Time_Storage.htm"),
    },
    contentFilter: {
        PA: "".concat(origin, "/Admin/Configuring_Filter_Keywords.htm"),
        EU: "",
        EA: "",
    },
    blacklist: {
        PA: "".concat(origin, "/Admin/Black_Lists.htm"),
        EU: "",
        EA: "".concat(origin, "/Account/Web-based_Operations/Black_Lists.htm"),
    },
    dnd: {
        PA: "".concat(origin, "/Admin/Creating_DND_Categories.htm"),
        EU: "",
        EA: "".concat(origin, "/Account/Web-based_Operations/Creating_DND_Categories.htm"),
    },
    npaasDeviceList: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_List/Device_List.htm"),
    },
    npaasDeviceListCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_List/Create_a_Device.htm"),
    },
    npaasDeviceListEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_List/Edit_a_Device.htm"),
    },
    npaasDeviceBulkUploadHistory: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_List/View_the_Bulk_Upload_Record_History.htm"),
    },
    npaasDeviceType: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_Type/Device_Type.htm"),
    },
    npaasDeviceTypeCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_Type/Create_a_Device_Type.htm"),
    },
    npaasDeviceTypeEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Devices/Device_Type/Edit_a_Device_Type.htm"),
    },
    npaasPolicyList: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_List/Policy_List.htm"),
    },
    npaasPolicyListCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_List/Create_a_Policy.htm"),
    },
    npaasPolicyListEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_List/Edit_a_Policy.htm"),
    },
    npaasPolicyRule: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_Rule/Policy_Rule.htm"),
    },
    npaasPolicyRuleCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_Rule/Create_a_Policy_Rule.htm"),
    },
    npaasPolicyRuleEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Policy_Rule/Edit_a_Policy_Rule.htm"),
    },
    npaasRuleCriteria: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Criteria/Rule_Criteria.htm"),
    },
    npaasRuleCriteriaCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Criteria/Create_a_Rule_Criteria_New.htm"),
    },
    npaasRuleCriteriaEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Criteria/Edit_a_Rule_Criteria.htm"),
    },
    npaasRuleEngine: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Engine/Rule_Engine_New.htm"),
    },
    npaasRuleEngineCreate: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Engine/Create_Rule.htm"),
    },
    npaasRuleEngineEdit: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Engine/Edit_a_Rule.htm"),
    },
    npaasRuleEngineClone: {
        EA: "",
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Network_APIs/Policies_and_Rules/Rule_Engine/Clone_a_Rule.htm"),
    },
    npaasApplications: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Application_Management/Applications_Management.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Application_Management/Applications_Management.htm"),
    },
    npaasApplicationsCreate: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Application_Management/Create_an_Application.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Application_Management/Create_an_Application.htm"),
    },
    npaasApplicationsEdit: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Application_Management/Edit_an_Application.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Application_Management/Edit_an_Application.htm"),
    },
    npaasConsent: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Consent_Management/Consent_Management.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Consent_Management/Consent_Management.htm"),
    },
    npaasConsentEdit: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Consent_Management/Edit_Consent.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Consent_Management/Edit_Consent.htm"),
    },
    npaasConsentBulkuploadViewHistory: {
        EA: "".concat(origin, "/NPaaSEA/Web-based_Operations/Applications/Consent_Management/View_the_Bulk_Upload_Record_History.htm"),
        EU: "",
        PA: "".concat(origin, "/NPaaSPA/Web-based_Operations/Applications/Consent_Management/View_the_Bulk_Upload_Record_History.htm"),
    },
};
export default urlConfig;
