import urlConfig from "./urlConfig";
export var handleHelpClick = function (pathname, loggedInRole) {
    if (pathname.includes("/insights/reports")) {
        window.open(urlConfig.usageReports[loggedInRole], "_blank");
    }
    else if (pathname.includes("/insights/dashboard")) {
        window.open(urlConfig.insightsDashboard[loggedInRole], "_blank");
    }
    else if (pathname.includes("/app/home")) {
        window.open(urlConfig.homePage[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/Register/sender-addresses")) {
        window.open(urlConfig.smsSenderAddress[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/Register/sender-addresses")) {
        window.open(urlConfig.smsSenderAddress[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/manage-templates")) {
        window.open(urlConfig.smsTemplates[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/my-campaigns")) {
        window.open(urlConfig.smsCampaigns[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/smpp-session")) {
        window.open(urlConfig.smppSession[loggedInRole], "_blank");
    }
    else if (pathname.includes("/voice/campaign")) {
        window.open(urlConfig.voiceCampaign[loggedInRole], "_blank");
    }
    else if (pathname.includes("/voice/file-manager")) {
        window.open(urlConfig.voiceFileManager[loggedInRole], "_blank");
    }
    else if (pathname.includes("/whatsapp/getting-started")) {
        window.open(urlConfig.whatsappProfile[loggedInRole], "_blank");
    }
    else if (pathname.includes("/whatsapp/templates")) {
        window.open(urlConfig.whatsappTemplates[loggedInRole], "_blank");
    }
    else if (pathname.includes("/whatsapp/my-campaigns")) {
        window.open(urlConfig.whatsAppCampaign[loggedInRole], "_blank");
    }
    else if (pathname.includes("/studio/dashboard/myFlows")) {
        window.open(urlConfig.studioDashboard[loggedInRole], "_blank");
    }
    else if (pathname.includes("/myplans/packages")) {
        window.open(urlConfig.packages[loggedInRole], "_blank");
    }
    else if (pathname.includes("/myplans/ratesheets")) {
        window.open(urlConfig.ratesheets[loggedInRole], "_blank");
    }
    else if (pathname.includes("/user-management/roles")) {
        window.open(urlConfig.roles[loggedInRole], "_blank");
    }
    else if (pathname.includes("/user-management/users")) {
        window.open(urlConfig.users[loggedInRole], "_blank");
    }
    else if (pathname.includes("/contacts/distribution-list")) {
        window.open(urlConfig.distributionList[loggedInRole], "_blank");
    }
    else if (pathname.includes("/contacts/contact-list")) {
        window.open(urlConfig.contactList[loggedInRole], "_blank");
    }
    else if (pathname.includes("/contacts/contact-group")) {
        window.open(urlConfig.contactGroup[loggedInRole], "_blank");
    }
    else if (pathname.includes("/segmentation")) {
        window.open(urlConfig.segmentation[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Logs/Activity")) {
        window.open(urlConfig.logsActivity[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Logs/Debug")) {
        window.open(urlConfig.logsDebug[loggedInRole], "_blank");
    }
    else if (pathname.includes("/notification_PA")) {
        window.open(urlConfig.notificationPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Logs/Enterprise")) {
        window.open(urlConfig.logsEnterprise[loggedInRole], "_blank");
    }
    else if (pathname.includes("/NumbersEA")) {
        window.open(urlConfig.numbersEA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/insights/usage-reports")) {
        window.open(urlConfig.reportsPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/sms/sender-address-specifications")) {
        window.open(urlConfig.smsSenderAddressPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/whatsapp/profiles-info")) {
        window.open(urlConfig.whatsappProfilePA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/notification")) {
        window.open(urlConfig.notification[loggedInRole], "_blank");
    }
    else if (pathname.includes("/NumbersPA")) {
        window.open(urlConfig.numbersPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/billing-pricing/rate-sheets")) {
        window.open(urlConfig.rateSheetPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/billing-pricing/packages")) {
        window.open(urlConfig.packagesPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/billing-pricing/wallets")) {
        window.open(urlConfig.walletsPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/billing-pricing/invoicing")) {
        window.open(urlConfig.invoicingPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/accounts")) {
        window.open(urlConfig.accountsPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Logs/Enterprise")) {
        window.open(urlConfig.logsEnterprise[loggedInRole], "_blank");
    }
    else if (pathname.includes("/studio/editor")) {
        window.open(urlConfig.studioEditor[loggedInRole], "_blank");
    }
    else if (pathname.includes("/email/my-campaigns")) {
        window.open(urlConfig.emailCampaigns[loggedInRole], "_blank");
    }
    else if (pathname.includes("/email/email-templates")) {
        window.open(urlConfig.emailTemplates[loggedInRole], "_blank");
    }
    else if (pathname.includes("/email/Register/email-addresses")) {
        window.open(urlConfig.emailAddressEA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Providers")) {
        window.open(urlConfig.providersPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/Routing")) {
        window.open(urlConfig.routersPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/email/email-address-specifications")) {
        window.open(urlConfig.emailAddressPA[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policy/QuietTime")) {
        window.open(urlConfig.quietTime[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policy/contentfilter")) {
        window.open(urlConfig.contentFilter[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policy/blacklist/msisdn")) {
        window.open(urlConfig.blacklist[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policy/dnd/msisdn")) {
        window.open(urlConfig.dnd[loggedInRole], "_blank");
    }
    else if (pathname.includes("/deviceList")) {
        window.open(urlConfig.npaasDeviceList[loggedInRole], "_blank");
    }
    else if (pathname.includes("/deviceListForm/_add")) {
        window.open(urlConfig.npaasDeviceListCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/deviceListForm/_edit")) {
        window.open(urlConfig.npaasDeviceListEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/bulkUploadHistory")) {
        window.open(urlConfig.npaasDeviceBulkUploadHistory[loggedInRole], "_blank");
    }
    else if (pathname.includes("/deviceType")) {
        window.open(urlConfig.npaasDeviceType[loggedInRole], "_blank");
    }
    else if (pathname.includes("/createDeviceTypeForm/_add")) {
        window.open(urlConfig.npaasDeviceTypeCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/createDeviceTypeForm/_edit")) {
        window.open(urlConfig.npaasDeviceTypeEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyList")) {
        window.open(urlConfig.npaasPolicyList[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyListForm/_add")) {
        window.open(urlConfig.npaasPolicyListCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyListForm/_edit")) {
        window.open(urlConfig.npaasPolicyListEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyRules")) {
        window.open(urlConfig.npaasPolicyRule[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyRuleForm/_add")) {
        window.open(urlConfig.npaasPolicyRuleCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/policyRuleForm/_edit")) {
        window.open(urlConfig.npaasPolicyRuleEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/ruleCriteria")) {
        window.open(urlConfig.npaasRuleCriteria[loggedInRole], "_blank");
    }
    else if (pathname.includes("/ruleCriteriaForm/_add")) {
        window.open(urlConfig.npaasRuleCriteriaCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/ruleCriteriaForm/_edit")) {
        window.open(urlConfig.npaasRuleCriteriaEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/ruleEngine")) {
        window.open(urlConfig.npaasRuleEngine[loggedInRole], "_blank");
    }
    else if (pathname.includes("/addRule/_add")) {
        window.open(urlConfig.npaasRuleEngineCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/editRule/_edit")) {
        window.open(urlConfig.npaasRuleEngineEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/cloneRule/_clone")) {
        window.open(urlConfig.npaasRuleEngineClone[loggedInRole], "_blank");
    }
    else if (pathname.includes("/applications")) {
        window.open(urlConfig.npaasApplications[loggedInRole], "_blank");
    }
    else if (pathname.includes("/applicationForm/_add")) {
        window.open(urlConfig.npaasApplicationsCreate[loggedInRole], "_blank");
    }
    else if (pathname.includes("/applicationForm/_edit")) {
        window.open(urlConfig.npaasApplicationsEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/consents")) {
        window.open(urlConfig.npaasConsent[loggedInRole], "_blank");
    }
    else if (pathname.includes("/consentEditForm/_edit")) {
        window.open(urlConfig.npaasConsentEdit[loggedInRole], "_blank");
    }
    else if (pathname.includes("/consentBulkUploadHistory")) {
        window.open(urlConfig.npaasConsentBulkuploadViewHistory[loggedInRole], "_blank");
    }
};
