import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/tailwind.output.css";
import "./assets/css/font-awesome.min.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { AuthProvider } from "./context/AuthContext";
import ThemedSuspense from "./components/ThemedSuspense";
// import { Windmill } from "@windmill/react-ui";
import StepContext from "../src/context/MultiStepFormContext";
import "../src/translations/i18n";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "./context/ThemeContext";
var queryClient = new QueryClient();
var container = document.getElementById("root");
var root = createRoot(container);
if (process.env.NODE_ENV === "development") {
    var worker = require("./mocks/browser").worker;
    worker.start();
}
root.render(_jsx(QueryClientProvider, { client: queryClient, children: _jsx(SidebarProvider, { children: _jsx(SnackbarProvider, { children: _jsx(AuthProvider, { children: _jsx(Suspense, { fallback: _jsx(ThemedSuspense, {}), children: _jsx(StepContext, { children: _jsx(ThemeProvider, { children: _jsx(App, {}) }) }) }) }) }) }) }));
