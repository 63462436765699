import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { multiStepFormContext } from "../context/MultiStepFormContext";
function AccessibleNavigationAnnouncer() {
    var _a = useState(""), message = _a[0], setMessage = _a[1];
    var location = useLocation();
    var handleCancelClick = useContext(multiStepFormContext).handleCancelClick;
    useEffect(function () {
        // ignore the /
        if (location.pathname.slice(1)) {
            // make sure navigation has occurred and screen reader is ready
            setTimeout(function () { return setMessage("Navigated to ".concat(location.pathname.slice(1), " page.")); }, 500);
        }
        else {
            setMessage("");
        }
    }, [location]);
    return (_jsx("span", { className: "sr-only", role: "status", "aria-live": "polite", "aria-atomic": "true", children: message }));
}
export default AccessibleNavigationAnnouncer;
