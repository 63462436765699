var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Auth from "../../containers/Auth";
import { AuthContext } from "../../context/AuthContext";
import { decodingJWT } from "../../utils/jwtDecode";
import { useQuery } from "react-query";
import getAPIMap from "../../api_map";
import axios from "axios";
import getIP from "../../getIP.js";
var IP = getIP();
import { t } from "i18next";
export default function ForwardRoute() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var user = useContext(AuthContext).user;
    var _l = useQuery(["/menu", user], function () { return __awaiter(_this, void 0, void 0, function () {
        var url, config;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = IP + getAPIMap("menu");
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.access_token),
                        },
                    };
                    return [4 /*yield*/, axios.get(url, config)];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    }); }, { enabled: user && ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.access_token) ? true : false }), data = _l.data, isLoading = _l.isLoading;
    var filteredMenu = (_c = (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.filter(function (e) { var _a; return ((_a = Object.keys(e)) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    var firstMenu = ((_e = (_d = filteredMenu === null || filteredMenu === void 0 ? void 0 : filteredMenu[0]) === null || _d === void 0 ? void 0 : _d.subRoutes) === null || _e === void 0 ? void 0 : _e.length)
        ? (_h = (_g = (_f = filteredMenu === null || filteredMenu === void 0 ? void 0 : filteredMenu[0]) === null || _f === void 0 ? void 0 : _f.subRoutes) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.path
        : (_j = filteredMenu === null || filteredMenu === void 0 ? void 0 : filteredMenu[0]) === null || _j === void 0 ? void 0 : _j.path;
    var getRoleName = function (user) {
        var _a, _b, _c, _d, _e;
        var roleName = "";
        roleName = decodingJWT((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.access_token);
        roleName = ((_e = (_d = (_c = (_b = roleName === null || roleName === void 0 ? void 0 : roleName.realm_access) === null || _b === void 0 ? void 0 : _b.roles) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.split("~")) === null || _e === void 0 ? void 0 : _e[2]) || "";
        return roleName;
    };
    return (_jsx(_Fragment, { children: user && ((_k = user === null || user === void 0 ? void 0 : user.data) === null || _k === void 0 ? void 0 : _k.access_token) && !isLoading && firstMenu ? (_jsx(Navigate, { to: getRoleName(user) !== t("_platform_Admin") ? "/app/home" : firstMenu })) : (_jsx(Auth, {})) }));
}
