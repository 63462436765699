import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useMemo } from "react";
// create context
export var SidebarContext = React.createContext();
export var SidebarProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isSidebarOpen = _b[0], setIsSidebarOpen = _b[1];
    var value = useMemo(function () {
        var toggleSidebar = function () {
            setIsSidebarOpen(!isSidebarOpen);
        };
        var closeSidebar = function () {
            setIsSidebarOpen(false);
        };
        return {
            isSidebarOpen: isSidebarOpen,
            toggleSidebar: toggleSidebar,
            closeSidebar: closeSidebar,
        };
    }, [isSidebarOpen]);
    return (_jsx(SidebarContext.Provider, { value: value, children: children }));
};
