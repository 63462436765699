var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import APIMap from "../api_map.js";
import jwt from "jwt-decode";
import { tableDetails } from "../constants_map.js";
import { createScopePermissions } from "../assets/constants/scope";
import getIP from "../getIP.js";
import { useQuery } from "react-query";
import { roleName } from "../constants_map.js";
var isRefreshing = false;
var refreshSubscribers = []; // array of promises
function onRefreshTokenFetched(newToken) {
    // resolve pending promises and proceed api
    console.log("refreshSubscribers resolved", refreshSubscribers.length);
    refreshSubscribers.forEach(function (_a) {
        var resolve = _a[0];
        return resolve(newToken);
    });
    // reset queue
    isRefreshing = false;
    refreshSubscribers = [];
}
function onRefreshTokenFail() {
    // resolve pending promises and proceed api
    // eslint-disable-next-line no-unused-vars
    refreshSubscribers.forEach(function (_a) {
        var resolve = _a[0], reject = _a[1];
        return reject();
    });
    // reset queue
    isRefreshing = false;
    refreshSubscribers = [];
}
function addRefreshSubscriber(resolve, reject) {
    console.log("refreshSubscribers rejected", refreshSubscribers.length);
    refreshSubscribers.push([resolve, reject]);
}
export var determineUserRole = function (userRole) {
    var role = userRole === roleName.enterpriseAdmin
        ? "EA"
        : userRole === roleName.enterpriseUser
            ? "EU"
            : userRole === roleName.platformAdmin
                ? "PA"
                : "NA";
    return role;
};
export var AuthContext = React.createContext();
import { useTranslation } from "react-i18next";
import { getGlobalConfigNoAuth } from "../lib/login-api.js";
export var AuthProvider = function (_a) {
    var children = _a.children;
    var IP = getIP();
    var SECRETPASS = process.env.REACT_APP_SECRETPASS;
    var _b = useState(), accountsEditData = _b[0], setAccountsEditData = _b[1];
    var _c = useState(), packagesEditData = _c[0], setPackagesEditData = _c[1];
    var _d = useState(), rateSheetEditData = _d[0], setRateSheetEditData = _d[1];
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var _f = useState({
        isLoaded: false,
        open: false,
        terms: "",
        loading: false,
        mobile: "",
        email2: "",
    }), state = _f[0], setState = _f[1];
    var _g = useState(JSON.parse(localStorage.getItem("userDetails")) || null), userDetails = _g[0], setUserDetails = _g[1];
    var _h = useState(""), userRole = _h[0], setUserRole = _h[1];
    var _j = useState(false), enableVoice = _j[0], setEnableVoice = _j[1];
    var _k = useState("SMS"), providerTab = _k[0], setProviderTab = _k[1];
    var _l = useState("SMS"), routingTab = _l[0], setRoutingTab = _l[1];
    var smsTemplates = useState([])[0];
    var emailTemplates = useState([])[0];
    var _m = useState(false), showSessionExpiredModal = _m[0], setShowSessionExpiredModal = _m[1];
    var _o = useState(JSON.parse(sessionStorage.getItem("user")) || null), user = _o[0], setUser = _o[1];
    var _p = useState(false), isAppTimeout = _p[0], setAppTimeout = _p[1];
    var _q = useState(JSON.parse(localStorage.getItem("usercredential")) || null), usercredential = _q[0], setUserCredential = _q[1];
    var _r = useState(JSON.parse(localStorage.getItem("usermfa")) || null), usermfa = _r[0], setUserMfa = _r[1];
    var _s = useState(""), email = _s[0], setEmail = _s[1];
    var _t = useState(new Audio()), audio = _t[0], setAudio = _t[1];
    var _u = useState(null), userEmail = _u[0], setUserEmail = _u[1];
    var otp = useState(0)[0];
    var _v = useState({}), smsTemplateDetails = _v[0], setSmsTemplateDetails = _v[1];
    var _w = useState({}), EmailTemplateDetails = _w[0], setEmailTemplateDetails = _w[1];
    var _x = useState({}), ContactDetails = _x[0], setContactDetails = _x[1];
    var _y = useState({}), ContactGroupDetails = _y[0], setContactGroupDetails = _y[1];
    var _z = useState([]), roleTemplates = _z[0], setTemplates = _z[1];
    var _0 = useState({}), distributionListDetails = _0[0], setdistributionListDetails = _0[1];
    var _1 = useState({}), filesummary = _1[0], setfilesummary = _1[1];
    var _2 = useState([]), resourceData = _2[0], setResourceData = _2[1];
    var _3 = useState({}), singleUserDetails = _3[0], setSingleUserDetails = _3[1];
    var _4 = useState(""), editRoleData = _4[0], setEditRoleData = _4[1];
    var _5 = useState(JSON.parse(localStorage.getItem("userData")) || {
        email: "",
        name: "",
        contactNo: "",
    }), userData = _5[0], setUserData = _5[1];
    var _6 = useState([]), packages = _6[0], setPackages = _6[1];
    var _7 = useState(JSON.parse(localStorage.getItem("userGroups")) || []), userGroups = _7[0], setUserGroups = _7[1];
    var _8 = useState([]), rateSheets = _8[0], setRateSheets = _8[1];
    var _9 = useState([]), rateSheetDetails = _9[0], setRateSheetDetails = _9[1];
    var _10 = useState({}), numberData = _10[0], setNumberData = _10[1];
    var _11 = useState([]), selWaTemplateData = _11[0], setSelWaTemplateData = _11[1];
    var _12 = useState("SMS"), tab = _12[0], setTab = _12[1];
    var _13 = useState({}), userScope = _13[0], setUserScope = _13[1];
    var _14 = useState([]), vrDetails = _14[0], setVrDetails = _14[1];
    var _15 = useState([]), allCountries = _15[0], setAllCountries = _15[1];
    var _16 = useState(undefined), beeInstance = _16[0], setBeeInstance = _16[1];
    var _17 = useState(false), collapsed = _17[0], setCollapsed = _17[1];
    var _18 = useState([]), globalConfig = _18[0], setGlobalConfig = _18[1];
    var _19 = useState(0), globalConfigMfa = _19[0], setGlobalConfigMfa = _19[1];
    var _20 = useState(0), Time = _20[0], setResendTime = _20[1];
    var _21 = useState([]), allowedChannels = _21[0], setAllowedChannels = _21[1];
    var _22 = useState(""), currencyCode = _22[0], setCurrencyCode = _22[1];
    var _23 = useState(""), currencySymbol = _23[0], setCurrencySymbol = _23[1];
    // const [usenameLogin, setUsernameLogin] = useState("");
    var _24 = useState(false), triggerRender = _24[0], setTriggerRender = _24[1];
    var exceptionEndpoints = [];
    axios.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
        var isException, newAccessToken, epochTime;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isException = exceptionEndpoints.some(function (endpoint) {
                        return config.url.includes(endpoint);
                    });
                    if (!(isRefreshing && config.url !== IP + APIMap("refreshToken"))) return [3 /*break*/, 2];
                    return [4 /*yield*/, new Promise(function (resolve, reject) {
                            addRefreshSubscriber(resolve, reject);
                        })];
                case 1:
                    newAccessToken = _a.sent();
                    axios.defaults.headers.Authorization = "Bearer ".concat(newAccessToken);
                    config.headers["Authorization"] = "Bearer ".concat(newAccessToken);
                    _a.label = 2;
                case 2:
                    epochTime = Date.now();
                    if (!isException) {
                        config.headers["Accept-Language"] = i18n.language;
                        config.headers["Unix-Timestamp-Nonce"] = epochTime;
                    }
                    return [2 /*return*/, config];
            }
        });
    }); }, function (error) {
        return Promise.reject(error);
    });
    // const { data, isLoading: isConfigDataLoading } = useQuery(
    //   ["noAuthConfig"],
    //   getGlobalConfigNoAuth,
    //   {
    //     refetchOnWindowFocus: false,
    //     onSuccess: (res) => {
    //       const usenameLogin =
    //         res?.find((e) => e.key === "isDNDActivationEnabled")?.value ||
    //         "false";
    //       // Store in localStorage for persistence
    //       localStorage.setItem("usernameLogin", usenameLogin);
    //       setUsernameLogin(usenameLogin);
    //     },
    //   }
    // );
    // useEffect(() => {
    //   // On mount, check localStorage for existing value
    //   const storedValue = localStorage.getItem("usernameLogin");
    //   if (storedValue) {
    //     setUsernameLogin(storedValue);
    //   }
    // }, []);
    // const { data, isLoading: isConfigDataLoading } = useQuery(
    //   ["noAuthConfig"],
    //   getGlobalConfigNoAuth,
    //   {
    //     refetchOnWindowFocus: false,
    //     onSuccess: (res) => {
    //       const usenameLogin =
    //         res?.find((e) => e.key === "isDNDActivationEnabled")?.value ||
    //         "false";
    //       console.log("Query Result:", usenameLogin);
    //       // Store in state and localStorage
    //       localStorage.setItem("usernameLogin", usenameLogin);
    //       setUsernameLogin(usenameLogin);
    //       // Trigger re-render to ensure latest value is applied
    //       setTriggerRender((prev) => !prev);
    //     },
    //   }
    // );
    // useEffect(() => {
    //   // Fallback logic for port changes
    //   const storedValue = localStorage.getItem("usernameLogin");
    //   if (storedValue) {
    //     setUsernameLogin(storedValue);
    //     console.log("Fallback Value:", storedValue);
    //   }
    // }, [triggerRender]); // Re-run effect on re-render
    var channels = {
        sms: "SMS",
        wa: "WhatsApp",
        voice: "Voice",
        vr: "Virtual Receptionist",
        email: "Email",
        rcs: "RCS",
        chatbot: "Chatbot",
        network: "Network",
        studio: "Studio",
    };
    var token = userDetails === null || userDetails === void 0 ? void 0 : userDetails.token;
    useEffect(function () {
        var user = sessionStorage.getItem("user");
        if (user && user != "null") {
            setUser(JSON.parse(user));
        }
        var usermfa = localStorage.getItem("usermfa");
        if (usermfa && usermfa != "null") {
            setUser(JSON.parse(usermfa));
        }
        var usercredential = localStorage.getItem("usercredential");
        if (usercredential && usercredential != "null") {
            setUser(JSON.parse(usercredential));
        }
        var userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
            setUserDetails(JSON.parse(userDetails));
        }
        var userData = localStorage.getItem("userData");
        if (userData) {
            setUserData(JSON.parse(userData));
        }
        var userGroups = localStorage.getItem("userGroups");
        if (userGroups) {
            setUserGroups(JSON.parse(userGroups));
        }
    }, []);
    var getAllCountries = function () {
        var config = {
            headers: {
                Authorization: "bearer " + token,
            },
        };
        var url = IP + APIMap("getCountries") + "?paging=0";
        axios.get(url, config).then(function (res) {
            var _a;
            setAllCountries(((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) || []);
        });
    };
    var getGlobalConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url, config, res, allowedChannels_1, dynamicChannels, currencyCode_1, currencySymbol_1, script, summernoteScript, error_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    url = IP + APIMap("getConfig") + "?scope=gui";
                    _k.label = 1;
                case 1:
                    _k.trys.push([1, 3, , 4]);
                    config = {
                        headers: {
                            Authorization: "bearer " + token,
                        },
                    };
                    return [4 /*yield*/, axios.get(url, config)];
                case 2:
                    res = _k.sent();
                    setGlobalConfig((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data);
                    allowedChannels_1 = ["All Channels"];
                    dynamicChannels = (_c = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.find(function (e) { return e.key === "allowedChannels"; }).value.trim().split(",").map(function (e) { return e.trim(); }).map(function (e) { return channels[e]; });
                    allowedChannels_1 = __spreadArray(__spreadArray([], allowedChannels_1, true), dynamicChannels, true);
                    currencyCode_1 = (_f = (_e = (_d = res === null || res === void 0 ? void 0 : res.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.find(function (e) { return e.key === "currencyCode"; })) === null || _f === void 0 ? void 0 : _f.value;
                    currencySymbol_1 = (_j = (_h = (_g = res === null || res === void 0 ? void 0 : res.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.find(function (e) { return e.key === "currencySymbol"; })) === null || _j === void 0 ? void 0 : _j.value;
                    if (allowedChannels_1.includes("Email")) {
                        console.log("loading bee free script because email channel is enabled");
                        script = document.createElement("script");
                        script.src = "https://app-rsrc.getbee.io/plugin/BeePlugin.js";
                        script.type = "text/javascript";
                        script.async = true;
                        script.defer = true;
                        summernoteScript = document.createElement("script");
                        summernoteScript.src = "/summernote-dist/summernote-lite.min.css";
                        summernoteScript.async = true;
                        summernoteScript.rel = "stylesheet prefetch";
                        summernoteScript.defer = true;
                        document.head.appendChild(script);
                        document.head.appendChild(summernoteScript);
                    }
                    setAllowedChannels(allowedChannels_1);
                    setCurrencyCode(currencyCode_1);
                    setCurrencySymbol(currencySymbol_1);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _k.sent();
                    setAllowedChannels(["All Channels", "SMS"]);
                    console.log("Error while fetching global config");
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (user) {
            sessionStorage.setItem("user", JSON.stringify(user));
        }
        localStorage.setItem("usermfa", JSON.stringify(usermfa));
        localStorage.setItem("usercredential", JSON.stringify(usercredential));
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        localStorage.setItem("userData", JSON.stringify(userData));
        if (userDetails && userDetails.scope) {
            var data_1 = createScopePermissions(userDetails.scope);
            setUserScope(function (userScope) { return (__assign(__assign({}, userScope), data_1)); });
            var role = userDetails.realm_access.roles[0].split("~");
            if (role.length > 1) {
                setUserRole(role[2]);
            }
            else {
                setUserRole(role[0]);
            }
            getAllCountries();
            getGlobalConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, usercredential, usermfa, userDetails, userData]);
    var clearStorage = function (timeout) {
        var language = localStorage.getItem("i18nextLng");
        localStorage.clear();
        if (timeout) {
            // for timeout dialog
            setAppTimeout(true);
        }
        else {
            setUser(null);
        }
        setUserEmail(null);
        setUserDetails(null);
        setUserData({
            email: "",
            name: "",
            contactNo: "",
        });
        sessionStorage.clear();
        localStorage.setItem("i18nextLng", language);
        localStorage.setItem("logout", dayjs());
    };
    var logout = function (status, revokeAll) { return __awaiter(void 0, void 0, void 0, function () {
        var accessTok, refreshTok, revokeUrl, revRes, config, err_1, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessTok = sessionStorage.getItem("access");
                    refreshTok = sessionStorage.getItem("refresh");
                    revokeUrl = IP + APIMap("revoke");
                    // after logout, disable refresh token processing
                    onRefreshTokenFail();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    revRes = null;
                    if (!!status) return [3 /*break*/, 5];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(accessTok),
                        },
                    };
                    return [4 /*yield*/, axios.post(revokeUrl, {
                            token: refreshTok,
                        }, config)];
                case 3:
                    revRes = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.log("error while calling access token revoke endpoint: ", err_1);
                    return [3 /*break*/, 5];
                case 5:
                    if ((revRes === null || revRes === void 0 ? void 0 : revRes.status) === 200 || status) {
                        clearStorage(status);
                    }
                    return [3 /*break*/, 7];
                case 6:
                    err_2 = _a.sent();
                    console.error("error while logging out: ", err_2);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var refreshing_token = null;
    axios.interceptors.response.use(function (response) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, response];
            });
        });
    }, function (error) {
        return __awaiter(this, void 0, void 0, function () {
            var prevRequest, newAccessToken, result;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        // Any status codes that falls outside the range of 2xx cause this function to trigger
                        // Do something with response error
                        if (!(error === null || error === void 0 ? void 0 : error.config)) { // api failed with no response config, so drop refresh token here
                            onRefreshTokenFail();
                        }
                        if (!(((_a = error === null || error === void 0 ? void 0 : error.config) === null || _a === void 0 ? void 0 : _a.url) === IP + APIMap("refreshToken") &&
                            ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === 401)) return [3 /*break*/, 2];
                        onRefreshTokenFail();
                        return [4 /*yield*/, logout(true)];
                    case 1:
                        _k.sent();
                        return [2 /*return*/, error];
                    case 2:
                        prevRequest = error === null || error === void 0 ? void 0 : error.config;
                        if (!(((_c = error === null || error === void 0 ? void 0 : error.config) === null || _c === void 0 ? void 0 : _c.url) !== IP + APIMap("Cpassloginform") &&
                            ((_d = error === null || error === void 0 ? void 0 : error.config) === null || _d === void 0 ? void 0 : _d.url) !== IP + APIMap("loginMfa") &&
                            ((_e = error === null || error === void 0 ? void 0 : error.config) === null || _e === void 0 ? void 0 : _e.url) !== IP + APIMap("Cpassloginform1") &&
                            ((_f = error === null || error === void 0 ? void 0 : error.response) === null || _f === void 0 ? void 0 : _f.status) === 401 &&
                            !(prevRequest === null || prevRequest === void 0 ? void 0 : prevRequest.sent) &&
                            !(((_h = (_g = error === null || error === void 0 ? void 0 : error.response) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.httpCode) === 401)) // Invalid api Key for WA Sandbox
                        ) return [3 /*break*/, 6]; // Invalid api Key for WA Sandbox
                        if (!isRefreshing) return [3 /*break*/, 4];
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                addRefreshSubscriber(resolve, reject);
                            })];
                    case 3:
                        newAccessToken = _k.sent();
                        // console.log('refreshSubscribers end2', error.config.url);
                        if (error === null || error === void 0 ? void 0 : error.config) {
                            error.config.headers["Authorization"] = "Bearer ".concat(newAccessToken);
                        }
                        // Retry the original request
                        return [2 /*return*/, axios.request(prevRequest)];
                    case 4:
                        isRefreshing = true;
                        return [4 /*yield*/, refreshTokens()];
                    case 5:
                        result = _k.sent();
                        prevRequest.headers["Authorization"] = "Bearer ".concat((_j = result === null || result === void 0 ? void 0 : result.data) === null || _j === void 0 ? void 0 : _j.access_token);
                        return [2 /*return*/, prevRequest];
                    case 6: return [2 /*return*/, Promise.reject(error)];
                }
            });
        });
    });
    var refreshTokens = function () { return __awaiter(void 0, void 0, void 0, function () {
        var refreshToken, payload, config, url, result, accessToken, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    refreshToken = sessionStorage.getItem("refresh");
                    // if refresh token is empty no need to call refresh token api
                    if (!refreshToken) {
                        onRefreshTokenFail();
                        return [2 /*return*/];
                    }
                    payload = {
                        refresh_token: refreshToken,
                    };
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(refreshToken),
                        },
                    };
                    url = IP + APIMap("refreshToken");
                    return [4 /*yield*/, axios.post(url, payload, config)];
                case 1:
                    result = _a.sent();
                    // assign new token to axios header
                    axios.defaults.headers.Authorization = "Bearer ".concat(sessionStorage.getItem("access"));
                    // Retry the original request with the new token
                    onRefreshTokenFetched(result.data.access_token); // pass all pending requests in req interceptor
                    sessionStorage.setItem("refresh", result.data.refresh_token);
                    sessionStorage.setItem("access", result.data.access_token);
                    console.log("set to session storage");
                    accessToken = jwt(result.data.access_token);
                    accessToken["token"] = result.data.access_token.trim();
                    setUser({ data: result.data });
                    setUserDetails(accessToken);
                    setUserGroups(accessToken.groups);
                    return [2 /*return*/, result];
                case 2:
                    error_2 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var value = useMemo(function () {
        var signup = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var url, result;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        localStorage.removeItem("count");
                        localStorage.removeItem("count3");
                        localStorage.removeItem("count1");
                        localStorage.removeItem("COUNTER_KEY1");
                        localStorage.removeItem("COUNTER_KEY");
                        localStorage.removeItem("COUNTER_KEY3");
                        setEmail(param.email);
                        localStorage.setItem("email", param.email);
                        setState(__assign(__assign({}, state), { loading: true }));
                        url = IP + APIMap("signup");
                        localStorage.setItem("timerOn", true);
                        localStorage.setItem("countryCode", (_a = param === null || param === void 0 ? void 0 : param.countryCode) === null || _a === void 0 ? void 0 : _a.value);
                        localStorage.setItem("mobileNumber", param.mobileNumber);
                        return [4 /*yield*/, axios.post(url, {
                                name: param.name,
                                email: param.email,
                                company: param.company,
                                countryCode: (_b = param.countryCode) === null || _b === void 0 ? void 0 : _b.value,
                                mobile: param.mobileNumber,
                            })];
                    case 1:
                        result = _c.sent();
                        return [2 /*return*/, result];
                }
            });
        }); };
        var EmailNotification = function (email, countryCode, mobile, deployURL) {
            if (!countryCode)
                countryCode = localStorage.getItem("countryCode");
            if (!mobile)
                mobile = localStorage.getItem("mobileNumber");
            localStorage.setItem("timerOn", true);
            localStorage.setItem("email", email);
            if (localStorage.getItem("count") == null) {
                localStorage.setItem("count", 1);
            }
            else {
                localStorage.setItem("count", parseInt(localStorage.getItem("count")) + 1);
            }
            var url = IP + APIMap("activationlink");
            var targetarr = [];
            targetarr.push(email);
            return axios.post(url, __assign(__assign({ targets: [email], msgTemplate: "setPasswordEmail" }, (deployURL && { deploymentURL: deployURL })), (countryCode && mobile
                ? {
                    templateValues: {
                        COUNTRY_CODE: countryCode,
                        MOBILE: mobile,
                    },
                }
                : undefined)));
        };
        var encryptText = function (text) {
            // const encryptedText = CryptoJS.AES.encrypt(
            //   JSON.stringify(text),
            //   SECRETPASS
            // ).toString();
            // return encryptedText || "";
            return text;
        };
        var setPassword = function (param) {
            var url = IP + APIMap("setpassword");
            localStorage.setItem("userid", param.userid);
            return axios.post(url, {
                token: param.usertoken,
                password: encryptText(param.password),
                uid: param.userid,
            });
        };
        var handleClose = function () {
            setState(__assign(__assign({}, state), { open: false }));
        };
        var setMobileNumber = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var url, jsonObject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localStorage.setItem("mobileNumber", param.mobileNumber);
                        localStorage.setItem("countryCode", param.countryCode.value);
                        localStorage.setItem("timerOn1", true);
                        localStorage.setItem("otpCounter", 1);
                        if (localStorage.getItem("count1") == null) {
                            localStorage.setItem("count1", 1);
                        }
                        else {
                            localStorage.setItem("count1", parseInt(localStorage.getItem("count1")) + 1);
                        }
                        url = IP + APIMap("sendotp");
                        jsonObject = {};
                        if (localStorage.getItem("userid")) {
                            if (localStorage.getItem("email")) {
                                jsonObject.targets = [localStorage.getItem("email")];
                            }
                            else {
                                jsonObject.targets = [""];
                            }
                            jsonObject.uid = localStorage.getItem("userid");
                            jsonObject.ttl = "5";
                        }
                        else {
                            jsonObject.targets = [localStorage.getItem("email")];
                            jsonObject.ttl = "5";
                        }
                        return [4 /*yield*/, axios.post(url, jsonObject)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        var getTerms = function () { return __awaiter(void 0, void 0, void 0, function () {
            var url, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = IP + APIMap("systemDocs");
                        url = url.replace("{filename}", "terms_and_conditions.html");
                        return [4 /*yield*/, axios.get(url)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        }); };
        var getScreenElement = function () {
            var url = IP + APIMap("screenelement");
            return axios.get(url);
        };
        var addSmsTemplate = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var TemplateMsgs, obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        TemplateMsgs = [];
                        TemplateMsgs.push({ language: "en", msg: obj.message });
                        obj1 = {
                            name: obj.name,
                            type: obj.type,
                            category: obj.category,
                            defaultLanguage: "en",
                            messages: TemplateMsgs,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        url = IP + APIMap("addTemplate");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getSmsTemplates = function (type, pageParam, status, category) { return __awaiter(void 0, void 0, void 0, function () {
            var config, statusString, categoryString, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        statusString = "".concat(status || status === "0" || status === 0 ? "&status=".concat(status) : "");
                        categoryString = "".concat(category || category === "0" || category === 0
                            ? "&category=".concat(category)
                            : "");
                        url = "";
                        url = IP + APIMap("getTemplates");
                        url =
                            url +
                                "?type=".concat(type, "&pgsize=3&pgindex=").concat(pageParam).concat(statusString).concat(categoryString);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deleteSmsTemplate = function (templateId) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        url = IP + APIMap("deleteTemplate");
                        url = url.replace("{id}", templateId);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var uploadblockedword = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var response, config, url, payload, response_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        url = "";
                        payload = new FormData();
                        payload.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("uploadblockedword");
                        return [4 /*yield*/, axios.post(url, payload, config)];
                    case 1:
                        response_1 = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var uploadreplacedword = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var response, config, url, payload, response_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        url = "";
                        payload = new FormData();
                        payload.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("uploadreplacedword");
                        return [4 /*yield*/, axios.post(url, payload, config)];
                    case 1:
                        response_2 = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var addEmailTemplate = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var response, config, url, obj1, payload, _i, _a, _b, key, val, response_3, payload, _c, _d, _e, key, val, response_4;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        config = {};
                        url = "";
                        if (!(obj.type === "s" || obj.type === "p")) return [3 /*break*/, 2];
                        obj1 = {
                            name: obj.name,
                            type: obj.type,
                            message: obj.message,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("addTemplateEmail");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _f.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(obj.type == "u")) return [3 /*break*/, 4];
                        payload = new FormData();
                        payload.append("name", obj.name);
                        payload.append("type", obj.type);
                        payload.append("file", obj.file);
                        payload.append("html", obj.html);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("uploadTemplateEmail");
                        console.log("Posting ----", url);
                        console.log("Config in Auth ", config);
                        for (_i = 0, _a = payload.entries(); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], val = _b[1];
                            console.log(key, val);
                        }
                        return [4 /*yield*/, axios.post(url, payload, config)];
                    case 3:
                        response_3 = _f.sent();
                        console.log("Response is ", response_3);
                        return [3 /*break*/, 6];
                    case 4:
                        if (!(obj.type == "r")) return [3 /*break*/, 6];
                        payload = new FormData();
                        payload.append("name", obj.name);
                        payload.append("type", obj.type);
                        payload.append("html", obj.html);
                        payload.append("json", JSON.stringify(obj.json));
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("uploadContentEmail");
                        console.log("Posting ----", url);
                        console.log("Config in Auth ", config);
                        for (_c = 0, _d = payload.entries(); _c < _d.length; _c++) {
                            _e = _d[_c], key = _e[0], val = _e[1];
                            console.log(key, val);
                        }
                        return [4 /*yield*/, axios.post(url, payload, config)];
                    case 5:
                        response_4 = _f.sent();
                        console.log("Response is ", response_4);
                        _f.label = 6;
                    case 6: return [2 /*return*/, response];
                }
            });
        }); };
        var getEmailTemplates = function (type, pageParam) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        url = "";
                        url = IP + APIMap("getTemplatesEmail");
                        url = url + "?type=".concat(type, "&pgsize=3&pgindex=").concat(pageParam);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deleteEmailTemplate = function (templateId) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        url = IP + APIMap("deleteTemplateEmail");
                        url = url.replace("{id}", templateId);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getPolicy = function () {
            var url = IP + APIMap("systemDocs");
            url = url.replace("{filename}", "privacy_policy.html");
            return axios.get(url);
        };
        var imageUrlToBase64 = function (url, token) { return __awaiter(void 0, void 0, void 0, function () {
            var response, blob_1, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get(url, {
                                responseType: "blob",
                                headers: {
                                    Authorization: "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        blob_1 = response.data;
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                try {
                                    var reader_1 = new FileReader();
                                    reader_1.readAsDataURL(blob_1);
                                    reader_1.onloadend = function () {
                                        resolve(reader_1.result);
                                    };
                                }
                                catch (e) {
                                    reject(e);
                                }
                            })];
                    case 2:
                        error_3 = _a.sent();
                        console.error("There was an error fetching the image:", error_3);
                        throw error_3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var changePassword = function (param) {
            var config = {
                headers: {
                    Authorization: "Bearer ".concat(param.token),
                },
            };
            var url = IP + APIMap("changePassword");
            //Encrypt passwords
            return axios.post(url, {
                oldPassword: encryptText(param.oldpassword),
                newPassword: encryptText(param.newpassword),
                token: sessionStorage.getItem("refresh"),
            }, config);
        };
        var getUser = function (uid, token) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, resp, profilebase64, base64Image, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        };
                        url = IP + APIMap("getuserid");
                        url = url.replace("{id}", uid);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        resp = _a.sent();
                        profilebase64 = "";
                        if (!resp.data.profilepicture) return [3 /*break*/, 5];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, imageUrlToBase64(resp.data.profilepicture, token)];
                    case 3:
                        base64Image = _a.sent();
                        profilebase64 = base64Image;
                        return [3 /*break*/, 5];
                    case 4:
                        err_3 = _a.sent();
                        console.log("error fetching profileImg: ", err_3);
                        return [3 /*break*/, 5];
                    case 5:
                        setUserData(__assign(__assign({}, userData), { email: resp.data.email, name: resp.data.name, contactNo: resp.data.contactNo, countryCode: resp.data.countryCode, profileImg: profilebase64, organisation: resp.data.organisation }));
                        return [2 /*return*/];
                }
            });
        }); };
        var SendOtp = function () {
            localStorage.setItem("otp", localStorage.setItem("otp") + 1);
        };
        var login = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var url, response, accessToken, err_4;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        localStorage.setItem("email", param.email);
                        url = IP +
                            APIMap(param.usenameLogin === "true" ? "Cpassloginform1" : "Cpassloginform");
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(url, (_a = {},
                                _a[param.usenameLogin === "true"
                                    ? param.email.includes("@")
                                        ? "email"
                                        : "username"
                                    : "email"] = param.email,
                                _a.password = encryptText(param.password),
                                _a.interface = "gui",
                                _a))];
                    case 2:
                        response = _b.sent();
                        axios.defaults.headers.Authorization = "Bearer ".concat(response.data.access_token);
                        sessionStorage.setItem("access", response.data.access_token);
                        sessionStorage.setItem("refresh", response.data.refresh_token);
                        setAppTimeout(false);
                        setUser(response);
                        accessToken = jwt(response.data.access_token);
                        accessToken["token"] = response.data.access_token.trim();
                        getUser(accessToken.sub, accessToken.token);
                        setUserGroups(accessToken.groups);
                        localStorage.setItem("userGroups", JSON.stringify(accessToken.groups));
                        setUserDetails(accessToken);
                        return [2 /*return*/, accessToken];
                    case 3:
                        err_4 = _b.sent();
                        throw err_4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var loginMfa = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var url, response, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localStorage.setItem("email", param.email);
                        url = IP + APIMap("loginMfa");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(url, {
                                email: param.email,
                                password: encryptText(param.password),
                            })];
                    case 2:
                        response = _a.sent();
                        setUserMfa(response);
                        console.log("setusermfa", usermfa);
                        setUserCredential(param);
                        console.log("usercredential", usercredential);
                        return [2 /*return*/, response];
                    case 3:
                        err_5 = _a.sent();
                        throw err_5;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var sendMfaOtp = function (token, sub) { return __awaiter(void 0, void 0, void 0, function () {
            var sendotpurl, body, response, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendotpurl = IP + APIMap("sentOTP");
                        body = {
                            sid: token,
                            uid: sub,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(sendotpurl, body)];
                    case 2:
                        response = _a.sent();
                        console.log("success", response);
                        return [2 /*return*/, response];
                    case 3:
                        err_6 = _a.sent();
                        return [2 /*return*/, err_6];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var resendMfaOtp = function (token, sub) { return __awaiter(void 0, void 0, void 0, function () {
            var sendotpurl, body, response, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendotpurl = IP + APIMap("sentOTP");
                        body = {
                            sid: token,
                            uid: sub,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.post(sendotpurl, body)];
                    case 2:
                        response = _a.sent();
                        console.log("success", response);
                        return [2 /*return*/, response];
                    case 3:
                        err_7 = _a.sent();
                        return [2 /*return*/, err_7];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var updateUser = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var formData, config, url, response, updatedUserData;
            var uid = _b.uid, values = _b.values, token = _b.token, profileImg = _b.profileImg;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("name", values.name);
                        formData.append("contactNo", values.mobileNumber);
                        formData.append("countryCode", values.countryCode.value);
                        if (Object.prototype.hasOwnProperty.call(values, "vrUserSettings")) {
                            formData.append("vrUserSettings", values.vrUserSettings);
                        }
                        if (profileImg !== null)
                            formData.append("profileImg", profileImg);
                        config = {
                            headers: {
                                Authorization: "Bearer ".concat(token),
                            },
                        };
                        url = IP + APIMap("updateuser");
                        url = url.replace("{id}", uid);
                        return [4 /*yield*/, axios.put(url, formData, config)];
                    case 1:
                        response = _c.sent();
                        updatedUserData = __assign(__assign(__assign(__assign({}, userData), { name: values.name, contactNo: values.mobileNumber, countryCode: values.countryCode.value }), (Object.prototype.hasOwnProperty.call(values, "vrUserSettings") && {
                            vrUserSettings: values.vrUserSettings,
                        })), (profileImg && { profileImg: URL.createObjectURL(profileImg) }));
                        localStorage.setItem("userData", JSON.stringify(updatedUserData));
                        setUserData(updatedUserData);
                        return [2 /*return*/, response];
                }
            });
        }); };
        var setLoader = function () {
            setState(__assign(__assign({}, state), { loading: false }));
        };
        var getTemplateById = function (id) { return __awaiter(void 0, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = IP + APIMap("getTemplate");
                        url = url.replace("{id}", id);
                        return [4 /*yield*/, axios.get(url)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var editSmsTemplate = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, TemplateMsgs, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        TemplateMsgs = [];
                        TemplateMsgs.push({ language: "en", message: obj.message, msg: obj.msg });
                        url = IP + APIMap("editTemplate");
                        url = url.replace("{id}", obj.id);
                        return [4 /*yield*/, axios.put(url, {
                                name: obj.name,
                                type: obj.type,
                                defaultLanguage: "en",
                                category: obj.category,
                                description: obj.description,
                                messages: TemplateMsgs,
                            }, config)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        var editEmailTemplate = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url, url, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        if (!(obj.type == "s" || obj.type == "p")) return [3 /*break*/, 2];
                        url = IP + APIMap("editTemplateEmail");
                        url = url.replace("{id}", obj.id);
                        return [4 /*yield*/, axios.put(url, {
                                name: obj.name,
                                type: obj.type,
                                defaultLanguage: "en",
                                description: obj.description,
                                message: obj.message,
                            }, config)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(obj.type == "u" || obj.type == "r")) return [3 /*break*/, 4];
                        url = IP + APIMap("cloneTemplateEmail");
                        console.log("With type as ", obj.type, " and id as ", obj.id);
                        url = url.replace("{id}", obj.id);
                        console.log("Final Posting URL is ", url);
                        payload = {
                            name: obj.name,
                            type: obj.type,
                            defaultLanguage: "en",
                            id: obj.id,
                            userId: obj.userId,
                            templateURL: obj.templateURL,
                            createdAt: obj.createdAt,
                        };
                        if (obj.type === "r") {
                            payload.templateURLJSON = obj.templateURLJSON;
                        }
                        return [4 /*yield*/, axios.put(url, payload, config)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        }); };
        var editRichMediaEmailTemplate = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer ".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.token),
                            },
                        };
                        url = IP + APIMap("editTemplateContentEmail");
                        url = url.replace("{id}", obj.id);
                        return [4 /*yield*/, axios.put(url, {
                                name: obj.name,
                                type: obj.type,
                                defaultLanguage: "en",
                                id: obj.id,
                                userId: obj.userId,
                                templateURL: obj.templateURL,
                                createdAt: obj.createdAt,
                                json: obj.json,
                                html: obj.htmlContent,
                            }, config)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        var forgotPassword = function (_a) {
            var email = _a.email, countryCode = _a.countryCode, mobileNumber = _a.mobileNumber, activationLinkMode = _a.activationLinkMode;
            var activtnLinkMode = activationLinkMode || "email";
            setState(__assign(__assign({}, state), { loading: true, email2: email }));
            localStorage.setItem("timerOn3", true);
            if (localStorage.getItem("count3") == null) {
                localStorage.setItem("count3", 1);
            }
            else {
                localStorage.setItem("count3", parseInt(localStorage.getItem("count3")) + 1);
            }
            localStorage.setItem("email2", email);
            localStorage.setItem("countryCode", countryCode);
            localStorage.setItem("mobileNumber", mobileNumber);
            var url = IP + APIMap("forgotpassword");
            var body = __assign(__assign(__assign({}, (activtnLinkMode === "email" && {
                email: localStorage.getItem("email2"),
            })), (activtnLinkMode === "sms" && {
                countryCode: countryCode,
                mobileNumber: mobileNumber,
            })), { ttl: "5" });
            return axios.post(url, body);
        };
        var Lang = function () {
            var url = IP + APIMap("systemDocs");
            url = url.replace("{filename}", "lang.json");
            return axios.get(url);
        };
        var Logo = function () {
            var url = IP + APIMap("assetsAccounts");
            url = url.replace("{groupname}", "default");
            url = url.replace("{filename}", "comviva-logo.svg");
            return axios.get(url);
        };
        var cpassLogo = function () {
            var url = IP + APIMap("assetsAccounts");
            url = url.replace("{groupname}", "default");
            url = url.replace("{filename}", "comviva-logo.svg");
            return axios.get(url);
        };
        var Copyright = function () {
            var url = IP + APIMap("systemDocs");
            url = url.replace("{filename}", "copyright.html");
            return axios.get(url);
        };
        var BackGroundImage = function () {
            var url = IP + APIMap("assetsAccounts");
            url = url.replace("{groupname}", "default");
            url = url.replace("{filename}", "comviva-background.jpg");
            return axios.get(url);
        };
        var resetPassword = function (token, n, userid) {
            setState(__assign(__assign({}, state), { loading: true }));
            var url = IP + APIMap("resetpassword");
            return axios.post(url, {
                token: token,
                password: n,
                reset: 1,
                uid: userid,
            });
        };
        var verifyOtp = function (n) {
            setState(__assign(__assign({}, state), { loading: true }));
            var url = IP + APIMap("verifyotp");
            var jsonObject = {};
            jsonObject.countryCode = localStorage.getItem("countryCode");
            jsonObject.contactNo = localStorage.getItem("mobileNumber");
            jsonObject.otp = n;
            if (localStorage.getItem("userid")) {
                jsonObject.issuedTo = localStorage.getItem("userid");
            }
            else {
                jsonObject.issuedTo = localStorage.getItem("email");
            }
            return axios.post(url, jsonObject);
        };
        var verifyEmail = function () {
            var url = IP + APIMap("emailverification");
            return axios.post(url, {
                email: localStorage.getItem("email"),
                message: "", // base64 encoded content
                template: "", // an email template existing in the system.
                templateValues: {}, // a json object containing keys and values for template placeholder.
                ttl: "2m", // A string consisting of a number and a unit of time. for ex: 48 hours -> 48h
                appInfo: {},
                apppCallback: "",
            });
        };
        //contact management
        var addcontact = function (obj_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([obj_1], args_1, true), void 0, function (obj, additionalValues) {
                var obj1, val, config, url, response;
                var _a, _b, _c;
                if (additionalValues === void 0) { additionalValues = null; }
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            obj1 = {
                                name: obj.name,
                                phone: obj.mobileNumber,
                                countryCode: obj.countryCode.value,
                                email: obj.email,
                                address: obj.address,
                                groups: obj.groups,
                            };
                            if (additionalValues) {
                                for (val in additionalValues) {
                                    // dropdown values
                                    if (((_a = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _a === void 0 ? void 0 : _a.label) &&
                                        ((_b = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _b === void 0 ? void 0 : _b.value)) {
                                        obj1[val] = (_c = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _c === void 0 ? void 0 : _c.value;
                                    }
                                    else {
                                        obj1[val] = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val];
                                    }
                                }
                            }
                            config = {
                                headers: {
                                    Authorization: "bearer " + token,
                                },
                            };
                            url = IP + APIMap("addcontact");
                            return [4 /*yield*/, axios.post(url, obj1, config)];
                        case 1:
                            response = _d.sent();
                            return [2 /*return*/, response];
                    }
                });
            });
        };
        var addbulkcontact = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj1 = {
                            jobId: obj.jobId,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("addbulkcontact");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var FileUpload = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var formData, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("file", obj);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("bulkfileupload");
                        return [4 /*yield*/, axios.post(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var RateSheetFileUpload = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var formData, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("file", obj);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        url = IP + APIMap("bulkfileupload");
                        return [4 /*yield*/, axios.post(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var DLFileUpload = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url, formData, obj1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        response = {};
                        url = IP + APIMap("dlfileuplaod");
                        if (!(obj.type === "file")) return [3 /*break*/, 2];
                        formData = new FormData();
                        formData.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        return [4 /*yield*/, axios.post(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(obj.type === "manual")) return [3 /*break*/, 4];
                        obj1 = {
                            manualLines: obj.manualLines,
                            uploadFileToken: 0,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        }); };
        var DLFileUploadEmail = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url, formData, obj1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        response = {};
                        url = IP + APIMap("emaildlfileuplaod");
                        if (!(obj.type === "file")) return [3 /*break*/, 2];
                        formData = new FormData();
                        formData.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        return [4 /*yield*/, axios.post(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(obj.type === "manual")) return [3 /*break*/, 4];
                        obj1 = {
                            manualLines: obj.manualLines,
                            uploadFileToken: 0,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        }); };
        var editDLFileUpload = function (obj, dl) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url, formData, obj1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        response = {};
                        url = IP + APIMap("editdlfileupload");
                        url = url.replace("{id}", dl.jobId);
                        if (!(obj.type === "file")) return [3 /*break*/, 2];
                        formData = new FormData();
                        formData.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        return [4 /*yield*/, axios.put(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(obj.type === "manual")) return [3 /*break*/, 4];
                        obj1 = {
                            manualLines: obj.manualLines,
                            uploadFileToken: 0,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        return [4 /*yield*/, axios.put(url, obj1, config)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        }); };
        var editEmailDLFileUpload = function (obj, dl) { return __awaiter(void 0, void 0, void 0, function () {
            var config, response, url, formData, obj1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        response = {};
                        url = IP + APIMap("editEmailDLFileUpload");
                        url = url.replace("{id}", dl.jobId);
                        if (!(obj.type === "file")) return [3 /*break*/, 2];
                        formData = new FormData();
                        formData.append("file", obj.file);
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        return [4 /*yield*/, axios.put(url, formData, config)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(obj.type === "manual")) return [3 /*break*/, 4];
                        obj1 = {
                            manualLines: obj.manualLines,
                            uploadFileToken: 0,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        return [4 /*yield*/, axios.put(url, obj1, config)];
                    case 3:
                        response = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, response];
                }
            });
        }); };
        var deletefile = function (jobId, fileNames) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response, err_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                            data: {
                                files: Object.keys(fileNames),
                                jobId: jobId,
                            },
                        };
                        url = IP + APIMap("removefileupload");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response];
                    case 3:
                        err_8 = _a.sent();
                        return [2 /*return*/, Promise.reject(err_8)];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var getsummary = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP;
                        if (obj.type === "Email") {
                            url = url + APIMap("getsummary");
                        }
                        else {
                            url = url + APIMap("getsummary");
                        }
                        url = url.replace("{id}", obj.jobId);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        var ContactList = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, searchString, baseUrl, url, response;
            var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 0 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        searchString = queryKey[1] || queryKey[1] === 0 || queryKey[1] === "0"
                            ? "&name=".concat(queryKey[1])
                            : "";
                        baseUrl = IP + APIMap("contactlist");
                        url = baseUrl +
                            "?pgindex=".concat(pageParam, "&pgsize=").concat(tableDetails.pageSize, "&contact=true").concat(searchString);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _d.sent();
                        return [2 /*return*/, { response: response, nextPage: pageParam + 1 }];
                }
            });
        }); };
        var ContactListForExport = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, searchString, baseUrl, url, response;
            var queryKey = _b.queryKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        searchString = queryKey[1] || queryKey[1] === 0 || queryKey[1] === "0"
                            ? "&name=".concat(queryKey[1])
                            : "";
                        baseUrl = IP + APIMap("contactlist");
                        url = baseUrl + "?page=".concat(1, "&per_page=1000000&contact=true").concat(searchString);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var contactSearchApi = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, url, response;
            var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 1 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("contactlist");
                        url =
                            url +
                                "?page=".concat(pageParam, "&per_page=").concat(tableDetails.pageSize, "&contact=true&name=").concat(queryKey[1]);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _d.sent();
                        return [2 /*return*/, { response: response, nextPage: pageParam + 1 }];
                }
            });
        }); };
        var editContactList = function (obj_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([obj_1], args_1, true), void 0, function (obj, additionalValues) {
                var addmems, delmems, grpid, objgrp, obj1, val, config, url, response;
                var _a, _b, _c, _d, _e;
                if (additionalValues === void 0) { additionalValues = null; }
                return __generator(this, function (_f) {
                    switch (_f.label) {
                        case 0:
                            addmems = [];
                            delmems = [];
                            grpid = [];
                            objgrp = [];
                            objgrp = (_a = obj === null || obj === void 0 ? void 0 : obj.groups) === null || _a === void 0 ? void 0 : _a.split(",");
                            if (ContactDetails.groups !== null && ContactDetails.groups !== "")
                                grpid = ((_b = ContactDetails === null || ContactDetails === void 0 ? void 0 : ContactDetails.groups) === null || _b === void 0 ? void 0 : _b.split(",")) || [];
                            objgrp.forEach(function (e) {
                                if (grpid.includes(e) === false)
                                    addmems.push(e);
                            });
                            grpid.forEach(function (e) {
                                if (objgrp.includes(e) === false)
                                    delmems.push(e);
                            });
                            addmems = addmems.filter(function (e) { return e !== ""; });
                            delmems = delmems.filter(function (e) { return e !== ""; });
                            obj1 = {
                                name: obj.name,
                                phone: obj.mobileNumber,
                                email: obj.email,
                                address: obj.address,
                                add: addmems,
                                countryCode: obj.countryCode.value,
                                delete: delmems,
                            };
                            if (additionalValues) {
                                for (val in additionalValues) {
                                    // dropdown values
                                    if (((_c = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _c === void 0 ? void 0 : _c.label) &&
                                        ((_d = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _d === void 0 ? void 0 : _d.value)) {
                                        obj1[val] = (_e = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val]) === null || _e === void 0 ? void 0 : _e.value;
                                    }
                                    else {
                                        obj1[val] = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues[val];
                                    }
                                }
                            }
                            config = {
                                headers: {
                                    Authorization: "bearer " + token,
                                },
                            };
                            url = IP + APIMap("editcontact");
                            url = url.replace("{id}", obj.id);
                            return [4 /*yield*/, axios.put(url, obj1, config)];
                        case 1:
                            response = _f.sent();
                            return [2 /*return*/, response];
                    }
                });
            });
        };
        var deleteContactList = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("deletecontact");
                        url = url.replace("{id}", param.queryKey[1]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        //contactgroup management
        var addcontactgrp = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj1 = {
                            groupName: obj.name,
                            ids: obj.id,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("addcontactgrp");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var Listcontactgrp = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("contactgrouplist");
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deletecontactgrp = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("deletecontactgrp");
                        url = url.replace("{groupname}", param.queryKey[1]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var editcontactgrp = function (obj) { return __awaiter(void 0, void 0, void 0, function () {
            var addmems, delmems, grpid, obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        addmems = [];
                        delmems = [];
                        grpid = [];
                        grpid = ContactGroupDetails.members.split(",");
                        obj.id.forEach(function (e) {
                            if (grpid.includes(e) === false)
                                addmems.push(e);
                        });
                        grpid.forEach(function (e) {
                            if (obj.id.includes(e) === false)
                                delmems.push(e);
                        });
                        obj1 = {
                            addMems: addmems,
                            delMems: delmems,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("updatecontactgrp");
                        url = url.replace("{groupname}", obj.name);
                        return [4 /*yield*/, axios.put(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getdistributionlists = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("getdistlist");
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getdistributionlistsSMS = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("getdistlist") + "?channel=sms";
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getdistributionlistsEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("getdistlistEmail") + "?channel=email";
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        ////console.log("authcontext", response);
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deletedistibutionlist = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("deletedistlist");
                        // var url = "http://localhost:6009/" + APIMap("deletedistlistEmail");
                        url = url.replace("{id}", param.queryKey[1]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deletedistibutionlistEmail = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("deletedistlist");
                        // var url = "http://localhost:6009/" + APIMap("deletedistlistEmail");
                        url = url.replace("{id}", param.queryKey[1]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var editdistributionlist = function (obj, distlistdetail, values) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj1 = {
                            jobId: distlistdetail.jobId ? distlistdetail.jobId : null,
                            count: (obj === null || obj === void 0 ? void 0 : obj.totalUnique) ? obj.totalUnique : null,
                            desc: values.description,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("editdistlist");
                        url = url.replace("{id}", distlistdetail.listId);
                        return [4 /*yield*/, axios.put(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var editdistributionlistEmail = function (obj, distlistdetail, values) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj1 = {
                            jobId: distlistdetail.jobId ? distlistdetail.jobId : null,
                            count: (obj === null || obj === void 0 ? void 0 : obj.totalUnique) ? obj.totalUnique : null,
                            desc: values.description,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("editdistlistEmail");
                        url = url.replace("{id}", distlistdetail.listId);
                        return [4 /*yield*/, axios.put(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var createdistlist = function (jobId, filesummary, values) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("values", values);
                        console.log("filesummary", filesummary);
                        obj1 = {
                            name: values.name,
                            count: filesummary.totalUnique,
                            jobId: jobId,
                            dlSummary: true,
                            status: "A",
                            desc: values.description,
                            channel: filesummary.channel,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("createdistlist");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var createdistlistEmail = function (jobId, filesummary, values) { return __awaiter(void 0, void 0, void 0, function () {
            var obj1, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj1 = {
                            name: values.name,
                            count: filesummary.totalUnique,
                            jobId: jobId,
                            dlSummary: true,
                            status: "A",
                            desc: values.description,
                        };
                        config = {
                            headers: {
                                Authorization: "bearer " + token,
                            },
                        };
                        url = IP + APIMap("createdistlistEmail");
                        return [4 /*yield*/, axios.post(url, obj1, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        //Role Manangement
        var getTemplates = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getResourcesTemplates");
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var deleteRoleByName = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("deleteRoleById");
                        url = url.replace("{roleId}", param.queryKey[1]);
                        return [4 /*yield*/, axios.delete(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getResourcesByTemplate = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getResourcesByTemplate");
                        url = url.replace("{templateName}", param.queryKey[1]);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var addRole = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var obj, arr, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj = JSON.parse(JSON.stringify(param));
                        arr = obj.grants.filter(function (el) { return el.check === true; });
                        arr.forEach(function (el) {
                            delete el.check;
                        });
                        obj.grants = arr;
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("addRole");
                        return [4 /*yield*/, axios.post(url, obj, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var updateRole = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var obj, arr, config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        obj = JSON.parse(JSON.stringify(param.obj));
                        delete obj.template;
                        delete obj.roleName;
                        arr = obj.grants.filter(function (el) { return el.check === true; });
                        arr.forEach(function (el) {
                            delete el.check;
                            if (Object.keys(el).includes("resourcedisName")) {
                                delete el.resourcedisName;
                            }
                        });
                        obj.grants = arr;
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("updateRoleById");
                        url = url.replace("{roleId}", param.editRoleData);
                        return [4 /*yield*/, axios.put(url, obj, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var rolePaginationApi = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, startDate, endDate, filterType, search, url, response;
            var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 1 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + queryKey[1],
                            },
                        };
                        startDate = queryKey[2] && queryKey[3] ? "&fromdate=".concat(queryKey[2]) : "";
                        endDate = queryKey[2] && queryKey[3] ? "&todate=".concat(queryKey[3]) : "";
                        filterType = startDate && endDate ? "&filtertype=custom" : "";
                        search = queryKey[4] ? "&name=".concat(queryKey[4]) : "";
                        url = IP + APIMap("getRoles");
                        url =
                            url +
                                "?pgsize=".concat(tableDetails.pageSize, "&pgindex=").concat(pageParam).concat(startDate).concat(endDate).concat(filterType).concat(search);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _d.sent();
                        return [2 /*return*/, { response: response, nextPage: pageParam + 1 }];
                }
            });
        }); };
        var getRoleById = function (param) { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getRoleById");
                        url = url.replace("{roleId}", param.queryKey[1]);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var roleForExport = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, startDate, endDate, filterType, search, url, response;
            var queryKey = _b.queryKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + queryKey[1],
                            },
                        };
                        startDate = queryKey[2] ? "&fromdate=".concat(queryKey[2]) : "";
                        endDate = queryKey[3] ? "&todate=".concat(queryKey[3]) : "";
                        filterType = startDate && endDate ? "&filtertype=custom" : "";
                        search = queryKey[4] ? "&name=".concat(queryKey[4]) : "";
                        url = IP + APIMap("getRoles");
                        url = url + "?paginate=0".concat(startDate).concat(endDate).concat(filterType).concat(search);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var roleSearchApi = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, url, response;
            var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 1 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getRoles");
                        url =
                            url +
                                "?name=".concat(queryKey[1], "&pgsize=").concat(tableDetails.pageSize, "&pgindex=").concat(pageParam);
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _d.sent();
                        return [2 /*return*/, { response: response, nextPage: pageParam + 1 }];
                }
            });
        }); };
        var getRoleByGroup = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getRoles");
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getRoleResource = function () { return __awaiter(void 0, void 0, void 0, function () {
            var config, url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = IP + APIMap("getRoleResource");
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _a.sent();
                        setResourceData(response.data);
                        return [2 /*return*/, response];
                }
            });
        }); };
        var getDateFilter = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var config, url, response;
            var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 1 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        config = {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        };
                        url = "";
                        if (queryKey[3] !== "custom") {
                            url = IP + APIMap("getRoles");
                            url =
                                url +
                                    "?filtertype=".concat(queryKey[3], "&pgsize=").concat(tableDetails.pageSize, "&pgindex=").concat(pageParam);
                        }
                        else {
                            url = IP + APIMap("getRoles");
                            url =
                                url +
                                    "?filtertype=".concat(queryKey[3], "&fromdate=").concat(queryKey[1], "&todate=").concat(queryKey[2], "&pgsize=").concat(tableDetails.pageSize, "&pgindex=").concat(pageParam);
                        }
                        return [4 /*yield*/, axios.get(url, config)];
                    case 1:
                        response = _d.sent();
                        return [2 /*return*/, {
                                response: response,
                                nextPage: pageParam + 1,
                            }];
                }
            });
        }); };
        var userStatus = function (email) { return __awaiter(void 0, void 0, void 0, function () {
            var url, obj, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = IP + APIMap("verifyUserStatus");
                        obj = { user: email };
                        return [4 /*yield*/, axios.post(url, obj)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        var OnBoardingApis = {
            signup: signup,
            EmailNotification: EmailNotification,
            setPassword: setPassword,
            forgotPassword: forgotPassword,
            getScreenElement: getScreenElement,
            getPolicy: getPolicy,
            getTerms: getTerms,
            setMobileNumber: setMobileNumber,
            verifyOtp: verifyOtp,
            setLoader: setLoader,
            resetPassword: resetPassword,
            login: login,
            loginMfa: loginMfa,
            sendMfaOtp: sendMfaOtp,
            resendMfaOtp: resendMfaOtp,
        };
        var loggedInRole = determineUserRole(userRole);
        return {
            user: user,
            isAppTimeout: isAppTimeout, // for timeout dialog before abrupt logout
            usercredential: usercredential,
            usermfa: usermfa,
            userEmail: userEmail,
            email: email,
            Time: Time,
            setUser: setUser,
            signup: signup,
            setPassword: setPassword,
            otp: otp,
            login: login,
            sendMfaOtp: sendMfaOtp,
            resendMfaOtp: resendMfaOtp,
            logout: logout,
            clearStorage: clearStorage,
            deletefile: deletefile,
            addEmailTemplate: addEmailTemplate,
            forgotPassword: forgotPassword,
            OnBoardingApis: OnBoardingApis,
            resetPassword: resetPassword,
            verifyEmail: verifyEmail,
            SendOtp: SendOtp,
            getPolicy: getPolicy,
            EmailNotification: EmailNotification,
            getTerms: getTerms,
            getScreenElement: getScreenElement,
            getResourcesByTemplate: getResourcesByTemplate,
            Lang: Lang,
            BackGroundImage: BackGroundImage,
            Logo: Logo,
            Copyright: Copyright,
            cpassLogo: cpassLogo,
            verifyOtp: verifyOtp,
            handleClose: handleClose,
            getsummary: getsummary,
            setLoader: setLoader,
            getRoleById: getRoleById,
            smsTemplates: smsTemplates,
            emailTemplates: emailTemplates,
            addSmsTemplate: addSmsTemplate,
            getSmsTemplates: getSmsTemplates,
            getEmailTemplates: getEmailTemplates,
            deleteSmsTemplate: deleteSmsTemplate,
            addEmailTemplate: addEmailTemplate,
            getEmailTemplates: getEmailTemplates,
            deleteSmsTemplate: deleteSmsTemplate,
            deleteEmailTemplate: deleteEmailTemplate,
            getTemplateById: getTemplateById,
            getRoleByGroup: getRoleByGroup,
            smsTemplateDetails: smsTemplateDetails,
            setSmsTemplateDetails: setSmsTemplateDetails,
            EmailTemplateDetails: EmailTemplateDetails,
            setEmailTemplateDetails: setEmailTemplateDetails,
            setContactDetails: setContactDetails,
            filesummary: filesummary,
            setfilesummary: setfilesummary,
            userStatus: userStatus,
            ContactDetails: ContactDetails,
            ContactGroupDetails: ContactGroupDetails,
            distributionListDetails: distributionListDetails,
            setdistributionListDetails: setdistributionListDetails,
            setContactGroupDetails: setContactGroupDetails,
            singleUserDetails: singleUserDetails,
            setSingleUserDetails: setSingleUserDetails,
            getRoleResource: getRoleResource,
            roleSearchApi: roleSearchApi,
            roleForExport: roleForExport,
            updateRole: updateRole,
            editSmsTemplate: editSmsTemplate,
            editEmailTemplate: editEmailTemplate,
            editContactList: editContactList,
            changePassword: changePassword,
            updateUser: updateUser,
            deleteRoleByName: deleteRoleByName,
            deleteContactList: deleteContactList,
            deletecontactgrp: deletecontactgrp,
            rolePaginationApi: rolePaginationApi,
            editRoleData: editRoleData,
            userData: userData,
            setResourceData: setResourceData,
            getTemplates: getTemplates,
            state: state,
            getUser: getUser,
            addRole: addRole,
            resourceData: resourceData,
            getDateFilter: getDateFilter,
            setEditRoleData: setEditRoleData,
            setAccountsEditData: setAccountsEditData,
            accountsEditData: accountsEditData,
            setPackagesEditData: setPackagesEditData,
            packagesEditData: packagesEditData,
            setRateSheetEditData: setRateSheetEditData,
            rateSheetEditData: rateSheetEditData,
            userDetails: userDetails,
            addcontact: addcontact,
            addbulkcontact: addbulkcontact,
            FileUpload: FileUpload,
            RateSheetFileUpload: RateSheetFileUpload,
            DLFileUpload: DLFileUpload,
            DLFileUploadEmail: DLFileUploadEmail,
            editDLFileUpload: editDLFileUpload,
            editEmailDLFileUpload: editEmailDLFileUpload,
            addcontactgrp: addcontactgrp,
            ContactList: ContactList,
            ContactListForExport: ContactListForExport,
            contactSearchApi: contactSearchApi,
            Listcontactgrp: Listcontactgrp,
            editcontactgrp: editcontactgrp,
            deletedistibutionlist: deletedistibutionlist,
            deletedistibutionlistEmail: deletedistibutionlistEmail,
            createdistlist: createdistlist,
            createdistlistEmail: createdistlistEmail,
            uploadblockedword: uploadblockedword,
            uploadreplacedword: uploadreplacedword,
            getdistributionlists: getdistributionlists,
            getdistributionlistsSMS: getdistributionlistsSMS,
            getdistributionlistsEmail: getdistributionlistsEmail,
            editdistributionlist: editdistributionlist,
            editdistributionlistEmail: editdistributionlistEmail,
            setTemplates: setTemplates,
            roleTemplates: roleTemplates,
            showSessionExpiredModal: showSessionExpiredModal,
            setShowSessionExpiredModal: setShowSessionExpiredModal,
            packages: packages,
            setPackages: setPackages,
            rateSheets: rateSheets,
            setRateSheets: setRateSheets,
            rateSheetDetails: rateSheetDetails,
            setRateSheetDetails: setRateSheetDetails,
            userScope: userScope,
            userRole: userRole,
            loggedInRole: loggedInRole, // EA, PA, EU, NA
            userGroups: userGroups,
            selWaTemplateData: selWaTemplateData,
            setSelWaTemplateData: setSelWaTemplateData,
            enableVoice: enableVoice,
            setEnableVoice: setEnableVoice,
            setProviderTab: setProviderTab,
            setRoutingTab: setRoutingTab,
            routingTab: routingTab,
            providerTab: providerTab,
            numberData: numberData,
            setNumberData: setNumberData,
            vrDetails: vrDetails,
            setVrDetails: setVrDetails,
            allCountries: allCountries,
            getAllCountries: getAllCountries,
            beeInstance: beeInstance,
            collapsed: collapsed,
            setCollapsed: setCollapsed,
            addEmailTemplate: addEmailTemplate,
            editRichMediaEmailTemplate: editRichMediaEmailTemplate,
            getGlobalConfig: getGlobalConfig,
            globalConfig: globalConfig,
            globalConfigMfa: globalConfigMfa,
            currencyCode: currencyCode,
            currencySymbol: currencySymbol,
            allowedChannels: allowedChannels,
            audio: audio,
            setAudio: setAudio,
            tab: tab,
            setTab: setTab,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        token,
        user,
        usercredential,
        usermfa,
        state,
        userEmail,
        accountsEditData,
        packagesEditData,
        rateSheetEditData,
        userData,
        editRoleData,
        smsTemplateDetails,
        EmailTemplateDetails,
        ContactDetails,
        ContactGroupDetails,
        distributionListDetails,
        singleUserDetails,
        filesummary,
        resourceData,
        userDetails,
        smsTemplates,
        emailTemplates,
        email,
        IP,
        roleTemplates,
        otp,
        showSessionExpiredModal,
        packages,
        rateSheets,
        rateSheetDetails,
        userScope,
        userRole,
        userGroups,
        selWaTemplateData,
        setSelWaTemplateData,
        enableVoice,
        providerTab,
        routingTab,
        numberData,
        vrDetails,
        allCountries,
        beeInstance,
        collapsed,
        globalConfig,
        globalConfigMfa,
        audio,
        tab,
    ]);
    // if (!isLoaded) {
    //   return <ThemedSuspense />;
    // }
    return _jsx(AuthContext.Provider, { value: value, children: children });
};
