import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import Sidebar from "./Sidebar";
import { SidebarContext } from "../../context/SidebarContext";
function MobileSidebar() {
    var isSidebarOpen = useContext(SidebarContext).isSidebarOpen;
    return (_jsx("div", { className: "".concat(isSidebarOpen
            ? "bg-black fixed inset-0 z-40 bg-opacity-30 w-full lg:hidden"
            : ""), children: _jsx("aside", { className: "fixed z-50  inset-y-0  flex-shrink-0 w-[201px] left-0  transition duration-500 ease-in-out ".concat(isSidebarOpen ? " " : "-translate-x-56 ", "  dark:bg-gray-800 "), children: _jsx(Sidebar, {}) }) }));
}
export default MobileSidebar;
