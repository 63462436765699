import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import paymentTimerIcon from "../assets/img/PaymentTimerImg.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPaymentStatus, finalizationAPIforEAnd, } from "../lib/payment-provider-apis";
import { useState } from "react";
import { useEffect } from "react";
import { t } from "i18next";
export default function PaymentTimer() {
    var _a, _b, _c;
    var location = useLocation();
    var timerDuration = parseInt((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.duration);
    var url = (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.url;
    var token = ((_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.token) || sessionStorage.getItem("access");
    var _d = useState(url !== "" || url !== undefined), enabled = _d[0], setEnabled = _d[1];
    var navigate = useNavigate();
    useEffect(function () {
        var _a;
        if (!((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.duration)) {
            navigate("/app/header/wallets", { state: { status: "" } });
        }
    });
    var data = useQuery({
        queryKey: ["product"],
        enabled: enabled,
        queryFn: function () {
            var _a, _b;
            return url.includes("eand/finalization")
                ? finalizationAPIforEAnd(((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.txnId) ||
                    window.localStorage.getItem("latest_transaction_id"), token)
                : fetchPaymentStatus(url, token, (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.txnId);
        },
        refetchInterval: 5000,
        onSuccess: function (res) {
            var _a, _b;
            var status = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status;
            if (status === "Pending" || status === "Unknown") {
                setEnabled(true);
            }
            else {
                setEnabled(false);
                navigate("/app/header/wallets", { state: { status: status, url: url } });
            }
        },
        onError: function () {
            setEnabled(true);
            navigate("/app/header/wallets", { state: { status: "Failed", url: url } });
        },
    }).data;
    return (_jsxs("div", { className: "flex flex-col justify-center items-center h-[99vh]", children: [_jsx("img", { src: paymentTimerIcon, alt: "Timer Icon", className: "h-60 w-60" }), _jsx("h1", { className: "text-3xl", children: t("_Processing_your_payment...") }), _jsx("h3", { className: "text-2xl mt-6 mb-8", children: t("_Do_not_refresh_this_page,_You_will_be_redirected_after_the_transaction_is_complete.") }), _jsx(CountdownCircleTimer, { isPlaying: true, duration: timerDuration, colors: ["#004777", "#F7B801", "#A30000", "#A30000"], colorsTime: [7, 5, 2, 0], onComplete: function () {
                    setEnabled(false);
                    if (!url) {
                        navigate("/app/header/wallets", {
                            state: { status: "Completed", url: url },
                        });
                    }
                    else {
                        navigate("/app/header/wallets", {
                            state: { status: "TimedOut", url: url },
                        });
                    }
                }, children: function (_a) {
                    var remainingTime = _a.remainingTime;
                    return remainingTime;
                } })] }));
}
