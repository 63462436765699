import { t } from "i18next";
export var workFlowStatusEA = {
    0: "Pending",
    4: "Active",
    6: "Rejected",
    7: "Inactive",
    8: "Active",
};
export var emailWorkFlowStatusEA = {
    0: "Pending",
    1: "Docs_Required",
    2: "Uploaded_Docs",
    3: "Docs_Verified",
    4: "Active",
    5: "Correction_needed",
    6: "Rejected",
    7: "Inactive",
};
export var workFlowStatusPA = {
    4: "Active",
    7: "Inactive",
    8: "Active",
};
export var EmailworkFlowStatusPA = {
    requested: "Requested",
    1: "Docs_Required",
    2: "Uploaded_Docs",
    3: "Docs_Verified",
    4: "Active",
    5: "Correction_needed",
    6: "Rejected",
    7: "Inactive",
};
export var workFlowAgentPA = {
    1: "Active",
    0: "Inactive",
};
export var statusColourMapping = {
    Active: "Lime-Green",
    Inactive: "Grey",
    Activated: "Lime-Green",
    Rejected: "Bittersweet",
    Pending: "[#FFC249]",
    Expired: "Grey",
};
export var smsStatusColorMapping = {
    0: "[#FFC249]",
    1: "Lime-Green",
    2: "Bittersweet",
};
export var providerStatusColorMapping = {
    0: "[#FFC249]",
    1: "Lime-Green",
};
export var emailStatusColourMapping = {
    Correction_needed: "black",
    Docs_verified: "red",
    Uploaded_Docs: "[#FFC278]",
    Docs_Required: "black",
    Requested: "[#FFC249]",
    Pending: "[#FFC249]",
    Approved: "Lime-Green",
    Active: "Lime-Green",
    Rejected: "Bittersweet",
    Inactive: "Grey",
};
export var registrationType = {
    0: "Pre-Registration",
    1: "Dynamic",
};
export var tableDetails = {
    pageSize: 10,
    pagedatas: 100000,
};
export var errorResponseStatus = {
    UNAUTHORISED: 401,
    INTERNALSERVERERROR: 500,
};
export var errorResponseMessages = {
    sessionExpired: "Session expired. Please login again.",
};
export var packageStatus = {
    active: 1,
    inactive: 0,
};
export var ratesheetType = {
    GLOBAL: "Default",
    GENERIC: "Hidden",
    ENTERPRISE: "Enterprise",
};
export var roleName = {
    enterpriseAdmin: "Enterprise Account Admin",
    enterpriseUser: "Enterprise User",
    platformAdmin: "Platform Admin",
    globalUser: "sysrole",
};
var moduleNames = {
    senderAddress: "Sender Address Management",
    rateSheet: "Rate Sheet Management",
    package: "Package Management",
    numbers: "Numbers",
    channelSettings: "Platform Channel Settings",
    wallets: "Wallet Management",
    smsCampaign: "Campaign-SMS",
    waCampaign: "Campaign-WhatsApp",
    waTamplate: "Template-WhatsApp",
};
var iconMapping = {
    senderAddress: "SMS",
    smsCampaign: "SMS",
    rateSheet: "Billing",
    package: "Billing",
    waCampaign: "Whatsapp",
    waTamplate: "Whatsapp",
    wallets: "wallet",
    numbers: "Numbers",
};
export var getIconMapping = function (moduleName) {
    var keyName = Object.keys(moduleNames).find(function (key) { return moduleNames[key] === moduleName; });
    return iconMapping[keyName];
};
export var maxquantity_package = {
    maxlimit: 10,
};
export var salesTeamEmailId = {
    mailIds: ["pradeepan.ramakrishnan@openturf.in"],
};
export var productNames = {
    allchannels: "All Channels",
    sms: "SMS",
    whatsapp: "WhatsApp",
    voice: "Voice",
    email: "Email",
    virtualreceptionist: "Virtual Receptionist",
    rcs: "RCS",
    chatbot: "Chatbot",
    network: "Network",
};
export var notificationCategories = {
    platformCapabilities: "Platform Capabilities",
    sms: "Sms",
    whatsapp: "WhatsApp",
    common: "Common",
    voice: "Voice",
    email: "Email",
    rcs: "Rcs",
};
var channelNames = {
    sms: "SMS",
    email: "E-Mail",
    web: "Web",
};
export var templateStatusMap = {
    0: "Pending",
    1: "Active",
    2: "Rejected",
};
export var templateCategoryMap = {
    0: "Promotional",
    1: "Transactional",
    2: "OTP",
};
export var campCategoryMap = {
    0: "PROMO",
    1: "TXN",
    3: "OTP",
};
export var smsTempCategoryMap = {
    PROMO: 0,
    TXN: 1,
    OTP: 2,
};
export var getSMSCategoryName = function (category) {
    if (templateCategoryMap[category] == undefined) {
        return templateCategoryMap["0"];
    }
    return templateCategoryMap[category];
};
export var getChannelName = function (name) {
    return channelNames[name];
};
//Whatsapp Profile Status
export var profileStatus = function (wastatus) {
    var status = {
        live: "live",
        pending_deletion: "pending_deletion",
        permission_granted: "permission_granted",
        ready: "ready",
    };
    return status[wastatus];
};
export var waProfileMsgLimit = function (msgLimit) {
    if (msgLimit === "TIER_1K")
        return "1K Customers/24 hours";
    else if (msgLimit === "TIER_10K")
        return "10K Customers/24 hours";
    else if (msgLimit === "TIER_100K")
        return "100K Customers/24 hours";
    else if (msgLimit === "TIER_0.25K")
        return "250 Customers/24 hours";
    else if (msgLimit === "TIER_50")
        return "50 Customers/24 hours";
    else if (msgLimit === "TIER_250")
        return "250 Customers/24 hours";
    else if (msgLimit === "TIER_UNLIMITED")
        return "UnLimited Customers/24 hours";
    else
        return msgLimit;
};
export var profileQuality = function (quality) {
    if (quality === "GREEN")
        return "High";
    else if (quality === "YELLOW")
        return "Medium";
    else if (quality === "RED")
        return "Low";
    else
        return quality;
};
export var businessOptions = [
    { value: "BEAUTY", label: "_Beauty,_Spa_and_Salon" },
    { value: "APPAREL", label: "_Clothing_and_Apparel" },
    { value: "EDU", label: "_Education" },
    { value: "ENTERTAIN", label: "_Entertainment" },
    { value: "FINANCE", label: "_Finance_and_Banking" },
    { value: "GROCERY", label: "_Food_and_Grocery" },
    { value: "GOVT", label: "_Public_Service" },
    { value: "HOTEL", label: "_Hotel_and_Lodging" },
    { value: "HEALTH", label: "_Medical_and_Health" },
    { value: "NONPROFIT", label: "_Non-profit" },
    { value: "PROF_SERVICES", label: "_Professional_Services" },
    { value: "RETAIL", label: "_Shopping_and_Retail" },
    { value: "TRAVEL", label: "_Travel_and_Transportation" },
    { value: "HOTEL", label: "_Restaurant" },
    {
        value: "TRAVEL",
        label: "_Event_Planning_and_Service",
    },
    { value: "OTHER", label: "Other" },
];
// Camapaign States
// VERIFYING(0),REJECTED(1),ONGOING(2),COMPLETED(3),PAUSED(4),CANCELLED(5),SCHEDULED(6),SUBMITCOMPLETE(7),FAILED(8),EXPANDED(9);
export var campStatus = function (status) {
    if (status === "VERIFYING")
        return t("_Verifying");
    if (status === "SCHEDULED")
        return t("_Scheduled");
    if (status === "REJECTED")
        return t("_Rejected");
    if (status === "FAILED")
        return t("_Failed");
    if (status === "PAUSED")
        return t("_Paused");
    if (status === "CANCELLED")
        return t("_Cancelled");
    if (status === "COMPLETED")
        return t("_Completed");
    if (status === "SUBMITCOMPLETE")
        return t("_Completed");
    if (status === "SUBMITED")
        return t("_Submitted");
    if (status === "EXPANDED" || status === "ONGOING")
        return t("_Processing");
    if (status === "PARKED")
        return t("_Parked");
    if (status === "EXPIRED")
        return t("_Expired");
    if (status === "QUIETTIME")
        return t("Paused_(Quiet Time)");
    if (status === "DROPPED")
        return "Dropped";
    if (status === "PARTIALLY_COMPLETED")
        return "Partial";
    else
        return status;
};
export var allCampStatuses = [
    "VERIFYING",
    "SCHEDULED",
    "REJECTED",
    "FAILED",
    "PAUSED",
    "CANCELLED",
    "COMPLETED",
    "SUBMITCOMPLETE",
    "SUBMITED",
    "EXPANDED",
    "PARKED",
    "EXPIRED",
    "QUIETTIME",
    "DROPPED",
    "PARTIALLY_COMPLETED",
];
export var allCampStatusColors = function (status) {
    if (status === "VERIFYING")
        return "#2f4f4f";
    if (status === "SCHEDULED")
        return "#FFB049";
    if (status === "REJECTED")
        return "#7f0000";
    if (status === "FAILED")
        return "#000080";
    if (status === "PAUSED")
        return "#ff8c00";
    if (status === "CANCELLED")
        return "#ffff00";
    if (status === "COMPLETED")
        return "#00ff00";
    if (status === "SUBMITCOMPLETE")
        return "#00ffff";
    if (status === "SUBMITED")
        return "#ff00ff";
    if (status === "EXPANDED" || status === "ONGOING")
        return "#1e90ff";
    if (status === "PARKED")
        return "#1e90ff";
    if (status === "EXPIRED")
        return "#ff69b4";
    if (status === "QUIETTIME")
        return "#ff8c00";
    if (status === "DROPPED")
        return "#ff8c00";
    if (status === "PARTIALLY_COMPLETED")
        return "#ff8c00";
};
export var whatsappTemplateStatus = function (status) {
    if (status === "submitted")
        return "Submitted";
    if (status === "approved")
        return "Approved";
    if (status === "rejected")
        return "Rejected";
    if (status === "pending")
        return "Pending";
    else
        return status;
};
export var templateStatusStyleMapping = function (status) {
    if (status === "submitted")
        return "CornflowerBlue";
    if (status === "approved")
        return "Lime-Green";
    if (status === "rejected")
        return "Bittersweet";
    if (status === "pending")
        return "FadeYellow";
};
export var logEvent = function (event) {
    if (event === "update" || event === "updated" || event === "Updated")
        return "Updated";
    if (event === "create" || event === "created")
        return "Created";
    if (event === "delete" || event === "deleted")
        return "Deleted";
    if (event === "activated")
        return "Activated";
    if (event === "deactivated")
        return "Deactivated";
    if (event === "transferred")
        return "Transferred";
    if (event === "debited")
        return "Debited";
    if (event === "credited")
        return "Credited";
    if (event === "purchased")
        return "Purchased";
    else
        return event;
};
export var waCategoryOptions = [
    // { value: "OTP", label: "_OTP" },
    // { value: "TRANSACTIONAL", label: "_Transactional" },
    { value: "MARKETING", label: "_Marketing" },
    { value: "AUTHENTICATION", label: "_Authentication" },
    { value: "UTILITY", label: "_Utility" },
];
export var getLanguage = {
    af: "Afrikaans",
    sq: "Albanian",
    ar: "Arabic",
    az: "Azerbaijani",
    bn: "Bengali",
    bg: "Bulgarian",
    ca: "Catalan",
    zh_CN: "Chinese (CHN)",
    zh_HK: "Chinese (HKG)",
    zh_TW: "Chinese (TAI)",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    en_GB: "English (UK)",
    en_US: "English (US)",
    fil: "Filipino",
    fi: "Finnish",
    fr: "French",
    ka: "Georgian",
    de: "German",
    el: "Greek",
    gu: "Gujarati",
    ha: "Hausa",
    he: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    id: "Indonesian",
    ga: "Irish",
    it: "Italian",
    ja: "Japanese",
    kn: "Kannada",
    kk: "Kazakh",
    rw_RW: "Kinyarwanda",
    ko: "Korean",
    ky_KG: "Kyrgyz (Kyrgyzstan)",
    lo: "Lao",
    lv: "Latvian",
    lt: "Lithuanian",
    mk: "Macedonian",
    ms: "Malay",
    ml: "Malayalam",
    mr: "Marathi",
    nb: "Norwegian",
    fa: "Persian",
    pl: "Polish",
    pt_BR: "Portuguese (BR)",
    pt_PT: "Portuguese (POR)",
    pa: "Punjabi",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    es_AR: "Spanish (ARG)",
    es_ES: "Spanish (SPA)",
    es_MX: "Spanish (MEX)",
    sw: "Swahili",
    sv: "Swedish",
    ta: "Tamil",
    te: "Telugu",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    vi: "Vietnamese",
    zu: "Zulu",
};
export var sampleFilePathMapping = {
    coolOffPeriod: "Cool-Off-period-sample.xlsx",
    numberTemplate: "numbers_template.xlsx",
    costsheet: "Cost Sheet.csv",
    waPlaceholder: "wa-placeholder-sample.xlsx",
    placeholder: "placeholder-sample.xlsx",
    recipient: "recipients-sample.xlsx",
    recipientEmail: "recipients-emailsample.xlsx",
    message: "message-sample.xlsx",
    contacts: "contacts_sample.txt",
    contactsCsv: "contacts.csv",
    distListEmail: "distribution_list_email.txt",
    distList: "distribution_list.txt",
    generateReportPASample: "Reports_Platform_Admin.xlsx",
    ratesheetVoice: "ratesheets_voice-sample.xlsx",
    ratesheet: "ratesheet-sample.xlsx",
    blacklistNumbers: "blacklist-numbers.csv",
    DNDNumbers: "dnd-numbers.xlsx",
};
export var rateNames = {
    ratesheet: "unit based",
    slab: "slab based",
};
export var roleAliases = {
    "Platform Admin": "Admin",
    "Platform Marketing Admin": "Marketing",
    "Platform Finance Admin": "Finance",
    "Enterprise Account Admin": "Enterprise Account Admin",
    "Enterprise User": "Enterprise User",
};
