/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
var routes = [
    {
        path: "/app/home",
        icon: "HomeIcon",
        name: "_Home",
    },
    {
        path: "",
        icon: "InsightsIcon",
        name: "Insights",
        subRoutes: [
            {
                path: "/app/insights/dashboardEA",
                name: "_Dashboard_EA",
            },
            {
                path: "/app/insights/dashboardPA",
                name: "_Dashboard_PA",
            },
        ],
    },
    {
        path: "",
        icon: "InsightsIcon",
        name: "_Reports",
        subRoutes: [
            {
                path: "/app/insights/reports",
                name: "Reports",
            },
        ],
    },
    {
        path: "",
        icon: "InsightsIcon",
        name: "_Reports_PA",
        subRoutes: [
            {
                path: "/app/usage-reports",
                name: "Usage",
            },
            {
                path: "/app/provider-reports",
                name: "Provider",
            },
        ],
    },
    {},
    {
        path: "",
        icon: "SmsIcon",
        name: "_SMS",
        subRoutes: [
            // {
            //   path: "/app/Sms/Getting-Started",
            //   name: "Getting Started",
            // },
            {
                path: "/app/sms/dashboard",
                name: "_Dashboard",
            },
            {
                path: "/app/Sms/reports",
                name: "_Reports",
            },
            {
                path: "/app/sms/my-campaigns",
                name: "_My_Campaigns",
            },
            {
                path: "/app/sms/manage-templates",
                name: "_Manage_Templates",
            },
            {
                path: "/app/sms/mo-services",
                name: "_MO_Services",
            },
            {
                path: "/app/sms/Register/sender-addresses",
                name: "_Sender_Addresses -EA",
            },
            {
                path: "/app/sms/sender-address-specifications",
                name: "_Sender_Addresses -PA",
            },
            {
                path: "/app/sms/smpp-session",
                name: "SMPP Session",
            },
            {
                path: "/app/Sms/short-codes",
                name: "_Short_Codes",
            },
            // {
            //   path: "/app/sms/epa",
            //   name: "Senderaddrs_epa"
            // },
            {
                path: "/app/Sms/sms-apis",
                name: "_SMS_APIs",
            },
        ],
    },
    {
        path: "",
        icon: "SmsIcon",
        name: "EMAIL",
        subRoutes: [
            {
                path: "/app/email/my-campaigns",
                name: "My Campaign",
            },
            {
                path: "/app/email/email-templates",
                name: "Template Management",
            },
            {
                path: "/app/404",
                name: "E-Mail ID Configuration",
            },
            {
                path: "/app/404",
                name: "Unsubscriber List",
            },
        ],
    },
    {
        path: "",
        icon: "SmsIcon",
        name: "POLICY",
        subRoutes: [
            {
                path: "/app/policy/contentfilter",
                name: "Content Filter",
            },
        ],
    },
    {
        path: "",
        icon: "SmsIcon",
        name: "EMAIL",
        subRoutes: [
            {
                path: "/app/email/my-campaigns",
                name: "My Campaigns",
            },
            {
                path: "/app/email/email-templates",
                name: "Template Management",
            },
            {
                path: "/app/404",
                name: "E-Mail ID Configuration",
            },
            {
                path: "/app/email/unsubscribe",
                name: "Unsubscribed List",
            },
            {
                path: "/app/email/email-address-specifications",
                name: "_Email_Addresses - PA",
            },
            {
                path: "/app/email/Register/email-addresses",
                name: "_Email_Addresses - EA",
            },
        ],
    },
    {
        path: "",
        icon: "WhatsappIcon",
        name: "_WhatsApp",
        subRoutes: [
            {
                path: "/app/whatsapp/getting-started",
                name: "Profiles",
            },
            {
                path: "/app/whatsapp/my-campaigns",
                name: "_My_Campaigns",
            },
            {
                path: "/app/whatsapp/sandbox",
                name: "_Sandbox",
            },
            {
                path: "/app/whatsapp/dashboard",
                name: "_Dashboard",
            },
            {
                path: "/app/whatsapp/templates",
                name: "_Manage_Templates",
            },
            {
                path: "/app/whatsapp/whatsapp-apis",
                name: "_WhatsApp_APIs",
            },
        ],
    },
    {
        path: "/app/voice",
        icon: "VoiceIcon",
        name: "Voice",
    },
    {
        path: "",
        icon: "NetworkIcon",
        name: "Network",
        subRoutes: [
            {
                path: "/app/deviceList",
                name: "Device List",
            },
            {
                path: "/app/deviceType",
                name: "Device Type",
            },
            {
                path: "/app/policyList",
                name: "Policy List",
            },
            {
                path: "/app/policyRules",
                name: "Policy Rules",
            },
            {
                path: "/app/ruleCriteria",
                name: "Rule Criteria",
            },
            {
                path: "/app/ruleEngine",
                name: "Rule Engine",
            },
        ],
    },
    {
        path: "/app/NumbersEA",
        icon: "NumbersIcon",
        name: "Numbers-EA",
    },
    {
        path: "/app/NumbersPA",
        icon: "NumbersIcon",
        name: "Numbers-PA",
    },
    {
        path: "/app/cloud-telephony",
        icon: "CloudIcon",
        name: "Cloud Telephony",
    },
    {
        path: "/app/outbound-contact-centre",
        icon: "PhoneForwardedIcon",
        name: "Outbound Contact Centre",
    },
    {
        path: "/app/Chatbots",
        icon: "ChatIcon",
        name: "Chat",
    },
    {
        path: "/app/inbound-contact-centre",
        icon: "PhoneBackwardIcon",
        name: "Inbound Contact Centre",
    },
    {},
    {
        path: "",
        icon: "AccountsIcon",
        name: "Accounts",
        subRoutes: [
            {
                path: "/app/accounts",
                name: "Accounts",
            },
        ],
    },
    {
        path: "",
        icon: "BillingIcon",
        name: "Billing & Pricing",
        subRoutes: [
            {
                path: "/app/billing-pricing/rate-sheets",
                name: "Rate Sheets",
            },
            {
                path: "/app/billing-pricing/invoicing",
                name: "Invoicing",
            },
            {
                path: "/app/billing-pricing/wallets",
                name: "Wallets",
            },
            {
                path: "/app/billing-pricing/packages",
                name: "Packages",
            },
            {
                path: "/app/billing-pricing/transfer-package",
                name: "Transfer Packages",
            },
        ],
    },
    {
        path: "",
        icon: "UserIcon",
        name: "User Management",
        subRoutes: [
            // {
            //   path: "/app/Sms/Getting-Started",
            //   name: "Getting Started",
            // },
            {
                path: "/app/user-management/roles",
                name: "Roles",
            },
            {
                path: "/app/user-management/users",
                name: "Users",
            },
        ],
    },
    {},
    {
        path: "",
        icon: "ContactIcon",
        name: "Contacts",
        subRoutes: [
            {
                path: "/app/contacts/distribution-list",
                name: "Distribution List",
            },
            {
                path: "/app/contacts/contact-list",
                name: "Contact List",
            },
            {
                path: "/app/contacts/contact-group",
                name: "Contact Group",
            },
        ],
    },
    {},
    {
        path: "",
        icon: "ContactIcon",
        name: "My Plans",
        subRoutes: [
            {
                path: "/app/myplans/packages",
                name: "Packages",
            },
            {
                path: "/app/myplans/ratesheets",
                name: "Rate Sheets",
            },
        ],
    },
    {},
    {
        path: "/app/notification_PA",
        icon: "NotificationIcon",
        name: "Alerts",
    },
    {
        path: "/app/DevTools", // the url
        icon: "DevtoolIcon", // the component being exported from icons/index.js
        name: "_API_Documentation", // name that appear in Sidebar
    },
];
export default routes;
