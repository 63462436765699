import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
//import SmsTemplatesList from "../pages/smsTemplatesList";
var Login = lazy(function () { return import("../pages/Login"); });
// const CreateAccount = lazy(() => import("../pages/CreateAccount"));
var ForgotPassword = lazy(function () { return import("../pages/ForgotPassword.js"); });
var SignUp = lazy(function () { return import("../pages/SignUp"); });
var EmailNotification = lazy(function () { return import("../pages/EmailNotification"); });
var ReEmailNotification = lazy(function () { return import("../pages/ReEmailNotification"); });
var Success = lazy(function () { return import("../pages/Success"); });
var VerifyNumber = lazy(function () { return import("../pages/VerifyNumber"); });
var SetPassword = lazy(function () { return import("../pages/SetPassword"); });
var OtpVerification = lazy(function () { return import("../pages/OtpVerification"); });
var OtpSuccessPage = lazy(function () { return import("../admin/pages/SuccessOtp"); });
var OtpFailurePage = lazy(function () { return import("../admin/pages/FailureOtp"); });
var MfaOtp = lazy(function () { return import("../admin/pages/MfaOtp.js"); });
var RePassword = lazy(function () { return import("../pages/RePassword"); });
var TermsAndConditions = lazy(function () { return import("../pages/Terms-and-conditions"); });
// const EnterpriseCreation = lazy(() => import("../pages/EnterpriseCreation"));
// const CreateDetailedReports = lazy(() =>
//   import("../pages/CreateDetailedReport")
// );
// const SmsTemplatesList = lazy(() => import("../pages/smsTemplatesList"));
var AdminLogin = lazy(function () { return import("../admin/pages/login"); });
function Auth() {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "login", element: _jsx(Login, {}) }), _jsx(Route, { path: "admin/login", element: _jsx(AdminLogin, {}) }), _jsx(Route, { path: "signup", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "email-notification", element: _jsx(EmailNotification, {}) }), _jsx(Route, { path: "reemail-notification", element: _jsx(ReEmailNotification, {}) }), _jsx(Route, { path: "forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "success", element: _jsx(Success, {}) }), _jsx(Route, { path: "verify-number", element: _jsx(VerifyNumber, {}) }), _jsx(Route, { path: "set-password", element: _jsx(SetPassword, {}) }), _jsx(Route, { path: "otp-verification", element: _jsx(OtpVerification, {}) }), _jsx(Route, { path: "reset-password", element: _jsx(RePassword, {}) }), _jsx(Route, { path: "Terms-and-condition/:action", element: _jsx(TermsAndConditions, {}) }), _jsx(Route, { path: "/", element: _jsx(Navigate, { to: "/auth/login" }) })] }));
}
export default Auth;
