var getIP = function () {
    var origin = window.location.origin;
    if (origin.startsWith("http://localhost")) {
        //REPLACE the hostname below to change which server GUI makes API calls to...
        return "https://dev1.ngagecpaas.com";
        // return "https://sb.ngagecpaas.com";
        //return "https://rcs.dev1.ngagecpaas.com";
        // return "https://autocpaas.comviva.com";
        //return "https://omocpaas.comviva.com";
        // return "http://emailcpaas.comviva.com:32606";
    }
    else {
        return origin;
    }
};
export default getIP;
