var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { MenuIcon, NotificationIcon } from "../icons";
import { SidebarContext } from "../context/SidebarContext";
import userIcon from "../assets/img/userIcon.png";
import { WalletIcon } from "../icons/index.js";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useQuery, useInfiniteQuery } from "react-query";
import { getBalance } from "../lib/wallet-service.js";
import { getNotifications, getUnreadCount } from "../lib/notification-apis";
import NotificationDropdown from "./Dropdowns/NotificationDropdown.js";
import ComvivaLogo from "../assets/img/comviva-logo.svg";
import { useTheme } from "../context/ThemeContext.js";
import axios from "axios";
import getIP from "../getIP.js";
var IP = getIP();
var Header = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _u = useTheme(), theme = _u.theme, setTheme = _u.setTheme;
    var _v = React.useState(false), showAccountDropDown = _v[0], setAccountShowDropDown = _v[1];
    var _w = React.useState(false), showCreditsDropDown = _w[0], setShowCreditsDropDown = _w[1];
    var _x = React.useState(false), showAlertDropDown = _x[0], setShowAlertDropDown = _x[1];
    var toggleSidebar = useContext(SidebarContext).toggleSidebar;
    var _y = useContext(AuthContext), logout = _y.logout, userData = _y.userData, userDetails = _y.userDetails, userScope = _y.userScope, loggedInRole = _y.loggedInRole, globalConfig = _y.globalConfig;
    var _z = useState(false), openInfoModal = _z[0], setOpenInfoModal = _z[1];
    var enableWallet = ((_a = globalConfig.find(function (e) { return e.key === "enableWallet"; })) === null || _a === void 0 ? void 0 : _a.value) || "true";
    var enableTransferCredits = ((_b = globalConfig.find(function (e) { return e.key === "enableTransferCredits"; })) === null || _b === void 0 ? void 0 : _b.value) ||
        "true";
    var smsDashboard = ((_c = globalConfig === null || globalConfig === void 0 ? void 0 : globalConfig.find(function (config) { return (config === null || config === void 0 ? void 0 : config.key) === "isSMSDashboardEnabled"; })) === null || _c === void 0 ? void 0 : _c.value) || "false";
    var _0 = useQuery(["getBalance", userDetails, userDetails === null || userDetails === void 0 ? void 0 : userDetails.sub], getBalance, {
        enabled: loggedInRole !== "PA",
        refetchInterval: 4000,
    }), data = _0.data, status = _0.status, isLoading = _0.isLoading;
    var url = "".concat(IP, "/api/v1/accounts/users?pgsize=10&pgindex=1");
    var fetchgroupID = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var config, res, error_1;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(queryKey[1]),
                        },
                    };
                    return [4 /*yield*/, axios.get(url, config)];
                case 1:
                    res = _c.sent();
                    return [2 /*return*/, res.data];
                case 2:
                    error_1 = _c.sent();
                    console.log("err", error_1);
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var groupid = useQuery(["getAccountListingData", userDetails === null || userDetails === void 0 ? void 0 : userDetails.token], fetchgroupID).data;
    var groupID = (_d = groupid === null || groupid === void 0 ? void 0 : groupid.data[0]) === null || _d === void 0 ? void 0 : _d.groupId;
    var _1 = useInfiniteQuery(["getNotifications", userDetails, userDetails === null || userDetails === void 0 ? void 0 : userDetails.sub], getNotifications, { enabled: (_e = userScope === null || userScope === void 0 ? void 0 : userScope.alerts) === null || _e === void 0 ? void 0 : _e.read }), notificationsData = _1.data, isNotificationLoading = _1.isLoading, notificationRefetch = _1.refetch;
    var _2 = useQuery(["getUnreadcount", userDetails, userDetails === null || userDetails === void 0 ? void 0 : userDetails.sub], getUnreadCount), unreadCount = _2.data, unreadCountRefetch = _2.refetch;
    var style = {
        accountDropDown: "absolute ".concat(showAccountDropDown ? "flex" : "hidden", "\n    top-6 bg-white ltr:right-6 rtl:left-6 bg-white flex flex-col mt-[0.65rem] rounded-xl text-sm shadow-md p-5 place-content-center"),
        creditsDropDown: "absolute ".concat(showCreditsDropDown ? "flex" : "hidden", "\n    top-[25px] bg-white flex flex-col mt-[1.1rem] px-3 box-border text-xs shadow-md ltr:left-0 rtl:right-1 place-content-center rounded-lg"),
    };
    var renderWalletPage = function () {
        navigate("/app/header/wallets");
        setShowCreditsDropDown(!showCreditsDropDown);
    };
    var CreditLimitPage = function () {
        navigate("/app/header/CreditLimitList");
        setShowCreditsDropDown(!showCreditsDropDown);
    };
    var renderTransferCreditsPage = function () {
        navigate("/app/header/transfercredits");
        setShowCreditsDropDown(!showCreditsDropDown);
    };
    var hexToRgb = function (hex) {
        hex = hex.replace(/^#/, "");
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return "".concat(r, ", ").concat(g, ", ").concat(b);
    };
    return (_jsxs("div", { className: "z-30 flex border-[1px] justify-between border-solid border-[#D2D2D2] flex-grow sticky bg-white top-0 w-full", style: { height: 85 }, children: [_jsxs("div", { className: "flex items-center w-[151px]", children: [_jsx("div", { className: "w-[30px] h-[85px] lg:hidden ltr:ml-4 rtl:mr-4", children: _jsx(MenuIcon, { onClick: toggleSidebar, height: 85, width: 35, className: "cursor-pointer" }) }), _jsx(Link, { to: "/app/home", className: "h-12 ", children: _jsx("img", { className: " h-full w-auto mx-8", src: theme.logos ? theme.logos : ComvivaLogo, alt: "Logo" }) })] }), theme.announcementFlag === "Enable" &&
                (theme.announcementText !== "" || !theme.announcementText) && (_jsx("div", { style: {
                    color: "black",
                    backgroundColor: "rgba(".concat(hexToRgb(theme.secondaryColor), ", 0.1)"),
                }, className: "p-2 flex h-8  md:ltr:ml-7 md:rtl:mr-7 items-center flex-row self-center place-items-center rounded gap-2", rel: "noreferrer", children: _jsxs("div", { className: "flex flex-row gap-2", children: [theme.announcementText, theme.display && theme.url && (_jsx("a", { href: theme.url, target: "_blank", className: "text-blue-400 ml-2", rel: "noopener noreferrer", children: theme.display })), _jsx("button", { type: "button", onClick: function () {
                                setTheme(__assign(__assign({}, theme), { announcementFlag: "Disable" }));
                            }, className: "font-bold text-sm", children: "x" })] }) })), _jsx("div", { className: "self-center", children: _jsxs("div", { className: "flex items-center justify-end", children: [loggedInRole === "EA" || loggedInRole === "EU" ? (_jsxs("div", { onClick: function () { return setShowCreditsDropDown(true); }, onMouseLeave: function () { return setShowCreditsDropDown(false); }, className: "relative py-1 rounded-[8px] bg-opacity-[0.06] flex items-center cursor-pointer", style: {
                                backgroundColor: "rgba(".concat(hexToRgb(theme.secondaryColor), ", 0.1)"),
                            }, children: [_jsx("div", { className: "ltr:ml-3 rtl:mr-3 py-3", children: _jsx(WalletIcon, { className: "w-6 h-5", style: { color: theme.secondaryColor } }) }), _jsxs("div", { onClick: function () { return setShowCreditsDropDown(!showCreditsDropDown); }, className: "flex flex-col ml-2 text mx-4 relative", children: [_jsx("h1", { style: { color: theme.secondaryColor, opacity: "75%" }, className: " text-xs ltr:pr-2 rtl:pl-2", children: t("_Balance") }), status === "success" && !isLoading && (_jsx("h1", { style: { color: theme.secondaryColor }, className: " font-bold opacity-100 text-sm", children: Number((_f = data === null || data === void 0 ? void 0 : data.data) === null || _f === void 0 ? void 0 : _f.balance).toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                            }) }))] }), _jsxs("div", { style: { width: 150 }, className: style.creditsDropDown, children: [enableWallet === "true" && (_jsx("h1", { className: "mx-3 mt-3 mb-2 cursor-pointer font-semibold", onClick: function () { return renderWalletPage(); }, children: t("_My_wallet") })), enableTransferCredits === "true" && (_jsx("h1", { className: "m-3 cursor-pointer font-semibold ".concat(!((_g = userScope === null || userScope === void 0 ? void 0 : userScope.wallets) === null || _g === void 0 ? void 0 : _g.transfer) &&
                                                "opacity-50 cursor-not-allowed"), onClick: function () {
                                                var _a, _b;
                                                if (smsDashboard === "true" &&
                                                    ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.billingType) === "postpaid") {
                                                    CreditLimitPage();
                                                }
                                                else if ((_b = userScope === null || userScope === void 0 ? void 0 : userScope.wallets) === null || _b === void 0 ? void 0 : _b.transfer) {
                                                    renderTransferCreditsPage();
                                                }
                                            }, children: t("_Transfer_credits") }))] })] })) : null, _jsxs("div", { className: "inline-flex relative h-[23px] w-[23px] sm:mx-[35px] mx-2 mt-1 ".concat(((_h = userScope === null || userScope === void 0 ? void 0 : userScope.alerts) === null || _h === void 0 ? void 0 : _h.read)
                                ? "cursor-pointer"
                                : "cursor-not-allowed opacity-50", " "), onClick: function () {
                                var _a;
                                if ((_a = userScope === null || userScope === void 0 ? void 0 : userScope.alerts) === null || _a === void 0 ? void 0 : _a.read) {
                                    setShowAlertDropDown(!showAlertDropDown);
                                }
                            }, onMouseLeave: function () {
                                var _a;
                                if ((_a = userScope === null || userScope === void 0 ? void 0 : userScope.alerts) === null || _a === void 0 ? void 0 : _a.read) {
                                    setShowAlertDropDown(false);
                                }
                            }, children: [!isNotificationLoading &&
                                    ((_j = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.data) === null || _j === void 0 ? void 0 : _j.count) > 0 &&
                                    ((_k = userScope === null || userScope === void 0 ? void 0 : userScope.alerts) === null || _k === void 0 ? void 0 : _k.read) && (_jsx("span", { style: { backgroundColor: theme.primaryColor }, className: "absolute -right-1 -top-2 leading-4 rounded-full  w-4 h-4 p-0 m-0 text-white font-mono text-xvs text-center ".concat(((_l = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.data) === null || _l === void 0 ? void 0 : _l.count) < 100 ? " text-xs " : "text-xvs "), children: ((_m = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.data) === null || _m === void 0 ? void 0 : _m.count) < 100
                                        ? (_o = unreadCount === null || unreadCount === void 0 ? void 0 : unreadCount.data) === null || _o === void 0 ? void 0 : _o.count
                                        : "99+" })), _jsx(NotificationIcon, {}), showAlertDropDown === true ? (_jsx(NotificationDropdown, { navigate: navigate, notificationRefetch: notificationRefetch, unreadRefetch: unreadCountRefetch, data: (_r = (_q = (_p = notificationsData === null || notificationsData === void 0 ? void 0 : notificationsData.pages[0]) === null || _p === void 0 ? void 0 : _p.response) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.data })) : null] }), _jsxs("div", { className: "flex cursor-pointer relative ", onMouseLeave: function () { return setAccountShowDropDown(false); }, onClick: function () { return setAccountShowDropDown(!showAccountDropDown); }, children: [_jsx("img", { src: (userData === null || userData === void 0 ? void 0 : userData.profileImg) || userIcon, className: "rounded-full h-9  w-10 object-cover ltr:mr-[8px] rtl:ml-[8px]", alt: "user-icon" }), _jsxs("h1", { className: "font-nunito my-auto ltr:mr-[31px] rtl:ml-[31px] hidden sm:inline-block font-bold", onMouseEnter: function () {
                                        setOpenInfoModal(true);
                                    }, onMouseLeave: function () {
                                        setOpenInfoModal(false);
                                    }, children: [((_s = userData.name) === null || _s === void 0 ? void 0 : _s.length) > 12
                                            ? userData.name.substring(0, 12) + "..."
                                            : userData.name, ((_t = userData.name) === null || _t === void 0 ? void 0 : _t.length) > 12 && openInfoModal && (_jsx(InfoModal, { closeInfoModal: function () {
                                                setOpenInfoModal(false);
                                            }, children: _jsx("span", { className: "text-xs", children: userData.name }) }))] }), _jsxs("div", { style: { width: 200 }, className: style.accountDropDown, children: [_jsx("h1", { className: "m-3 cursor-pointer font-semibold text-sm", onClick: function () {
                                                navigate("/app/header/change-profile");
                                            }, children: t("_My_profile") }), loggedInRole !== "PA" && (_jsx("h1", { className: "m-3 cursor-pointer font-semibold text-sm", onClick: function () {
                                                navigate("/app/header/billing");
                                            }, children: t("_Billing") })), loggedInRole == "EA" || loggedInRole == "EU" ? null : (_jsx("h1", { className: "m-3 cursor-pointer font-semibold text-sm", onClick: function () {
                                                navigate("/app/header/settings/".concat(groupID));
                                            }, children: t("_Settings") })), _jsx("h1", { className: "m-3 cursor-pointer font-semibold text-sm", onClick: function () {
                                                logout();
                                            }, children: t("_Log_out") })] })] })] }) })] }));
};
var InfoModal = function (props) {
    var styles = {
        modalstyle: "bg-[#3C3C3C] absolute flex z-10 justify-center shadow-[0_3px_8px_rgba(0,0,0,0.24)] rounded-md right-5 top-9 px-2 pb-1 rounded-xl w-max text-white flex",
    };
    return (_jsx("div", { className: styles.modalstyle, style: {
            boxShadow: "0px 3px 6px #0000001A",
        }, children: props.children }));
};
export default Header;
