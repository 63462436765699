import { lazy } from "react";
import loadRemoteComponent from "../utils/loadRemoteComponent";
// path for rule criteria MFE
var RuleCriteriaForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./RuleCriteriaForm");
});
var DeviceType = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceType");
});
var RuleCriteria = lazy(function () { return import("../pages/RuleCriteria"); });
// path for device type MFE
var NewDeviceType = lazy(function () { return import("../pages/NewDeviceType"); });
// const CreateDeviceTypeForm = lazy(() =>
//   import("adrinWebApp/CreateDeviceTypeForm")
// );
var CreateDeviceTypeForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./CreateDeviceTypeForm");
});
// const DeviceTypeEditForm = lazy(() => import("adrinWebApp/DeviceTypeEditForm"));
var DeviceTypeEditForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceTypeEditForm");
});
var BulkUploadHistoryDetails = lazy(function () {
    return import("../pages/BulkUploadHistoryDetails");
});
//#region Paths for Adrin Web App
// path for policy list MFE
var PolicyListNew = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./PolicyListNew");
});
var PolicyListForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./PolicyListForm");
});
//policy rules
var PolicyRulesList = lazy(function () { return import("../pages/PolicyRulesNew"); });
var PolicyRulesForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./PolicyRuleForm");
});
//Device list
var DeviceListNew = lazy(function () { return import("../pages/DeviceListNew"); });
var DeviceListForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceListForm");
});
var DeviceListFormNew = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceListForm");
});
var DeviceListViewHistory = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceListViewHistory");
});
// path for rule engine MFE
var RulesEngine = lazy(function () { return import("../pages/RulesEngine"); });
var CloneRuleEngine = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./CloneRuleEngine");
});
var DeviceTypeForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceTypeForm");
});
var CreateEnterpriseApp = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./CreateEnterpriseApp");
});
//Consents
var ConsentList = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./DeviceConsentsList");
});
var ConsentEditForm = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./ConsentEditForm");
});
var CreateRuleEngineTailwind = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./CreateRuleEngineTailwind");
});
var EditRuleEngineTailwind = lazy(function () {
    return loadRemoteComponent("adrinWebApp", "./EditRuleEngineTailwind");
});
//#endregion
//Enterprise Application
var EnterpriseAppList = lazy(function () {
    return import("../pages/EnterpriseApplicationList");
});
//Subcriptions
var SubscriptionsList = lazy(function () { return import("../pages/AllSubscriptionsList"); });
var DeviceListViewConsentHistory = lazy(function () {
    return import("../pages/ConsentBulkUploadHistory");
});
//Event Records
var EventRecordsList = lazy(function () { return import("../pages/EventRecordsList"); });
// use lazy for better code splitting, a.k.a. load faster
var Logout = lazy(function () { return import("../pages/Logout"); });
var ipwhitelistinguser = lazy(function () { return import("../pages/ipwhitelistinguser.js"); });
var IPWhitelisting = lazy(function () { return import("../pages/IPWhitelisting.js"); });
var AddIPAddresses = lazy(function () { return import("../pages/AddIPAddresses.js"); });
var smsTemplateForm = lazy(function () { return import("../pages/smsTemplateForm"); });
var SMSTemplateRouter = lazy(function () { return import("../pages/SMSTemplateRouter"); });
var emailTemplatesList = lazy(function () { return import("../pages/emailTemplatesList"); });
var emailTemplateForm = lazy(function () { return import("../pages/emailTemplateForm"); });
var ListEMAILCampaigns = lazy(function () { return import("../pages/ListEMAILCampaigns"); });
var EmailAddCampaigns = lazy(function () { return import("../pages/EmailAddCampaign"); });
var ContentBlock = lazy(function () { return import("../pages/ContentFilterBlocked.js"); });
var ContentFilter = lazy(function () {
    return import("../admin/pages/Content-Filter-Outline.js");
});
var QuietTime = lazy(function () { return import("../pages/quietTime"); });
var CreateQuiteTime = lazy(function () {
    return import("../components/Forms/createQuietTime");
});
var ContactPage = lazy(function () { return import("../pages/ContactListPage"); });
var ContactGroupPage = lazy(function () { return import("../pages/ContactGroupPage"); });
var Forms = lazy(function () { return import("../pages/Forms"); });
var Charts = lazy(function () { return import("../pages/Charts"); });
var smsDashboard = lazy(function () { return import("../pages/SMSDashboard"); });
var Reports = lazy(function () { return import("../pages/Reports"); });
var Modals = lazy(function () { return import("../pages/Modals"); });
var Page404 = lazy(function () { return import("../pages/404"); });
var Blank = lazy(function () { return import("../pages/Blank"); });
var RolesList = lazy(function () { return import("../pages/RolesList"); });
var AddCampaign = lazy(function () { return import("../pages/AddCampaign"); });
var RoleCreationForm = lazy(function () { return import("../pages/roleCreationForm"); });
var listsmsCamp = lazy(function () { return import("../pages/ListSMSCampaign"); });
var ViewSMSCampaign = lazy(function () { return import("../pages/ViewSMSCampaign"); });
var ViewPackageDetails = lazy(function () { return import("../pages/ViewPackageDetails"); });
var ViewEMAILCampaign = lazy(function () { return import("../pages/ViewEMAILCampaign"); });
var ViewWACampaign = lazy(function () { return import("../pages/ViewWACampaign.js"); });
var Reschedule = lazy(function () { return import("../pages/reSchedulePage"); });
var AddAccounts = lazy(function () { return import("../pages/EnterpriseCreation"); });
var SenderAddresses = lazy(function () { return import("../pages/SenderAddresses"); });
var EmailAddresses = lazy(function () { return import("../pages/EmailAddresses"); });
var AddSenderIdForm = lazy(function () { return import("../pages/AddSenderIdForm"); });
var AddEmailIdForm = lazy(function () { return import("../pages/AddEmailIdForm"); });
var SMPPSession = lazy(function () { return import("../pages/smppSession"); });
var Wallets = lazy(function () { return import("../pages/Wallets"); });
var CreditLimitUsersPA = lazy(function () {
    return import("../admin/components/CreditLimitListUsersPA");
});
var CreditLimitPA = lazy(function () {
    return import("../admin/components/CreditLimitListPA");
});
var TransferCredits = lazy(function () { return import("../pages/TransferCredits"); });
var UsersList = lazy(function () { return import("../pages/UsersList"); });
var UserCreationForm = lazy(function () { return import("../pages/UserCreationForm"); });
var HomeDashboard = lazy(function () { return import("../pages/HomeDashboard"); });
var Insights = lazy(function () { return import("../pages/Insights"); });
var DashboardInsights = lazy(function () { return import("../pages/DashboardInsights"); });
var StudioAI = lazy(function () { return import("../studio/modules/studio-ai"); });
var PackagesBillingAndPricing = lazy(function () {
    return import("../pages/PackagesBillingAndPricing");
});
var RateSheetsBillingAndPricing = lazy(function () {
    return import("../pages/RateSheetsBillingAndPricing");
});
var RateSheetsBillingAndPricingForm = lazy(function () {
    return import("../pages/RateSheetsBillingAndPricingForm");
});
var WhatsappCampaign = lazy(function () { return import("../pages/WhatsappMyCampaigns"); });
var WhatsappCampaignForm = lazy(function () {
    return import("../pages/WhatsappMyCampaignsForm");
});
var DistributionList = lazy(function () { return import("../pages/DistributionList"); });
var SenderAddress = lazy(function () {
    return import("../admin/pages/SenderAddress_PlatformAdmin");
});
var EmailAddress = lazy(function () {
    return import("../admin/pages/EmailAddress_PlatformAdmin");
});
var RegisterSenderAddressesList = lazy(function () {
    return import("../pages/RegisterSenderAddress");
});
var RegisterEmailAddressesList = lazy(function () {
    return import("../pages/RegisterEmailAddress");
});
var unsubscribedlists = lazy(function () { return import("../pages/unsubscribedlist"); });
var SenderAddrsInroduction = lazy(function () {
    return import("../pages/SenderAddressIntroduction");
});
var EmailAddrsInroduction = lazy(function () {
    return import("../pages/EmailAddressIntroduction");
});
var DistributionListForm = lazy(function () {
    return import("../pages/DistributionListForm");
});
var PackageCreationForm = lazy(function () {
    return import("../pages/PackageCreationFormNew");
});
var ContactForm = lazy(function () { return import("../pages/ContactForm"); });
// const ContactNewForm = lazy(() => import("../pages/ContactNewForm.js"));
var ContactGroupForm = lazy(function () { return import("../pages/ContactGroupForm"); });
var EditSenderAddressSpecifications = lazy(function () {
    return import("../pages/EditSenderAddressSpecifications");
});
var EditEmailAddressSpecification = lazy(function () {
    return import("../pages/EditEmailAddressSpecification");
});
var Accounts = lazy(function () { return import("../pages/Accounts"); });
var Packages = lazy(function () { return import("../pages/PackagesNew"); });
var GettingStarted = lazy(function () { return import("../pages/WhatsappLanding"); });
var ViewRateSheet = lazy(function () { return import("../pages/ViewRatesheetNew"); });
var WhatsappTemplateList = lazy(function () {
    return import("../pages/WhatsappTemplateList");
});
var WhatsappTemplateCreationForm = lazy(function () {
    return import("../pages/WhatsappTemplateCreationForm");
});
var WhatsappSandboxPage = lazy(function () { return import("../pages/WhatsappSandboxPage"); });
var EditWhatsappProfiles = lazy(function () { return import("../pages/EditProfile.js"); });
var WhatsappProfileList = lazy(function () {
    return import("../pages/WhatsappProfileList.js");
});
var Invoicing = lazy(function () { return import("../pages/Invoicing"); });
var InvoicingDetails = lazy(function () { return import("../pages/InvoicingDetails"); });
var NumbersListViewEA = lazy(function () { return import("../pages/NumberListViewEA"); });
var NumbersListViewPA = lazy(function () { return import("../admin/pages/Numbers"); });
var BuyNumber = lazy(function () { return import("../pages/BuyNumber"); });
var EditNumber = lazy(function () { return import("../pages/EditNumber"); });
var EditNumberPA = lazy(function () { return import("../admin/components/EditNumberPA"); });
var NumberSettings = lazy(function () { return import("../admin/components/NumberSettings"); });
var UploadNumberPA = lazy(function () { return import("../admin/components/UploadNumberPA"); });
var TransferPackage = lazy(function () { return import("../pages/TransferPackage"); });
var ProviderReports = lazy(function () { return import("../pages/ProviderReports"); });
var LogActivity = lazy(function () { return import("../admin/pages/LogActivity"); });
var LogEnterprise = lazy(function () { return import("../admin/pages/LogEnterprise"); });
var LogDebug = lazy(function () { return import("../admin/pages/LogDebug"); });
var ChatbotAPI = lazy(function () { return import("../pages/ChatbotAPI"); });
var Notification = lazy(function () { return import("../pages/Notification"); });
var NotificationPA = lazy(function () { return import("../pages/NotificationPA"); });
var ProviderPage = lazy(function () { return import("../admin/pages/Provider/Provider"); });
var ProviderForm = lazy(function () { return import("../admin/pages/Provider/ProviderForm"); });
var Routing = lazy(function () { return import("../admin/pages/Routing/Routing"); });
var StudioDashboard = lazy(function () { return import("../studio"); });
var StudioEditor = lazy(function () { return import("../studio/modules/studio-editor"); });
var StudioLogs = lazy(function () { return import("../studio/modules/studio-logs"); });
var RuleCreationForm = lazy(function () {
    return import("../admin/pages/Routing/RuleCreationPage");
});
var ConfigureSmpp = lazy(function () {
    return import("../admin/pages/Provider/ConfigureSmpp");
});
var ConfigureSmppForm = lazy(function () {
    return import("../admin/pages/Provider/ConfigureSmppForm");
});
var ConfigureSip = lazy(function () { return import("../admin/pages/Provider/ConfigureSip"); });
var ConfigureSipForm = lazy(function () {
    return import("../admin/pages/Provider/ConfigureSipForm");
});
var RatePlan = lazy(function () { return import("../admin/pages/Provider/RatePlan"); });
var GroupList = lazy(function () { return import("../pages/GroupList"); });
var AddGroupForm = lazy(function () { return import("../pages/AddGroupForm"); });
var IVRList = lazy(function () { return import("../pages/IVRList"); });
var AddVirtualReceptionistForm = lazy(function () {
    return import("../pages/AddVirtualReceptionist");
});
var VoiceNumberList = lazy(function () { return import("../pages/VoiceNumberList"); });
var NumberConfigurationForm = lazy(function () {
    return import("../pages/NumberConfigurationPage");
});
var NumberView = lazy(function () { return import("../admin/pages/NumberView"); });
var IVRView = lazy(function () { return import("../admin/pages/IVRView"); });
var VirtualReceptionistActivityLogs = lazy(function () {
    return import("../admin/pages/VirtualReceptionistActivityLogs");
});
var VoiceCampaign = lazy(function () { return import("../pages/VoiceCampaign"); });
var VoiceCampaignForm = lazy(function () { return import("../pages/VoiceCampaignForms"); });
var FileManagerList = lazy(function () { return import("../pages/FileManagerList"); });
var UploadAnnouncement = lazy(function () { return import("../pages/UploadAnnouncement"); });
var VirtualReceptionistActivityLogsEA = lazy(function () {
    return import("../pages/VirtualReceptionistActivityLogs");
});
var Dialler = lazy(function () { return import("../pages/Dialler"); });
var RCSCampaign = lazy(function () { return import("../pages/RCSCampaign"); });
var RCSCampaignForm = lazy(function () { return import("../pages/RCSCampaignForm"); });
var RCSTemplateRouter = lazy(function () { return import("../pages/RCSTemplateRouter"); });
var RCSTemplateForm = lazy(function () { return import("../pages/RCSTemplateForm"); });
// const ServiceKey = lazy(() => import("../pages/ServiceKey"));
var RCSAgentProfilesEA = lazy(function () { return import("../pages/RCSAgentProfilesEA"); });
var RCSAgentProfilesPA = lazy(function () { return import("../pages/RCSAgentProfilesPA"); });
var RCSAgentProfilesForm = lazy(function () {
    return import("../pages/RCSAgentProfilesForm");
});
var RCSCustomerProfiles = lazy(function () { return import("../pages/CustomerProfiles"); });
var RCSConfigureCustomerProfiles = lazy(function () {
    return import("../pages/ConfigureCustomerProfile");
});
var APIDocumentationEA = lazy(function () { return import("../pages/APIDocumentationEA"); });
var APIDocumentationPA = lazy(function () { return import("../pages/APIDocumentationPA"); });
var SegmentationList = lazy(function () {
    return import("../components/Segmentation/SegmentationList");
});
var SegmentationForm = lazy(function () {
    return import("../components/Segmentation/SegmentationForm");
});
var WhatsAppProfileInfo = lazy(function () { return import("../pages/WhatsAppProfileInfo"); });
var WhatsAppAccountDetails = lazy(function () {
    return import("../pages/WhatsAppAccountDetails");
});
var ViewSenderAddressDetails = lazy(function () {
    return import("../pages/ViewSenderAddressDetails");
});
var BlacklistManagementListing = lazy(function () {
    return import("../pages/BlacklistManagementListing");
});
var DNDListing = lazy(function () { return import("../pages/DNDListing"); });
var UploadDNDNumbers = lazy(function () { return import("../pages/UploadDNDNumbers"); });
var ViewRCSCampaign = lazy(function () { return import("../pages/ViewRCSCampaign.js"); });
// const DeviceTypeEditForm = lazy(() =>
//   import("../pages/DeviceTypeEditForm")
// );
// const RulesEngine = lazy(() =>
//   import("../pages/RulesEngine")
// );
// const PolicyListForm = lazy(() => import("../pages/PolicyListForm"))
var DashboardPage = lazy(function () { return import("../pages/Dashboard"); });
// const RulesCriteriaForm = lazy(() => import("adrinWebApp/RuleCriteriaForm"))
/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
var routes = [
    {
        path: "/studio/dashboard/:type/:templateId?",
        component: StudioDashboard,
    },
    {
        path: "/studio/editor/:id",
        component: StudioEditor,
    },
    {
        path: "/studio/editor/ai/:id",
        component: StudioAI,
    },
    {
        path: "/studio/editor/logs/:id",
        component: StudioLogs,
    },
    {
        path: "/studio/editor/ai/:id",
        component: StudioAI,
    },
    {
        path: "/logout",
        component: Logout,
    },
    {
        path: "/policy/QuietTime",
        component: QuietTime,
    },
    {
        path: "/policy/QuietTime/:method",
        component: CreateQuiteTime,
    },
    {
        path: "/policy/QuietTime/:method/:id",
        component: CreateQuiteTime,
    },
    {
        path: "home",
        component: HomeDashboard,
    },
    {
        path: "/sms/my-campaigns",
        component: listsmsCamp,
    },
    {
        path: "/email/my-campaigns",
        component: ListEMAILCampaigns,
    },
    {
        path: "/email/my-campaigns/add-campaign/:method",
        component: EmailAddCampaigns,
    },
    {
        path: "/email/my-campaigns/add-campaign/:method/:id",
        component: EmailAddCampaigns,
    },
    {
        path: "/email/my-campaigns/reschedule/:id",
        component: Reschedule,
    },
    {
        path: "/sms/my-campaigns/view-campaign/:id/:recurrenceId",
        component: ViewSMSCampaign,
    },
    {
        path: "/email/my-campaigns/view-campaign/:id/:recurrenceId",
        component: ViewEMAILCampaign,
    },
    {
        path: "/rcs/campaign/view-campaign/:id/:recurrenceId",
        component: ViewRCSCampaign,
    },
    //policy routes
    {
        path: "/policy/contentfilter",
        component: ContentFilter,
    },
    {
        path: "/whatsapp/my-campaigns/view-campaign/:id/:recurrenceId",
        component: ViewWACampaign,
    },
    {
        path: "/sms/my-campaigns/reschedule/:id/:recurrenceId",
        component: Reschedule,
    },
    {
        path: "/sms/my-campaigns/add-campaign/:method",
        component: AddCampaign,
    },
    {
        path: "/sms/my-campaigns/add-campaign/:method/:id/:recurrenceId",
        component: AddCampaign,
    },
    {
        path: "/sms/sender-addresses",
        component: SenderAddresses,
    },
    {
        path: "/email/email-addresses",
        component: EmailAddresses,
    },
    {
        path: "/sms/Register/sender-addresses",
        component: RegisterSenderAddressesList,
    },
    {
        path: "/sms/Register/sender-addresses/view/:id",
        component: ViewSenderAddressDetails,
    },
    {
        path: "/email/Register/email-addresses",
        component: RegisterEmailAddressesList,
    },
    {
        path: "/email/unsubscribe",
        component: unsubscribedlists,
    },
    {
        path: "/email/Register/email-addresses/:action",
        component: AddEmailIdForm,
    },
    {
        path: "/email/Register/email-addresses/:action/:emailId",
        component: AddEmailIdForm,
    },
    {
        path: "/sms/introduction/sender-addresses",
        component: SenderAddrsInroduction,
    },
    {
        path: "/email/introduction/email-addresses",
        component: EmailAddrsInroduction,
    },
    {
        path: "/sms/Register/sender-addresses/:action",
        component: AddSenderIdForm,
    },
    {
        path: "/sms/Register/sender-addresses/:action/:senderId",
        component: AddSenderIdForm,
    },
    {
        path: "/sms/manage-templates",
        component: SMSTemplateRouter,
    },
    {
        path: "/sms/manage-templates/:id",
        component: smsTemplateForm,
    },
    {
        path: "/sms/manage-templates/:id/:templateId",
        component: smsTemplateForm,
    },
    {
        path: "/email/email-templates",
        component: emailTemplatesList,
    },
    {
        path: "/email/email-templates/:id",
        component: emailTemplateForm,
    },
    {
        path: "/email/email-templates/:id/:templateId",
        component: emailTemplateForm,
    },
    {
        path: "/whatsapp/my-campaigns/",
        component: WhatsappCampaign,
    },
    {
        path: "/whatsapp/my-campaigns/add-campaign/:method",
        component: WhatsappCampaignForm,
    },
    {
        path: "/whatsapp/my-campaigns/add-campaign/:method/:id/:recurrenceId",
        component: WhatsappCampaignForm,
    },
    {
        path: "/whatsapp/sandbox",
        component: WhatsappSandboxPage,
    },
    {
        path: "/forms",
        component: Forms,
    },
    {
        path: "/charts",
        component: Charts,
    },
    {
        path: "/sms/dashboard",
        component: smsDashboard,
    },
    {
        path: "/modals",
        component: Modals,
    },
    {
        path: "/404",
        component: Page404,
    },
    {
        path: "/blank",
        component: Blank,
    },
    {
        path: "/user-management/roles",
        component: RolesList,
    },
    {
        path: "/user-management/roles/:action",
        component: RoleCreationForm,
    },
    {
        path: "/user-management/roles/:action/:id",
        component: RoleCreationForm,
    },
    {
        path: "/accounts",
        component: Accounts,
    },
    {
        path: "/accounts/ipwhitelisting/:id",
        component: IPWhitelisting,
    },
    {
        path: "/accounts/ipwhitelistinguser/:id",
        component: ipwhitelistinguser,
    },
    {
        path: "/accounts/addipaddresses/:id",
        component: AddIPAddresses,
    },
    {
        path: "/accounts/:action",
        component: AddAccounts,
    },
    {
        path: "/accounts/:action/:accountId",
        component: AddAccounts,
    },
    {
        path: "/billing-pricing/wallets",
        component: Wallets,
    },
    {
        path: "/wallet/CreditLimitPA",
        component: CreditLimitPA,
    },
    {
        path: "/wallet/CreditLimitUsers/:id",
        component: CreditLimitUsersPA,
    },
    {
        path: "/billing-pricing/packages",
        component: PackagesBillingAndPricing,
    },
    {
        path: "/billing-pricing/packages/view/:id",
        component: ViewPackageDetails,
    },
    {
        path: "/billing-pricing/rate-sheets",
        component: RateSheetsBillingAndPricing,
    },
    {
        path: "/billing-pricing/rate-sheets/:action/",
        component: RateSheetsBillingAndPricingForm,
    },
    {
        path: "/billing-pricing/rate-sheets/:action/:id",
        component: RateSheetsBillingAndPricingForm,
    },
    {
        path: "/billing-pricing/rate-sheets/add-rate-sheet/:action/:id",
        component: RateSheetsBillingAndPricingForm,
    },
    {
        path: "/logs/activity",
        component: LogActivity,
    },
    {
        path: "/logs/enterprise",
        component: LogEnterprise,
    },
    {
        path: "/logs/debug",
        component: LogDebug,
    },
    // {
    //   path: "/home/wallets",
    //   component: Wallets,
    // },
    {
        path: "/billing-pricing/invoicing",
        component: Invoicing,
    },
    {
        path: "/billing-pricing/invoicing/invoice-history/:id",
        component: InvoicingDetails,
    },
    {
        path: "/NumbersEA",
        component: NumbersListViewEA,
    },
    {
        path: "/NumbersPA",
        component: NumbersListViewPA,
    },
    {
        path: "/NumbersEA/buy",
        component: BuyNumber,
    },
    {
        path: "/NumbersEA/edit/:id",
        component: EditNumber,
    },
    {
        path: "/NumbersPA/edit/:id",
        component: EditNumberPA,
    },
    {
        path: "/NumbersPA/view/:id",
        component: EditNumber,
    },
    {
        path: "/NumbersPA/settings",
        component: NumberSettings,
    },
    {
        path: "/numbersPA/upload",
        component: UploadNumberPA,
    },
    {
        path: "/transfercredits",
        component: TransferCredits,
    },
    {
        path: "/user-management/users",
        component: UsersList,
    },
    {
        path: "/user-management/users/_create",
        component: UserCreationForm,
    },
    {
        path: "/user-management/users/:id",
        component: UserCreationForm,
    },
    {
        path: "/contacts/distribution-list",
        component: DistributionList,
    },
    { path: "/insights/reports", component: Reports },
    // { path: "/insights/usage-reports", component: UsageReports },
    { path: "/insights/usage-reports", component: Reports },
    { path: "/insights/provider-reports", component: ProviderReports },
    { path: "/device/list", component: DeviceListNew },
    { path: "/device/list/_history", component: DeviceListViewHistory },
    { path: "/device/list/:action", component: DeviceListForm },
    { path: "/device/list/:action/:id", component: DeviceListForm },
    // { path: "/public/demo", component: PublicDemo },
    { path: "/device/type", component: DeviceType },
    { path: "/device/type/:action", component: DeviceTypeForm },
    { path: "/device/type/:action/:id", component: DeviceTypeForm },
    { path: "/policy/list/:action", component: PolicyListForm },
    { path: "/policy/list/:action/:id", component: PolicyListForm },
    // { path: "/rule/engine", component: RulesEngine },
    // { path: "/rule/criteria/:action", component: RulesCriteriaForm },
    // { path: "/rule/criteria/:action/:id", component: RulesCriteriaForm },
    // { path: "/operations-management", component: OperationsManagement },
    // {
    //   path: "/Sms/reports",
    //   component: Reports,
    // },
    {
        path: "/contacts/distribution-list/_create",
        component: DistributionListForm,
    },
    {
        path: "/contacts/distribution-list/:id",
        component: DistributionListForm,
    },
    {
        path: "/contacts/contact-list",
        component: ContactPage,
    },
    {
        path: "/contacts/contact-list/_create",
        component: ContactForm,
    },
    {
        path: "/contacts/contact-list/:id",
        component: ContactForm,
    },
    {
        path: "/contacts/contact-group",
        component: ContactGroupPage,
    },
    {
        path: "/contacts/contact-group/_create",
        component: ContactGroupForm,
    },
    {
        path: "/contacts/contact-group/:id",
        component: ContactGroupForm,
    },
    {
        path: "/sms/sender-address-specifications",
        component: SenderAddress,
    },
    {
        path: "/email/email-address-specifications",
        component: EmailAddress,
    },
    {
        path: "/sms/sender-address-specifications/:action",
        component: EditSenderAddressSpecifications,
    },
    {
        path: "/sms/sender-address-specifications/:action/:countryCode",
        component: EditSenderAddressSpecifications,
    },
    {
        path: "/email/email-address-specifications/:action",
        component: EditEmailAddressSpecification,
    },
    {
        path: "/email/email-address-specifications/:action/:countryCode",
        component: EditEmailAddressSpecification,
    },
    {
        path: "/sms/smpp-session",
        component: SMPPSession,
    },
    {
        path: "/insights/dashboard",
        component: DashboardPage,
    },
    {
        path: "/myplans/packages",
        component: Packages,
    },
    //Whatsapp routes
    // {
    //   path: "/whatsapp/getting-started",
    //   component: GettingStarted,
    // },
    //TODO - remove this after demo and uncomment the above path
    {
        path: "/whatsapp/getting-started",
        component: WhatsappProfileList,
    },
    {
        path: "/billing-pricing/packages/:action",
        component: PackageCreationForm,
    },
    {
        path: "/billing-pricing/packages/:action/:id",
        component: PackageCreationForm,
    },
    {
        path: "/billing-pricing/packages/add-package/:action/:id",
        component: PackageCreationForm,
    },
    {
        path: "/billing-pricing/packages/transfer-package",
        component: TransferPackage,
    },
    {
        path: "/myplans/ratesheets",
        component: ViewRateSheet,
    },
    {
        path: "/whatsapp/templates",
        component: WhatsappTemplateList,
    },
    {
        path: "/whatsapp/templates/:action/:id",
        component: WhatsappTemplateCreationForm,
    },
    {
        path: "/whatsapp/templates/:action",
        component: WhatsappTemplateCreationForm,
    },
    {
        path: "/whatsapp/getting-started",
        component: GettingStarted,
    },
    {
        path: "/whatsapp/profiles",
        component: WhatsappProfileList,
    },
    {
        path: "/whatsapp/profiles/:id",
        component: EditWhatsappProfiles,
    },
    {
        path: "/whatsapp/my-campaigns/reschedule/:id/:recurrenceId",
        component: Reschedule,
    },
    {
        path: "/voice/my-campaigns/reschedule/:id/:recurrenceId",
        component: Reschedule,
    },
    {
        path: "/email/my-campaigns/reschedule/:id/:recurrenceId",
        component: Reschedule,
    },
    {
        path: "/chat",
        component: ChatbotAPI,
    },
    {
        path: "/notification",
        component: Notification,
    },
    {
        path: "/Routing/:channel/:id/:key",
        component: RuleCreationForm,
    },
    {
        path: "/provider/:channel/configure/rateplan/:name/:key",
        component: RatePlan,
    },
    {
        path: "/Providers",
        component: ProviderPage,
    },
    {
        path: "/provider-form/:channel/:id",
        component: ProviderForm,
    },
    {
        path: "/provider-form/:channel/:id/:key",
        component: ProviderForm,
    },
    {
        path: "/provider/sms/configure",
        component: ConfigureSmpp,
    },
    {
        path: "/provider/sms/configure/:id",
        component: ConfigureSmpp,
    },
    {
        path: "/provider/voice/configure/:id",
        component: ConfigureSip,
    },
    {
        path: "/provider/voice/configure/:providerId/:bindId/:method",
        component: ConfigureSipForm,
    },
    {
        path: "/provider/voice/configure/:providerId/:method",
        component: ConfigureSipForm,
    },
    {
        path: "/Routing",
        component: Routing,
    },
    {
        path: "/provider/sms/configure/:providerId/:bindId/:method",
        component: ConfigureSmppForm,
    },
    {
        path: "/provider/sms/configure/:providerId/:method",
        component: ConfigureSmppForm,
    },
    {
        path: "/provider/configure/rateplan/:id/:key",
        component: RatePlan,
    },
    {
        path: "/Routing/:channel/:id",
        component: RuleCreationForm,
    },
    {
        path: "/notification_PA",
        component: NotificationPA,
    },
    {
        path: "/voice/groups",
        component: GroupList,
    },
    {
        path: "/voice/groups/:id",
        component: AddGroupForm,
    },
    {
        path: "/voice/groups/:id/:key",
        component: AddGroupForm,
    },
    {
        path: "/voice/voicerecepientlist",
        component: IVRList,
    },
    {
        path: "/voice/voicerecepientlist/:id",
        component: AddVirtualReceptionistForm,
    },
    {
        path: "/voice/voicerecepientlist/:id/:key",
        component: AddVirtualReceptionistForm,
    },
    {
        path: "/voice/numbers",
        component: VoiceNumberList,
    },
    {
        path: "/voice/numbers/:cc/:number/:id",
        component: NumberConfigurationForm,
    },
    {
        path: "/voice/numbers/:cc/:number/:id/:key",
        component: NumberConfigurationForm,
    },
    {
        path: "/Routing/:id/:value",
        component: RuleCreationForm,
    },
    { path: "/notification_PA", component: NotificationPA },
    { path: "/voice/number-view", component: NumberView },
    { path: "/voice/ivr-view", component: IVRView },
    { path: "/voice/admin/activity", component: VirtualReceptionistActivityLogs },
    {
        path: "/voice/campaign",
        component: VoiceCampaign,
    },
    { path: "/voice/campaign/:method", component: VoiceCampaignForm },
    { path: "/voice/campaign/:method/:id", component: VoiceCampaignForm },
    { path: "/voice/file-manager", component: FileManagerList },
    { path: "/voice/file-manager/upload", component: UploadAnnouncement },
    { path: "/voice/activity", component: VirtualReceptionistActivityLogsEA },
    { path: "/voice/dialler", component: Dialler },
    { path: "/rcs/campaign", component: RCSCampaign },
    { path: "/rcs/campaign/:id", component: RCSCampaignForm },
    { path: "/rcs/manage-templates", component: RCSTemplateRouter },
    { path: "/rcs/template-form/:id", component: RCSTemplateForm },
    // { path: "/rcs/service-key", component: ServiceKey },
    { path: "/rcs/customer-profiles", component: RCSCustomerProfiles },
    {
        path: "/rcs/customer-profiles/:action",
        component: RCSConfigureCustomerProfiles,
    },
    {
        path: "/rcs/customer-profiles/:action/:id",
        component: RCSConfigureCustomerProfiles,
    },
    { path: "/rcs/agent-profiles", component: RCSAgentProfilesEA },
    { path: "/rcs/agent-profiles/:action/", component: RCSAgentProfilesForm },
    { path: "/rcs/agent-profiles/:action/:id", component: RCSAgentProfilesForm },
    { path: "/rcs/profiles", component: RCSAgentProfilesPA },
    { path: "/api-documentation", component: APIDocumentationEA },
    { path: "/admin/api-documentation", component: APIDocumentationPA },
    {
        path: "/email/my-campaigns/add-campaign/:method",
        component: EmailAddCampaigns,
    },
    {
        path: "/email/my-campaigns/add-campaign/:method/:id/:recurrenceId",
        component: EmailAddCampaigns,
    },
    {
        path: "/email/my-campaigns/reschedule/:id",
        component: Reschedule,
    },
    {
        path: "/email/email-addresses",
        component: EmailAddresses,
    },
    {
        path: "/email/Register/email-addresses",
        component: RegisterEmailAddressesList,
    },
    {
        path: "/email/unsubscribe",
        component: unsubscribedlists,
    },
    {
        path: "/email/Register/email-addresses/:action",
        component: AddEmailIdForm,
    },
    {
        path: "/email/Register/email-addresses/:action/:emailId",
        component: AddEmailIdForm,
    },
    {
        path: "/email/introduction/email-addresses",
        component: EmailAddrsInroduction,
    },
    {
        path: "/email/email-templates",
        component: emailTemplatesList,
    },
    {
        path: "/email/email-templates/:id",
        component: emailTemplateForm,
    },
    {
        path: "/email/email-templates/:id/:templateId",
        component: emailTemplateForm,
    },
    {
        path: "/email/email-address-specifications",
        component: EmailAddress,
    },
    {
        path: "/email/email-address-specifications/:action",
        component: EditEmailAddressSpecification,
    },
    {
        path: "/email/email-address-specifications/:action/:countryCode",
        component: EditEmailAddressSpecification,
    },
    { path: "/whatsapp/profiles-info", component: WhatsAppProfileInfo },
    {
        path: "/whatsapp/profiles-info/account-details/:id",
        component: WhatsAppAccountDetails,
    },
    {
        path: "/segmentation",
        component: SegmentationList,
    },
    {
        path: "/segmentation/:method",
        component: SegmentationForm,
    },
    {
        path: "/segmentation/:method/:id",
        component: SegmentationForm,
    },
    {
        path: "/policy/blacklist/:channel",
        component: BlacklistManagementListing,
    },
    {
        path: "/policy/dnd/:channel",
        component: DNDListing,
    },
    {
        path: "/policy/dnd/:channel/upload",
        component: UploadDNDNumbers,
    },
    // paths for rule criteria MFE
    { path: "/ruleCriteria", component: RuleCriteria },
    { path: "/ruleCriteriaForm/:action", component: RuleCriteriaForm },
    {
        path: "/ruleCriteriaForm/:action/:criteriaId",
        component: RuleCriteriaForm,
    },
    // paths for device type MFE
    { path: "/deviceType", component: NewDeviceType },
    { path: "/createDeviceTypeForm/:action", component: CreateDeviceTypeForm },
    {
        path: "/deviceTypeEditForm/:action/:deviceTypeId",
        component: CreateDeviceTypeForm,
    },
    //Policy List
    { path: "/policyList", component: PolicyListNew },
    { path: "/policyListForm/:action", component: PolicyListForm },
    { path: "/policyListForm/:action/:policyId", component: PolicyListForm },
    //Policy Rule
    { path: "/policyRules", component: PolicyRulesList },
    { path: "/policyRuleForm/:action", component: PolicyRulesForm },
    { path: "/policyRuleForm/:action/:policyRuleId", component: PolicyRulesForm },
    //Device List
    { path: "/deviceList", component: DeviceListNew },
    { path: "/deviceListForm/:action", component: DeviceListFormNew },
    {
        path: "/deviceListForm/:action/:deviceListId",
        component: DeviceListFormNew,
    },
    { path: "/bulkUploadHistory", component: BulkUploadHistoryDetails },
    //Rule Engine
    { path: "/ruleEngine", component: RulesEngine },
    { path: "/addRule/:action", component: EditRuleEngineTailwind },
    { path: "/editRule/:action/:id", component: EditRuleEngineTailwind },
    { path: "/cloneRule/:action/:id", component: EditRuleEngineTailwind },
    //Enterprise Application
    { path: "/applications", component: EnterpriseAppList },
    { path: "/applicationForm/:action", component: CreateEnterpriseApp },
    { path: "/applicationForm/:action/:id", component: CreateEnterpriseApp },
    //Subscriptions
    { path: "/subscriptions", component: SubscriptionsList },
    //Consents
    { path: "/consents", component: ConsentList },
    {
        path: "/consentEditForm/:action/:deviceID/:appID",
        component: ConsentEditForm,
    },
    {
        path: "/consentBulkUploadHistory",
        component: DeviceListViewConsentHistory,
    },
    //Event Records
    { path: "/eventRecords", component: EventRecordsList },
];
export default routes;
