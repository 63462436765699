import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import rightarrow from "../../assets/img/right-arrow-grey.svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { getIconMapping } from "../../constants_map";
import walletSrc from "../../assets/img/wallet.png";
import smsSrc from "../../icons/smsIcon.svg";
import whatsappSrc from "../../icons/whatsappIcon_.svg";
import numberSrc from "../../icons/numbersIcon.svg";
import billingSrc from "../../icons/billingIcon.svg";
import { t } from "i18next";
function NotificationDropdown(props) {
    var _a;
    var navigate = useNavigate();
    var userDetails = useContext(AuthContext).userDetails;
    //const { mutate: updateAPI } = useMutation(updateNotificationStatus);
    var handleNavigate = function (eleId, eleStatus) {
        //props.unreadRefetch();
        navigate("/app/notification", { state: { id: eleId, status: eleStatus } });
    };
    return (_jsx("div", { id: "toolTip7", className: "absolute ltr:left-10 rtl:right-10  ltr:-translate-x-64 rtl:translate-x-64  top-9 rounded-xl  table bg-white justify-center items-center shadow-sm  border w-[330px]", children: _jsxs("div", { className: "divide-y divide-gray-200 border border-white dark:border-gray-800 ", children: [(_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.map(function (ele, i) {
                    return i < 3 ? (_jsx("div", { children: _jsxs("div", { className: "flex flex-row justify-between px-4 py-2 hover:bg-AliceBlue", onClick: function () { return handleNavigate(ele.id, ele.read); }, children: [_jsx("div", { className: "flex my-auto rounded-full", children: _jsx("img", { className: "w-7 h-7 text-white", src: getIconMapping(ele.from) === "SMS"
                                            ? smsSrc
                                            : getIconMapping(ele.from) === "wallet"
                                                ? walletSrc
                                                : getIconMapping(ele.from) === "Whatsapp"
                                                    ? whatsappSrc
                                                    : getIconMapping(ele.from) === "numbers"
                                                        ? numberSrc
                                                        : billingSrc, alt: ele.from }) }), _jsxs("div", { className: "pl-2 mt-2 mb-2 w-3/4 font-semibold", children: [_jsx("div", { className: "text-[#394054] text-sm dark:text-gray-400 ".concat(ele.read ? "font-normal" : "font-bold"), children: ele.description }), _jsx("div", { className: "text-xs text-Dark-Grey ".concat(ele.read ? "font-normal" : "font-bold"), children: dayjs(new Date()).diff(dayjs(ele.createdAt), "minutes") >
                                                60
                                                ? dayjs(new Date()).diff(dayjs(ele.createdAt), "hours") >
                                                    48
                                                    ? dayjs(new Date()).diff(dayjs(ele.createdAt), "days") +
                                                        " days ago"
                                                    : dayjs(new Date()).diff(dayjs(ele.createdAt), "hours") + " hours ago "
                                                : dayjs(new Date()).diff(dayjs(ele.createdAt), "minutes") + " minutes ago" })] }), _jsx("span", { children: _jsx("img", { alt: "reports", className: "mx-1 fill-Dark-Grey mt-10", src: rightarrow }) })] }) }, i)) : null;
                }), _jsx("div", { className: "text-center  py-5 text-sm  font-semibold  border-gray-100", onClick: function () {
                        props.notificationRefetch();
                        props.unreadRefetch();
                        props.navigate("/app/notification");
                    }, children: _jsx("span", { className: "text-Dark-Grey underline", children: t("_View_all_notifications") }) })] }) }));
}
export default NotificationDropdown;
