/* eslint-disable no-template-curly-in-string */
////console.log("in api map", process.env.REACT_APP_IP);
var APIMapping = {
    // Cpassloginform: "/ngage_dev/api/v2/customers/login",
    // login: "/ngage_dev/api/v2/customers/login",
    // signup: "/ngage_dev/api/v2/customers/_register",
    // forgotpassword: "/ngage_dev/api/v2/customers/forgot-password",
    // resetpassword: "/ngage_dev/api/v2/customers/reset-password",
    // verifymobileno: "/ngage_dev/api/v2/customers/verify-mobile",
    // emailverification:
    //   "${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`",
    //policy: "/config/system/docs/privacy_policy.html",
    // screenelement: "/ngage_dev/api/v2/customers/screen-elements",
    //terms: "/config/system/docs/terms_and_conditions.html",
    // systemDocs: "/config/system/docs/{filename}",
    // assetsAccounts: "/config/assets/accounts/{groupname}/{filename}",
    // krackend clean up
    systemDocs: "/api/v1/config/system/docs/{filename}",
    assetsAccounts: "/api/v1/config/accounts/{groupname}/{filename}",
    getRegulationsLatest: "/api/v1/email/scsa/regulationslatest",
    editEmailDLFileUpload: "/api/v1/contacts/filesemail/{id}",
    // register: "/ngage_dev/api/v2/customers/_register",
    // emailnotification: "/ngage_dev/api/v2/customers/send-password-email",
    // setpassword: "/ngage_dev/api/v2/customers/set-password",
    // sendotp: "/ngage_dev/api/v2/customers/send-otp",
    // verifymobileno: "/ngage_dev/api/v2/customers/verify-mobile",
    emailverification: "${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`",
    //policy: "/ngage_config/api/v3/system/docs/privacy_policy.html",
    screenelement: "/ngage_dev/api/v2/customers/screen-elements",
    // terms: "/ngage_config/api/v3/system/docs/terms_and_conditions.html",
    register: "/ngage_dev/api/v2/customers/_register",
    emailnotification: "/ngage_dev/api/v2/customers/send-password-email",
    setpasswordold: "/ngage_dev/api/v2/customers/set-password",
    templateSubmitemail: "/api/v1/campaigns/email/template/send",
    //version3
    // Cpassloginform: "/selfonboarding/_login",
    // // forgotpassword: "/selfonboarding/alink/_send",
    // forgotpassword: "/selfonboarding/pswd/_sendResetalink  ",
    // resetpassword: "/selfonboarding/pswd/_reset",
    // changePassword: "/selfonboarding/pswd/_change",
    // setpassword: "/selfonboarding/pswd/_reset",
    // sendotp: "/selfonboarding/otp/_send",
    // activationlink: "/selfonboarding/alink/_send",
    // login: "/selfonboarding/_login",
    // signup: "/selfonboarding/_signup",
    // logout: "/selfonboarding/logout",
    // menu: "/selfonboarding/roles/menu",
    // verifyUserStatus: "/selfonboarding/verify/_status",
    // //verifyotp: "/ngage_accounts/api/v3/accounts/_verifyMobile",
    // verifyotp: "/selfonboarding/otp/_verify",
    // updateUser: "/ngage_accounts/api/v3/accounts/:accountid/users/:userid",
    //krackend mapping clean up
    Cpassloginform: "/api/v1/accounts/users/login",
    Cpassloginform1: "/api/v1/accounts/users/loginuser",
    forgotpassword: "/api/v1/accounts/users/password/sendalink",
    resetpassword: "/api/v1/accounts/users/password/reset",
    changePassword: "/api/v1/accounts/users/password/change",
    setpassword: "/api/v1/accounts/users/password/reset",
    sendotp: "/api/v1/accounts/users/sendotp",
    activationlink: "/api/v1/accounts/users/sendalink",
    signup: "/api/v1/accounts/users/signup",
    logout: "/api/v1/accounts/users/logout",
    logoutSession: "/api/v1/accounts/users/{id}/logout",
    logoutAllSessions: "/api/v1/accounts/users/logoutAllSessions",
    revoke: "/api/v1/accounts/users/revoke",
    verifyotp: "/api/v1/accounts/otp/verify",
    menu: "/api/v1/account/roles/menu",
    verifyUserStatus: "/api/v1/notification/alink/verify/_status",
    updateUser: "/ngage_accounts/api/v3/accounts/:accountid/users/:userid",
    getChannelControl: "/api/v1/accounts/getChannelStatus/{accountId}",
    updateChannelControl: "/api/v1/accounts/updateChannelStatus/{accountId}",
    // role management
    // addRole: "/role_mgmt/accounts/addrole",
    // updateRoleById: "/role_mgmt/accounts/updaterole/{roleId}",
    // deleteRoleById: "role_mgmt/accounts/deleterole/{roleId}`",
    // getRoleById: "/role_mgmt/accounts/getrolebyid/{roleId}",
    // getRoles: "/role_mgmt/accounts/getroles",
    // getResourcesTemplates: "/role_mgmt/resources/gettemplates",
    // getResourcesByTemplate: "/role_mgmt/resources/gettemplatebyid/{templateName}",
    // getRoleResource: "/role_mgmt/system/resources/gettypes",
    //Quiet time
    //createquiettime: "/api/v1/config/quietTime",
    //updatequiettime: "/api/v1/accounts/users/{id}",
    //getQuietTime: "/api/v1/accounts/users/{id}",
    // krackend clean up role management
    addRole: "/api/v1/accounts/roles",
    updateRoleById: "/api/v1/accounts/roles/{roleId}",
    deleteRoleById: "/api/v1/accounts/roles/{roleId}",
    getRoleById: "/api/v1/accounts/roles/{roleId}",
    getRoles: "/api/v1/accounts/roles",
    getResourcesTemplates: "/api/v1/platform/roles/templates",
    getResourcesByTemplate: "/api/v1/platform/templates/{templateName}",
    getRoleResource: "/api/v1/platform/resources",
    //usermanagementapi
    // getuser: "/usermgmt/getusers/users",
    // getuserid: "/selfonboarding/getuserbyid/users/{id}",
    // createuser: "/usermgmt/adduser/users",
    // updateuser: "/selfonboarding/updateuser/users/{id}",
    // deactivateuser: "/usermgmt/deactivateuser/{id}",
    // activateuser: "/usermgmt/activateuser/{id}",
    // todo :change id to userId once old urls are removed
    //krackend clean up  usermanagementapi
    getuser: "/api/v1/accounts/users",
    getuserid: "/api/v1/accounts/users/{id}",
    createuser: "/api/v1/accounts/users",
    updateuser: "/api/v1/accounts/users/{id}",
    deactivateuser: "/api/v1/accounts/users/{id}/deactivate",
    activateuser: "/api/v1/accounts/users/{id}/activate",
    //Quiet time
    createquiettime: "/api/v1/config/quietTime",
    quiteTimeList: "/api/v1/config/quietTimes",
    updatequiettime: "/api/v1/config/quietTime/{id}",
    deleteQuietTime: "/api/v1/config/quietTime/{id}",
    getQuietTimeById: "/api/v1/config/quietTimes",
    //contactmgmt
    // addcontact: "/marvel/createcontact",
    // contactlist: "/marvel/getcontacts",
    // editcontact: "/marvel/updatecontact/{id}",
    // deletecontact: "/marvel/deletecontact/{id}",
    // addbulkcontact: "/marvel/uploadbulkcontacts",
    // bulkfileupload: "/marvel/uploadbcfile",
    // removefileupload: "/marvel/deletebcfiles",
    // getsummary: "/marvel/getsummary/{id}",
    // addcontactgrp: "/marvel/createcontactgroup",
    // contactgrouplist: "/marvel/getcontactgroup/group/details?listing=true",
    // deletecontactgrp: "/marvel/deletecontactgroup/group/{groupname}",
    // updatecontactgrp: "/marvel/updatecontactgroup/group/{groupname}",
    // dlfileuplaod: "/marvel/uploaddlfile",
    // Krackend code clean up
    addcontact: "/api/v1/contacts",
    contactlist: "/api/v1/contacts",
    editcontact: "/api/v1/contacts/{id}",
    deletecontact: "/api/v1/contacts/{id}",
    addbulkcontact: "/api/v1/contacts/files/load",
    bulkfileupload: "/api/v1/contacts/files",
    removefileupload: "/api/v1/contacts/filesbc",
    getsummary: "/api/v1/contacts/summary/{id}",
    getsummaryforemail: "/api/v1/contacts/filesEmail/{id}",
    addcontactgrp: "/api/v1/groups",
    contactgrouplist: "/api/v1/groups?listing=true",
    deletecontactgrp: "/api/v1/groups/name/{groupname}",
    updatecontactgrp: "/api/v1/groups/name/{groupname}",
    dlfileuplaod: "/api/v1/distributions/upload",
    editdlfileupload: "/api/v1/contacts/files/{id}",
    emaildlfileuplaod: "/api/v1/distributionsEmail/upload",
    emaileditdlfileupload: "/api/v1/contacts/filesemail/{id}",
    removedlfile: "/api/v1/contacts/files",
    updatedlfile: "/api/v1/contacts/files/{id}",
    //msw
    mswlogin: "/login",
    mswuser: "/user",
    //smppsession
    // generatesmpp: "/smpp/generatesmpp",
    // updateipaddress: "/smpp/updateips",
    // regeneratesmpp: "/smpp/regeneratesmpp",
    //krackend url clean up  smppsession
    generatesmpp: "/api/v1/accounts/smpp",
    updateipaddress: "/api/v1/users/smpp/ips",
    regeneratesmpp: "/api/v1/accounts/users/smpp/credentials/generate",
    //smstemplate
    // editTemplate: "/marvel/updatetemplate/{id}",
    // deleteTemplate: "/marvel/deletetemplate/{id}",
    // getTemplate: "/marvel/gettemplate/{id}",
    // addTemplate: "/marvel/addtemplate",
    // getTemplates: "/marvel/gettemplate",
    //krackend clean sms template
    editTemplate: "/api/v1/sms/templates/{id}",
    updateSmsTemplateStatus: "/api/v1/sms/templates/updateTemplateStatus/{id}",
    deleteTemplate: "/api/v1/sms/templates/{id}",
    getTemplate: "/api/v1/sms/templates/{id}",
    addTemplate: "/api/v1/sms/templates",
    getTemplates: "/api/v1/sms/templates",
    //krackend clean Email template
    editTemplateEmail: "/api/v1/email/templates/{id}",
    deleteTemplateEmail: "/api/v1/email/templates/{id}",
    getTemplateEmail: "/api/v1/email/templates/id/{id}",
    addTemplateEmail: "/api/v1/email/templates",
    getTemplatesEmail: "/api/v1/email/templates",
    uploadTemplateEmail: "/api/v1/email/templates/upload",
    uploadContentEmail: "/api/v1/email/templates/content",
    cloneTemplateEmail: "/api/v1/email/templates/clone/{id}",
    getTemplateJson: "/api/v1/email/templates/getJsonContent",
    editTemplateContentEmail: "/api/v1/email/templates/content/{id}",
    //krackend clean Email template Local
    // editTemplateEmail: "v3/templatesEmail/{id}",
    // deleteTemplateEmail: "v3/templatesEmail/{id}",
    // getTemplateEmail: "/api/v1/email/templates/id/{id}",
    // addTemplateEmail: "v3/templatesEmail",
    // getTemplatesEmail: "v3/templatesEmail",
    // uploadTemplateEmail: "v3/templatesEmail/upload",
    //assisted onboarding
    // enterprisePaginationApi: "/asstonboarding/getenterprises",
    // deactivateEnterprise: "/asstonboarding/deactivateenterprise/{id}",
    // getClasses: "/asstonboarding/getaccountsclass",
    // createEnterprise: "/asstonboarding/addenterprise",
    // editEnterprise: "/asstonboarding/updateenterprise/{id}",
    // getEnterpriseById: "/asstonboarding/getenterprisebyid/{accountId}",
    // activateEnterprise: "/asstonboarding/activateenterprise/{id}",
    // keackend clean up assisted onboarding
    enterprisePaginationApi: "/api/v1/accounts",
    deactivateEnterprise: "/api/v1/accounts/{id}/deactivate",
    getClasses: "/api/v1/accounts/class",
    createEnterprise: "/api/v1/accounts",
    editEnterprise: "/api/v1/accounts/{id}",
    getEnterpriseById: "/api/v1/accounts/{accountId}",
    activateEnterprise: "/api/v1/accounts/{id}/activate",
    //client-secret
    // clientSecret: "/selfonboarding/generateclientsecret",
    // refreshToken: "/selfonboarding/refreshtoken",
    // Transfer Type
    createEnterpriseTransferType: "/api/v4/accounts",
    editEnterpriseTransferType: "/api/v4/accounts/{id}",
    getEnterpriseByIdTransferType: "/api/v4/accounts/{accountId}",
    enterprisePaginationApiTransferType: "/api/v4/accounts",
    platformAdminUsers: "/api/v1/wallet/creditHistory/{userId}",
    //krakend clean up client-secret
    clientSecret: "/api/v1/accounts/users/secret",
    refreshToken: "/api/v1/accounts/users/refreshtoken",
    //Sender address-PA
    // getAllSpecifications: "/scsa/getregulations",
    // getRegulationsByCountry: "/scsa/getregulation",
    // updateCountryRegulation: "/scsa/updateregulation",
    // getSenderAddressList: "/scsa/senderaddresses",
    // approveRejectSenderAddress: "/scsa/updatestatus",
    // getAttachments: "/scsa/getattachments",
    // createRegulation: "/scsa/createregulation",
    // deleteRegulation: "/scsa/deleteregulation",
    //Sender address-PA krakend cleanup urls
    // getRegulationsByCountry: "/v1/numbers/scsa/regulations",
    // getAllSpecifications: "/v1/numbers/scsa/regulations",
    // updateCountryRegulation: "/v1/numbers/scsa/regulations",
    // getSenderAddressList: "/v1/numbers/scsa/sas",
    // getAttachments: "/v1/numbers/scsa/attachments",
    // approveRejectSenderAddress: "/v1/numbers/scsa/wf",
    //deleteRegulation:"/v1/numbers/scsa/regulations",
    //createRegulation: "/v1/numbers/scsa/regulations",
    //Email Address PA
    //createEmailRegulation: "/api/v1/email/scsa/regulations",
    //updateEmailRegulation: "/api/v1/email/scsa/regulations",
    //getEmailAddressList: "/api/v1/email/scsa/sas",
    //EmailapproveRejectSenderAddress: "/api/v1/email/wf",
    //getAllEmailSpecifications: "/api/v1/email/scsa/regulations",
    //deleteEmailRegulation: "/api/v1/email/scsa/regulations",
    //Email Address - EA
    //registerEmailId: "/api/v1/email/scsa/register",
    //getEmailAddressListEA: "/api/v1/email/scsa",
    //SenderAddress- EA
    // registerSenderId: "/scsa/registersenderid",
    // getSenderAddressListEA: "/scsa/sa/list",
    // getSenderAddressListByuserId: "/scsa/sa/listbyuser",
    // associateuser: "/scsa/addusers",
    // getAddedUsers: "/scsa/getusers/{id}",
    // activateDeactivate: "/scsa/updatesenderaddress",
    //registerSenderId:"/v1/numbers/scsa/register",
    //getSenderAddressListEA: "/v1/numbers/scsa",
    //getSenderAddressListByuserId: "/v1/numbers/scsa/users",
    //associateuser: "/v1/numbers/scsa/users",
    //getAddedUsers: "/v1/numbers/scsa/{id}",
    //activateDeactivate:"/v1/numbers/scsa",
    // krakend clean up distribution list
    createdistlist: "/api/v1/distributions",
    getdistlist: "/api/v1/distributions",
    editdistlist: "/api/v1/distributions/{id}",
    deletedistlist: "/api/v1/distributions/{id}",
    // krackend clean up distribution list Email
    createdistlistEmail: "/api/v1/distributionsEmail",
    getdistlistEmail: "/api/v1/distributionsEmail",
    editdistlistEmail: "/api/v1/distributionsEmail/{id}",
    deletedistlistEmail: "/api/v1/distributionsEmail/{id}",
    //krackend claen up Wallet
    getWalletBalance: "/api/v1/wallet/{userId}/balance",
    getCredit: "/api/v1/wallet/credit",
    debit: "/api/v1/wallet/debit",
    transfer: "/api/v1/wallet/transfer",
    updateBuffer: "/api/v1/wallet/updateBuffer",
    getBuffer: "/api/v1/accounts/renewalcharges/list/{userId}",
    getMyBalance: "/wallet/mybalance",
    // Update Credit Limit api
    updateCreditLimit: "/api/v1/wallet/updateCreditLimit",
    // krakend clean up url
    listAccounts: "/api/v1/accounts/list",
    //Sender address-PA
    // getAllSpecifications: "/senderaddrs/scsa/getregulations",
    // getRegulationsByCountry: "/senderaddrs/scsa/getregulation",
    // updateCountryRegulation: "/senderaddrs/scsa/updateregulation",
    // getSenderAddressList: "/senderaddrs/scsa/senderaddresses",
    // approveRejectSenderAddress: "/senderaddrs/scsa/updatestatus",
    // getAttachments: "/senderaddrs/scsa/getattachments",
    // createRegulation: "/senderaddrs/scsa/createregulation",
    // deleteRegulation: "/senderaddrs/scsa/deleteregulation",
    getRegulationsByCountry: "/api/v1/numbers/scsa/regulations",
    getAllSpecifications: "/api/v1/numbers/scsa/regulations",
    updateCountryRegulation: "/api/v1/numbers/scsa/regulations",
    getSenderAddressList: "/api/v1/numbers/scsa/sas",
    getAttachments: "/api/v1/numbers/scsa/attachments",
    getAttachmentsById: "/api/v1/numbers/scsa/attachments/{id}",
    getAttachmentsEmail: "/api/v1/email/scsa/attachments",
    approveRejectSenderAddress: "/api/v1/numbers/scsa/wf",
    deleteRegulation: "/api/v1/numbers/scsa/regulations",
    createRegulation: "/api/v1/numbers/scsa/regulations",
    //Email Address PA
    createEmailRegulation: "/api/v1/email/scsa/regulations",
    updateEmailRegulation: "/api/v1/email/scsa/regulations",
    getEmailAddressList: "/api/v1/email/scsa/sas",
    EmailapproveRejectSenderAddress: "/api/v1/email/wf",
    getAllEmailSpecifications: "/api/v1/email/scsa/regulations",
    deleteEmailRegulation: "/api/v1/email/scsa/regulations",
    //SenderAddress- EA
    // registerSenderId: "/senderaddrs/scsa/registersenderid",
    // getSenderAddressListEA: "/senderaddrs/scsa/sa/list",
    // getSenderAddressListByuserId: "/senderaddrs/scsa/sa/listbyuser",
    // associateuser: "/senderaddrs/scsa/addusers",
    // getAddedUsers: "/senderaddrs/scsa/getusers",
    // activateDeactivate: "/senderaddrs/scsa/updatesenderaddress",
    registerSenderId: "/api/v1/numbers/scsa/register",
    getSenderAddressListEA: "/api/v1/numbers/scsa",
    getSenderAddressListByuserId: "/api/v1/numbers/scsa/users",
    associateuser: "/api/v1/numbers/scsa/users",
    getAddedUsers: "/api/v1/numbers/scsa/{id}",
    activateDeactivate: "/api/v1/numbers/scsa",
    deleteSenderAddress: "/api/v1/numbers/scsa/{id}",
    updateSenderAddress: "/api/v1/numbers/scsa/updateSenderId/{id}",
    //Email Address - EA
    registerEmailId: "/api/v1/email/scsa/register",
    getEmailAddressListEA: "/api/v1/email/scsa",
    activateDeactivateEmail: "/api/v1/email/scsa",
    associateuserEmail: "/api/v1/email/scsa/users",
    getAddedUsersEmail: "/api/v1/email/scsa/{id}",
    getEmailAddressListByuserId: "/api/v1/email/scsa/users",
    //Campaign Mgmt
    // listCampaign: "/campmgmt/campaign/listcampaign",
    // bulkSimpleUpload: "/campmgmt/msgs/sms/simpleupload",
    // bulkFileUpload: "/campmgmt/msgs/sms/fileupload",
    // bulkTemplateUpload: "/campmgmt/msgs/sms/templateupload",
    // simpleSubmit: "/campmgmt/msgs/sms/simplesubmit",
    // fileSubmit: "/campmgmt/msgs/sms/filesubmit",
    // templateSubmit: "/campmgmt/msgs/sms/templatesubmit",
    // //cancelCampaign: "/campmgmt/campaign/cancel/{id}",
    // //pauseCampaign: "/campmgmt/campaign/pause/{id}",
    // //resumeCampaign: "/campmgmt/campaign/resume/{id}",
    // //campainById: "/campmgmt/campaign/getcampaign/{id}",
    // //rescheduleCampaign: "/campmgmt/campaign/reschedule/{id}",
    // contactGroupByName: "/marvel/getcontactgroupbyname/{name}",
    // contactByName: "/marvel/getcontactbyname/{name}",
    // getDistributionByName: "/marvel/getdistributionlistbyname/{name}",
    // campainById: "/campmgmt/campaign/channel/getcampaign/{channel}/{id}",
    // cancelCampaign: "/campmgmt/campaign/channel/cancel/{channel}/{id}",
    // pauseCampaign: "/campmgmt/campaign/channel/pause/{channel}/{id}",
    // resumeCampaign: "/campmgmt/campaign/channel/resume/{channel}/{id}",
    // rescheduleCampaign: "/campmgmt/campaign/channel/reschedule/{channel}/{id}",
    // campaignPieDetails: "/reports/getCampaignPie/{campaignId}",
    //clean up krackend url sms campaign
    listCampaign: "/api/v1/campaigns",
    bulkSimpleUpload: "/api/v1/campaigns/sms/inline/send",
    bulkFileUpload: "/api/v1/campaigns/sms/file/inline/send",
    bulkTemplateUpload: "/api/v1/campaigns/sms/template/inline/send",
    simpleSubmit: "/api/v1/campaigns/sms/send",
    fileSubmit: "/api/v1/campaigns/sms/file/send",
    templateSubmit: "/api/v1/campaigns/sms/template/send",
    contactGroupByName: "/api/v1/groups/name/{name}",
    contactByName: "/api/v1/contacts/{name}",
    getDistributionByName: "/api/v1/distributions/{name}",
    campainById: "/api/v1/campaigns/c/{channel}/{id}/{recurrenceId}",
    cancelCampaign: "/api/v1/campaigns/{channel}/cancel/{id}/{recurrenceId}",
    cancelRecurrence: "/api/v1/campaigns/{channel}/cancel/{id}/{recurrenceId}",
    pauseCampaign: "/api/v1/campaigns/{channel}/pause/{id}/{recurrenceId}",
    resumeCampaign: "/api/v1/campaigns/{channel}/resume/{id}/{recurrenceId}",
    rescheduleCampaign: "/api/v1/campaigns/{channel}/reschedule/{id}/{recurrenceId}",
    campaignPieDetails: "/api/v1/metrics/{channel}/campaign/{campaignId}/{recurrenceId}",
    campaignPieDetailsVoice: "/api/v1/metrics/{channel}/campaign/{campaignId}",
    campaignRecurrences: "/api/v1/campaigns/c/{channel}/recurrences/{campaignId}",
    //clean up krackend url email campaign
    EmaillistCampaign: "/api/v1/campaigns",
    //Whatsapp Campaign
    // simpleSubmitForWhatsapp: "/api/v1/campaigns/whatsapp/send",
    // simpleUploadForWhatsapp: "/api/v1/campaigns/whatsapp/inline/send",
    // templateUploadForWhatsapp: "/api/v1/campaigns/whatsapp/template/inline/send",
    // templateSubmitForWhatsapp: "/api/v1/campaigns/whatsapp/template/send",
    //krackend clean up Whatsapp Campaign
    simpleSubmitForWhatsapp: "/api/v1/campaigns/whatsapp/send",
    simpleUploadForWhatsapp: "/api/v1/campaigns/whatsapp/inline/send",
    templateUploadForWhatsapp: "/api/v1/campaigns/whatsapp/template/inline/send",
    templateSubmitForWhatsapp: "/api/v1/campaigns/whatsapp/template/send",
    //krackend clean up Ratesheet
    getRateSheetList: "/api/v1/ratesheets",
    getProducts: "/api/v1/products",
    getSubproducts: "/api/v1/products/features/{id}",
    getRateSheetById: "/api/v1/ratesheets/{id}/summary",
    activateDeactivateRatesheet: "/api/v1/ratesheets/{id}/status",
    createRateSheet: "/api/v1/ratesheets/upload",
    getRateSheetsForEA: "/api/v1/products/{uid}",
    updateRatesheet: "/api/v1/ratesheets/{id}/upload",
    getConsumptionInfoForFeatures: "/api/v1/ratesheet/slab/counters",
    //Package
    // getPackagesList: "/pkgmgmt/pkg_rate/getpackages",
    // createPackage: "/pkgmgmt/pkg_rate/createpackage",
    // updatePackage: "/pkgmgmt/pkg_rate/updatepackage/{id}",
    // getPackageById: "/pkgmgmt/pkg_rate/getpackage/{id}",
    // activateDeactivatePackage: "/pkgmgmt/pkg_rate/package/{id}/updatestatus",
    // getActiveBrowsePackages: "/pkgmgmt/pkg_rate/account/{uid}/packages",
    // transferPackagebyEA: "/pkgmgmt/pkg_rate/package/transfer",
    // buyPackageByEA: "/pkgmgmt/pkg_rate/package/buy",
    // getOrganisations: "/pkgmgmt/listorganizations",
    // getCurrentUserPackages: "/pkgmgmt/pkg_rate/packages/active",
    // getPackageActiveEnterpriseCount:
    //   "/pkgmgmt/pkg_rate/package/{pkgid}/activeusers",
    // getRatesheetDownload: "/pkgmgmt/pkg_rate/ratecards/{id}/link",
    // krackend clean up packages
    getPackagesList: "/api/v1/packages",
    createPackage: "/api/v1/packages",
    updatePackage: "/api/v1/packages/{id}",
    getPackageById: "/api/v1/packages/{id}",
    activateDeactivatePackage: "/api/v1/packages/{id}/status",
    getActiveBrowsePackages: "/api/v1/packages/account/{uid}",
    transferPackagebyEA: "/api/v1/packages/transfer",
    updatePackagebyEA: "/api/v1/package/updateRenewalType",
    buyPackageByEA: "/api/v1/packages/buy",
    getOrganisations: "/api/v1/platform/org",
    getCurrentUserPackages: "/api/v1/packages/active",
    getPackageActiveEnterpriseCount: "/api/v1/packages/{pkgid}/activeusers",
    unsharePackage: "/api/v1/packages/revoke",
    unsubscribePackage: "/api/v1/package/unsubscribe",
    getRatesheetDownload: "/api/v1/ratesheets/{id}/link",
    deleteRejectedEmailId: "/api/v1/email/scsa/delete",
    //Whatsapp Sandbox
    // getQRForSandbox: "/wa/sandbox/qrc",
    // setupWebhookForSandbox: "/wa/sandbox/setup",
    // sendMessageToSandbox: "/wa/sandbox/send",
    // getUUID: "/wa/sandbox/messages/{phonenumber}",
    // deleteUUID: "/wa/sandbox/messages/{phonenumber}",
    // krackend clean up Whatsapp Sandbox
    getQRForSandbox: "/api/v1/whatsapp/sandbox/qrc",
    setupWebhookForSandbox: "/api/v1/whatsapp/sandbox/setup",
    sendMessageToSandbox: "/api/v1/whatsapp/sandbox/send",
    getUUID: "/api/v1/whatsapp/sandbox/messages/{phonenumber}",
    deleteUUID: "/api/v1/whatsapp/sandbox/messages/{phonenumber}",
    //Whatsapp templates
    // getWaTemplatesList: "/wa/templates/get",
    // deleteWaTemplate: "/wa/templates/delete/{waNumber}/{templateName}/{templateId}",
    // createTemplate: "/wa/templates/create/{waNumber}",
    // getWaTemplateById: "",
    //krackend clean up Whatsapp templates
    getWaTemplatesList: "/api/v1/whatsapp/templates",
    deleteWaTemplate: "/api/v1/whatsapp/templates/{waNumber}/{templateName}/{templateId}",
    editWaTemplate: "/api/v1/whatsapp/templates/{waNumber}/{templateName}",
    createTemplate: "/api/v1/whatsapp/templates/{waNumber}",
    getWaTemplatesLanguages: "/api/v1/whatsapp/templates/languages",
    // getWaTemplateById: "",
    //todo : change prefix
    //Number management
    // uploadNumbers: "/selfonboarding/number/_upload",
    // uploadNumbersStatus: "/selfonboarding/number/upload/status/{id}",
    // getNumbers: "/selfonboarding/number/numberslist",
    // getNumberById: "/selfonboarding/number/{numberid}",
    // editNumberPA: "/selfonboarding/number/edit/{numberid}",
    // buyNumber: "/selfonboarding/number/buy/{numberid}",
    // deleteNumber: "/selfonboarding/number/delete/{numberid}",
    // getNumbersSettings: "/selfonboarding/number/settings/coolingperiod",
    // updateCoolingPeriod: "/selfonboarding/number/update/coolingperiod",
    // getCampaignsAssociatedtoNumber: "/selfonboarding/msgs/sms/{virtualNumber}",
    //krackend clean up
    uploadNumbers: "/api/v1/numbers/upload",
    uploadNumbersStatus: "/api/v1/numbers/upload/{id}",
    getNumbers: "/api/v1/numbers",
    getNumberById: "/api/v1/numbers/{numberid}",
    editNumberPA: "/api/v1/numbers/{numberid}",
    buyNumber: "/api/v1/numbers/{numberid}/buy",
    deleteNumber: "/api/v1/numbers/{numberid}",
    getNumbersSettings: "/api/v1/numbers/coolingperiod",
    updateCoolingPeriod: "/api/v1/numbers/coolingperiod",
    getCampaignsAssociatedtoNumber: "/api/v1/campaigns/sms/{virtualNumber}",
    //Provider apis
    createProvider: "/api/v1/providers",
    updateProvider: "/api/v1/providers/{id}",
    deleteProvider: "/api/v1/providers/{id}",
    getServices: "/api/v1/providers/services",
    getProviders: "/api/v1/providers",
    getServiceById: "/api/v1/providers/services/{id}",
    updateService: "/api/v1/providers/services/{id}/{ch}",
    createSmsc: "/api/v1/providers/{id}/services/sms",
    createSip: "/api/v1/providers/{id}/services/voice",
    deleteSmsc: "/api/v1/providers/{id}/services/sms/{serviceId}",
    deleteSip: "/api/v1/providers/{id}/services/voice/{serviceId}",
    updateRateSheet: "/api/v1/providers/ratesheets/{id}",
    uploadRateSheet: "/api/v1/providers/ratesheets",
    getRateSheet: "/api/v1/providers/ratesheets",
    //Routing apis
    createRule: "/api/v1/providers/routing",
    updateRule: "/api/v1/providers/routing/{id}",
    deleteRule: "/api/v1/providers/routing/{id}",
    getRule: "/api/v1/providers/routing",
    //Alerts and notifications
    // getNotifications: "/alerts/notification/list",
    // sendNotifications: "/alerts/notification/send",
    // deleteNotification: "/alerts/notification/delete",
    // updateNotification: "/alerts/notification/updatestatus",
    // unreadNotificationCount: "/alerts/notification/getunreadcount",
    // getAlertSettings: "/alerts/notification/alertsettings",
    // getTopics: "/alerts/notifications/topics",
    // getsubscribedTopics: "/alerts/notifications/subscribe/{uid}",
    // subscribeTopic: "/alerts/notifications/subscribe",
    //krackend clean up Alerts and notifications
    getNotifications: "/api/v1/notifications",
    sendNotifications: "/api/v1/notifications/send",
    deleteNotification: "/api/v1/notifications/delete",
    updateNotification: "/api/v1/notifications/status",
    unreadNotificationCount: "/api/v1/notifications/unread",
    getAlertSettings: "/alerts/notification/alertsettings",
    getTopics: "/api/v1/notifications/topics",
    getsubscribedTopics: "/api/v1/notifications/subscribe/{uid}",
    subscribeTopic: "/api/v1/notifications/subscribe",
    //Whatsapp Profile APIs
    // getClients: "/alerts/profile/wa/client",
    // addClient: "/alerts/profile/wa/client",
    // updateWebHook: "/alerts/profile/wa/client",
    // getChannelDetails: "/alerts/profile/wa/channel/{chid}",
    // updateChannelDetails: "/api/v1/whatsapp/profile/{chid}",
    // deactivateChannel: "/alerts/profile/wa/client/{clid}/{chid}",
    // krackend clean up Whatsapp Profile APIs
    getClients: "/api/v1/whatsapp/profiles",
    getClientsMETA: "/api/v1/whatsapp/profiles",
    //  getClients: "/api/v1/whatsapp/client",
    addClient: "/api/v1/whatsapp/profile",
    addMetaProfile: "/api/v1/whatsapp/profile",
    updateWebHook: "/api/v1/whatsapp/client",
    getChannelDetails: "/api/v1/whatsapp/profile/{chid}",
    getChannelDetailsMETA: "/api/v1/whatsapp/profile/{chid}",
    updateChannelDetails: "/api/v1/whatsapp/profile/{chid}",
    updateMetaChannelDetails: "/api/v1/whatsapp/profile/{chid}",
    deactivateChannel: "/api/v1/whatsapp/client/{clid}/{chid}",
    // krackend clean up Whatsapp Profile APIs
    //  getClients: "/api/v1/whatsapp/client",
    //  addClient: "/api/v1/whatsapp/client",
    //  updateWebHook: "/api/v1/whatsapp/client",
    //  getChannelDetails: "/api/v1/whatsapp/channel/{chid}",
    //  updateChannelDetails: "/api/v1/whatsapp/profile/{chid}",
    // Voice group APIs
    getGroupById: "/api/v1/groups/{groupId}",
    createGroup: "/api/v1/groups/_all",
    deleteGroup: "/api/v1/groups/{groupId}",
    getAllGroups: "/api/v1/groups/_all",
    removeUsersFromGroup: "/api/v1/groups/{groupId}/remove",
    addUsersToGroup: "/api/v1/groups/{groupId}/users",
    updateGroupStatus: "/api/v1/groups/{groupId}",
    // VR APIs
    getVR: "/api/v1/voice/vr",
    createVR: "/api/v1/voice/{number}/vr",
    deleteVR: "/api/v1/voice/{number}/vr/{vrId}",
    updateVR: "/api/v1/voice/{number}/vr/{vrId}",
    getVRById: "/api/v1/voice/vr",
    //IVR APIs
    getIVR: "/api/v1/voice/ivr",
    deleteIVR: "/api/v1/voice/ivr/{ivrId}",
    createIVR: "/api/v1/voice/ivr",
    updateIVR: "/api/v1/voice/ivr/{ivrId}",
    getIVRById: "/api/v1/voice/ivr/{ivrId}",
    // Audit logs
    // getLogs: "/selfonboarding/logs/audit",
    // getDebugLogs: "/selfonboarding/logs/debug",
    // krackend clean up
    getLogs: "/api/v1/logs/audit",
    getDebugLogs: "/api/v1/logs/debug",
    //Dashboard APIs
    getPerformancePie: "/api/v1/metrics/performance/_stats",
    getInterfacePie: "/api/v1/metrics/interface/_stats",
    getSummaryPanel: "/api/v1/metrics/summarypanel/_stats",
    getInterfacePieEmail: "/api/v1/metrics/email/interface/_stats",
    getVolumetricsPanel: "/api/v1/metrics/volumetricspanel/_stats",
    getVolumetricsPanelEmail: "/api/v1/metrics/email/volumetricspanel/_stats",
    getStatusTrendGraph: "/api/v1/metrics/statustrendgraph/_stats",
    getVoiceStatusTrendGraph: "/api/v1/metrics/voice/trend",
    getVoicePerformancePie: "/api/v1/metrics/voice/performance",
    getVoiceInterfacePie: "/api/v1/metrics/voice",
    getVoiceVolumetricsPanel: "/api/v1/metrics/voice/volumes",
    getNumberConfigurationPanel: "/api/v1/voice/vr/stats",
    getNumbersPanel: "/api/v1/numbers/stats",
    getNumberPanel: "/api/v1/metrics/numberpanel/_stats",
    getSenderPie: "/api/v1/numbers/scsa/stats",
    getCampaignRevenue: "/api/v1/reports/revenue/campaigns",
    getTopEnterprises: "/api/v1/reports/revenue/topEnterprise",
    getTopPackages: "/api/v1/reports/revenue/topPackages",
    getSummaryRevenue: "/api/v1/reports/revenue/totalRevenue",
    getEmailMetrics: "/api/v1/metrics/email/dashboard/rate",
    getMessageTemplatePie: "/api/v1/reports/getMessageTemplatePie",
    getSchedulingTypePie: "/api/v1/metrics/campaigns/sms/_stats",
    getTPSConsumption: "/api/v1/metrics/tpsconsumption/_stats",
    getEmailStatusTrendGraph: "/api/v1/reports/email/getStatusTrendGraph",
    getEMAILVolumetricsPanel: "/api/v1/email/metrics/performance/_stats",
    getEmailCampaignSummary: "/api/v2/metrics/email/subscriber/campaign/{campaignId}/{recId}",
    getSmsCampaignPanel: "/api/v1/metrics/campaigns/sms/_status",
    getEmailTemplatePie: "/api/v1/reports/getEmailTemplatePie",
    getTopEnterprisesForEmail: "/api/v1/reports/email/revenue/topEnterprise",
    getTopPackagesForEmail: "/api/v1/reports/email/revenue/topPackages",
    getEmailPerformancePie: "/api/v1/email/metrics/performance/_stats",
    getEmailScheduleType: "/api/v1/email/metrics/schedule/_stats",
    getEmailCampaigns: "/api/v1/email/metrics/campaign/_stats",
    getWhatsAppCampaigns: "/api/v1/wa/metrics/campaign/_stats",
    getWhatsappPerformancePie: "/api/v1/wa/metrics/performance/_stats",
    getWhatsappStatusTrendGraph: "/api/v1/reports/wa/getStatusTrendGraph",
    getSummaryRevenueForVoice: "/api/v1/reports/revenue/voice/totalRevenue",
    getCampaignRevenueForVoice: "/api/v1/reports/revenue/voice/campaigns",
    getTopEnterprisesForVoice: "/api/v1/reports/revenue/voice/topEnterprise",
    getTopPackagesForVoice: "/api/v1/reports/revenue/voice/topPackages",
    concurrentCalls: "/api/v1/metrics/voice/cc",
    getWhatsappMessageTypePie: "/api/v1/wa/metrics/messagetype/_stats",
    getTopEnterprisesForWA: "/api/v1/wa/metrics/revenue/topEnterprise",
    getTopPackagesForWA: "/api/v1/wa/metrics/revenue/topPackage",
    getWAInterfacePie: "/api/v1/wa/metrics/interface/_stats",
    getWAVolumetricsPanel: "/api/v1/wa/metrics/performance/_stats",
    getWAMessageTypeTrend: "/api/v1/wa/metrics/msgtypetrend/_stats",
    getWARevenueTrend: "/api/v1/wa/metrics/revenuetrend/_stats",
    getWhatsAppCampaignMessage: "/api/v1/wa/metrics/campaignmsg/_stats",
    getSmsCampaignPanel: "/api/v1/metrics/campaigns/sms/_status",
    getWATemplates: "/api/v1/wa/metrics/message/_stats",
    getWATemplateType: "/api/v1/wa/metrics/templatetype/_stats",
    getWAStatusTrend: "/api/v1/wa/metrics/conversation/_stats",
    getWAScheduleType: "/api/v1/wa/metrics/schedule/_stats",
    getWhatsAppRevenueData: "/api/v1/wa/metrics/revenue/_stats",
    getEmailTemplatePie: "/api/v1/reports/getEmailTemplatePie",
    getTopEnterprisesForEmail: "/api/v1/reports/email/revenue/topEnterprise",
    getTopPackagesForEmail: "/api/v1/reports/email/revenue/topPackages",
    getRevenueTrendEmail: "/api/v1/reports/email/revenue/totalRevenue",
    getAllEmailCampaignRevenue: "/api/v1/reports/email/revenue/campaigns",
    getAllEmailVolPanel: "/api/v1/metrics/email/summarypanel/_stats",
    //Report APIs
    getWADetailedReport: "/api/v1/reports/{ch}/_details",
    getDetailedReport: "/api/v1/reports/{ch}/{type}/_detail",
    getSummaryReport: "/api/v1/reports/{ch}/{type}/_stats",
    getOfflineReport: "/api/v1/reports/{ch}/{type}/_view",
    cancelReport: "/api/v1/reports/{ch}/{type}/delete/{trackerId}",
    getEMAILSummaryReport: "/api/v1/reports/{ch}/_summary",
    getWADetailedReport: "/api/v1/reports/{ch}/_details",
    getWASummaryReport: "/api/v1/reports/{ch}/_summary",
    //get countries
    // getCountries: "/pkgmgmt/pkg_rate/getcountries",
    //krackend url clean up get countries
    getCountries: "/api/v1/platform/countries",
    //get transaction history
    getTxnHistory: "/api/v1/txnlog/transactions",
    //payment gateway
    paymentGateway: "/api/v1/paygate/paymentGateway",
    // Voice Blast
    Job1CampaignUpload: "/api/v1/campaigns/voice/simpleupload",
    Job1CampaignSubmit: "/api/v1/campaigns/voice/simplesubmit",
    Job3CampaignUpload: "/api/v1/campaigns/voice/templateupload",
    Job3CampaignSubmit: "/api/v1/campaigns/voice/templatesubmit",
    //File Manager
    listfiles: "/api/v1/file_service/listfiles",
    getfile: "/api/v1/file_service/getfile/{id}",
    deletefile: "/api/v1/file_service/deletefile/{id}",
    _upload: "/api/v1/file_service/_upload",
    // getCountries: "/pkgmgmt/pkg_rate/getcountries",
    //krackend url clean up get countries
    getCountries: "/api/v1/platform/countries",
    //get transaction history
    getTxnHistory: "/api/v1/txnlog/transactions",
    //Voice Report Api's
    getVoiceDetailedReport: "/api/v1/reports/voice/{report_desc}/_detail",
    //Bridge Call
    bridgeCall: "/api/v1/call/bridgecall/",
    // Dialler api call
    makeDiallerCall: "/api/v1/campaigns/voice/bridge/_submit",
    // CallLogs
    getCallLog: "/api/v1/logs/callLog",
    //Audio For TTS
    audioForTextToSpeech: "/api/v1/call/voice/audiofortext",
    //email-campaign
    getEmailTemplatesList: "/api/v1/email/templates",
    bulkcampaignsend: "/api/v1/campaigns/email/send",
    // getdistlistEmail: "/api/v1/distributionsEmail",
    templateUpload: "/api/v1/campaigns/email/template/inline/send",
    simpleUpload: "/api/v1/campaigns/email/inline/send",
    uploadEmailAttachments: "/api/v1/emailAttachments/upload",
    getConfig: "/api/v1/global/config",
    getInboundRateSheetLink: "/api/v1/packages/account/{id}/voice/inbound",
    getEmailRevenueData: "/api/v1/metrics/campaigns/email/_status",
    getWhatsAppProfile: "/api/v1/whatsapp/profiles/enterprises/_count",
    getWhatsAppProfileChannels: "/api/v1/whatsapp/profiles/enterprises/",
    getWhatsAppProfileMeta: "/api/v1/whatsapp/profiles/enterprises/_count",
    getWhatsAppProfileChannelsMeta: "/api/v1/whatsapp/profiles/enterprises/",
    getConfig: "/api/v1/global/config",
    getGlobalConfigNoAuth: "/api/v1/global/noauth/config",
    //brand changes
    setBrandSettings: "/api/v1/config/brand/settings",
    //DTMF API
    dtmf: "/api/v1/metrics/voice/campaign/{id}/_dtmf",
    getInboundRateSheetLink: "/api/v1/packages/account/{id}/voice/inbound",
    totalCampaignsCountForVoice: "/api/v1/metrics/campaigns/voice/_stats",
    revenueDataForVoice: "/api/v1/reports/revenue/voice/totalRevenue",
    revenueTrendDataForVoice: "/api/v1/reports/revenue/voice/totalRevenue",
    // Wa simualtor
    waCreateChannelSimulator: "/wasimulator/create/channel",
    //Segment api
    getSegmentList: "/api/v1/segment",
    getSegment: "/api/v1/segment/{segmentId}",
    getSegmentParamList: "/api/v1/segment/fields",
    getSegmentParamCount: "/api/v1/segment/records/_count",
    getSegmentParamFieldValue: "/api/v1/segment/field/{id}",
    //MFA API
    sentOTP: "/api/v1/accounts/users/sendLoginOTP",
    verifyMFAotp: "/api/v1/notification/otp/verifyOTP",
    loginMfa: "/api/v1/accounts/users/authenticate",
    getConfigMfa: "/api/v1/global/config/resendOTP?id=14bc6fe6-bf93-410c-8e84-525dd749503b",
    //IP WHitelisting API
    listing: "/api/v1/accounts/whitelisting/",
    create: "/api/v1/accounts/whitelisting/",
    edit: "/api/v1/accounts/whitelisting/",
    delete: "/api/v1/accounts/whitelisting/",
    whitelistfilterApi: "/api/v1/accounts/whitelisting/",
    toggle: "/api/v1/accounts/whitelist/toggle",
    remove: "/api/v1/accounts/whitelist/remove/",
    getApplicationDetails: "/leap_gw/apps/{id}",
    updateApplicationDetails: "/leap_gw/apps/{id}/update",
    deleteApp: "/leap_gw/apps/{id}/delete",
    getApps: "/leap_gw/apps",
    createAppInfo: "/leap_gw/apps",
    createAppWithAI: "/leap_gw/apps/ai",
    cloneApp: "/leap_gw/apps/clone/{id}",
    exportApp: "/leap_gw/api/app/export/{id}",
    cloneTemplate: "/leap_gw/api/app/templates/clone/",
    getTemplatesForStudio: "/leap_gw/api/app/templates",
    getAppsforAdmin: "/leap_gw/apps/getAppsForAdmin",
    importApp: "/leap_gw/api/app/import",
    getApplicationStartUrl: "/leap_gw/apps/getTriggerEndpoints",
    getParsedCurl: "/leap_gw/utils/ConvertCurl",
    getWhatsappOptions: "/api/v1/whatsapp/channels",
    getQuickAccessList: "/leap_gw/apps/ai/getQueries",
    getAppReports: "/leap_gw/reports/getAppReports",
    listAppVersions: "/leap_gw/apps/listAppVersions/{id}",
    getAppSnapshot: "/leap_gw/apps/getAppSnapshot/{id}",
    getDetailedAppReport: "/leap_gw/reports/getDetailedAppReport",
    updateAppSnapshot: "/leap_gw/apps/updateAppSnapshot/{id}",
    //content filter API
    contentfilterlisting: "/api/v1/config/listwords",
    createblockedword: "/api/v1/config/addword",
    createreplaceword: "/api/v1/config/addword",
    uploadblockedword: "/api/v1/config/uploadBlocked",
    uploadreplacedword: "/api/v1/config/uploadReplace",
    deleteblockedword: "/api/v1/config/deletewords",
    //Payment Provider APIs
    getAllPaymentProviders: "/api/v1/pps/providers",
    getPaymentProviderById: "/api/v1/pps/providers/{id}",
    requestPayment: "/api/v1/pps/providers/{ppId}/process",
    checkPaymentStatus: "/api/v1/pps/providers/status/{txnId}",
    //E& payment APIs
    registrationForEAnd: "/api/v1/pps/eand/registration",
    finalizationForEAnd: "/api/v1/pps/eand/finalization",
    //activation link shortening API
    getActivationLink: "/api/v1/notification/activationLink",
    //blacklist APIs
    getBlackListedNumbers: "/api/v1/blacklist",
    uploadSmsBlacklistFile: "/api/v1/blacklist/upload",
    deleteBlacklistNumber: "/api/v1/blacklist/delete",
    //dnd APIs
    getSmsDndNumbers: "/api/v1/dnd",
    uploadSmsDndFile: "/api/v1/dnd/upload",
    deleteSmsDndtNumber: "/api/v1/dnd/delete",
    //CJB APIs
    updateAppCjb: "/updateApp",
    //Gen AI API's
    fetchAiGroupList: "/ai/v1/groups/list",
    createAiGroup: "/ai/v1/groups/create",
    fetchAiFilesList: "/ai/v1/files/list",
    learnAi: "/ai/v1/files/learn",
    uploadAiFiles: "/ai/v1/files/upload",
    // SMS APIs
    fetchScsa: "/api/v1/numbers/scsa",
    //email API
    emailListing: "/api/v1/email/scsa",
    //rcs campaign
    campaignsend: "/api/v1/rcs/bulk",
    rcsCampaignSimpleLaunch: "/api/v1/campaigns/rcs/send",
    rcsCampaignInlineLaunch: "/api/v1/campaigns/rcs/inline/send",
    rcsCampaignTemplateLaunch: "/api/v1/campaigns/rcs/template/inline/send",
    // RCS TEMPLATE
    getRcsTemplateDetails: "/api/v1/rcs/templates",
    createRcsTemplate: "/api/v1/rcs/templates/create",
    deleteRcsTemplate: "/api/v1/rcs/templates/delete/",
    viewRcsTemplate: "/api/v1/rcs/templates/details/{id}",
    updateRcsTemplateStatus: "/api/v1/rcs/templates/updateStatus/{id}",
    editRcsTemplate: "/api/v1/rcs/templates/edit/{id}",
    //rcs agentprofiles
    getAgentProfilesList: "/api/v1/rcs/agents/list",
    getAgentProfilesByID: "/api/v1/rcs/agents",
    uploadAgentImageApi: "/api/v1/rcs/agents/upload",
    createAgentProfilesApi: "/api/v1/rcs/agents/create",
    agentAssociateuser: "/api/v1/rcs/agents/users/{id}",
    agentApproveRejectApi: "/api/v1/rcs/agents/updatestatus/{id}",
    specificationApi: "/api/v1/rcs/agents/specification",
    activateDeactivateAgentApi: "/api/v1/rcs/agents/{id}",
    editAgentProfilesApi: "/api/v1/rcs/agents/update/{id}",
    draftAgentProfilesApi: "/api/v1/rcs/agents/draft",
    updateDraftAgentProfilesApi: "/api/v1/rcs/agents/draft/{id}",
    //invoicing APIs
    getInvoiceDetails: "/api/v1/accounts/invoice",
    getInvoiceMonths: "/api/v1/accounts/invoice/invoiceMonths",
    sendMailWithInvoiceData: "/api/v1/accounts/invoice/sendEmail",
    //GenAI Composer:
    genAiComposer: "/content_enrich",
    sendBulkMail: "/api/v1/invoice/bulkDownload",
};
function getAPIMap(name) {
    return APIMapping[name];
}
export default getAPIMap;
