import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useTheme } from "./context/ThemeContext";
import PaymentTimer from "./pages/PaymentTimer";
var HeaderLayout = lazy(function () { return import("./containers/headerLayout"); });
var EmailVerification = lazy(function () { return import("./pages/EmailVerification"); });
var MFAOtpVerification = lazy(function () { return import("./admin/pages/MfaOtp"); });
var SetPassword = lazy(function () { return import("./pages/SetPassword"); });
var ForwardActivationLink = lazy(function () {
    return import("./pages/ForwardActivationLink");
});
var ResetPassword = lazy(function () { return import("./pages/RePassword"); });
var SuccessOTP = lazy(function () { return import("./admin/pages/SuccessOtp"); });
var FailureOTP = lazy(function () { return import("./admin/pages/FailureOtp"); });
var TransactionCompleteCallback = lazy(function () {
    return import("./pages/TransactionCompleteCallback");
});
function App() {
    var i18n = useTranslation().i18n;
    var val = i18n.resolvedLanguage;
    var _a = useTheme(), isThemeLoading = _a.isThemeLoading, isAuthConfigLoading = _a.isAuthConfigLoading;
    useEffect(function () {
        if (val === "ar") {
            document.body.dir = "rtl";
        }
        else {
            document.body.dir = "ltr";
        }
    }, [val, i18n]);
    return (_jsx(_Fragment, { children: !isThemeLoading && !isAuthConfigLoading && (_jsxs(Router, { children: [_jsx(AccessibleNavigationAnnouncer, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "/auth/*", element: _jsx(ForwardRoute, {}) }), _jsx(Route, { path: "/mfaverification", element: _jsx(MFAOtpVerification, {}) }), _jsx(Route, { path: "/successOTP", element: _jsx(SuccessOTP, {}) }), _jsx(Route, { path: "/failureOTP/:errorMessage", element: _jsx(FailureOTP, {}) }), _jsx(Route, { path: "/paymentComplete", element: _jsx(TransactionCompleteCallback, {}) }), _jsx(Route, { path: "/:userid/:usertoken/:mobileNumber/_vlink", element: _jsx(SetPassword, {}) }), _jsx(Route, { path: "/activate/:nanoId", element: _jsx(ForwardActivationLink, {}) }), _jsx(Route, { path: "/:userid/:usertoken/_rlink", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "/app/header/*", element: _jsx(HeaderLayout, {}) }), _jsx(Route, { path: "/app/*", element: _jsx(ProtectedRoute, {}) }), _jsx(Route, { path: "/verify-email", element: _jsx(EmailVerification, {}) }), _jsx(Route, { path: "/payment-timer", element: _jsx(PaymentTimer, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/auth" }) })] })] })) }));
}
export default App;
