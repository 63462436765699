var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import getAPIMap from "../api_map.js";
import axios from "axios";
import { roleName } from "../constants_map";
// const IP = process.env.REACT_APP_IP;
import getIP from "../getIP.js";
var IP = getIP();
/**
 * This method is used to get the web notifications of the user
 */
var getNotifications = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, response;
    var queryKey = _b.queryKey, _c = _b.pageParam, pageParam = _c === void 0 ? 1 : _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("getNotifications") + "?pgSize=".concat(50, "&pgIndex=").concat(pageParam);
                //var url = IP + getAPIMap("getNotifications") + `?paginate=0`;
                url = url.concat("&userId=", queryKey[2]);
                if (queryKey[3]) {
                    url = url.concat("&from=", queryKey[3]);
                }
                if (queryKey[4]) {
                    url = url.concat("&search=", queryKey[4]);
                }
                if (queryKey[5]) {
                    url = url.concat("&startDate=", queryKey[5]);
                }
                if (queryKey[6]) {
                    url = url.concat("&endDate=", queryKey[6]);
                }
                return [4 /*yield*/, axios.get(url, config)];
            case 1:
                response = _d.sent();
                return [2 /*return*/, { response: response, nextPage: pageParam + 1 }];
        }
    });
}); };
/**
 * This method is used to get the unread notification count
 */
var getUnreadCount = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("unreadNotificationCount");
                url = url.concat("?userId=", queryKey[2]);
                return [4 /*yield*/, axios.get(url, config)];
            case 1: return [2 /*return*/, _c.sent()];
        }
    });
}); };
/**
 * This method is used to send notification to the required user
 */
var sendNotifications = function (options) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(options.userDetails.token),
                    },
                };
                url = IP + getAPIMap("sendNotifications");
                return [4 /*yield*/, axios.post(url, options.req, config)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
/**
 * This method is used to delete notifications */
var deleteNotifications = function (options) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(options.userDetails.token),
                    },
                };
                url = IP + getAPIMap("deleteNotification");
                return [4 /*yield*/, axios.post(url, options.req, config)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
/**
 * This method is used to update notification status
 */
var updateNotificationStatus = function (options) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(options.userDetails.token),
                    },
                };
                url = IP + getAPIMap("updateNotification");
                return [4 /*yield*/, axios.put(url, options.req, config)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
/**
 * This method is used to get the topics
 */
var getTopics = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, role, url;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(queryKey[1].token),
                    },
                };
                role = queryKey[1].realm_access.roles[0].split("~");
                url = IP + getAPIMap("getTopics");
                if (role[2] === roleName.platformAdmin) {
                    url = url + "?visibility=platform&access_level=public";
                }
                else if (role[2] === roleName.enterpriseAdmin) {
                    url = url + "?visibility=enterprise_admin";
                }
                else if (role[2] === roleName.enterpriseUser) {
                    url = url + "?visibility=enterprise";
                }
                return [4 /*yield*/, axios.get(url, config)];
            case 1: return [2 /*return*/, _c.sent()];
        }
    });
}); };
/**
 * This method is used to get the subscribed topics of user
 */
var getSubscribedTopics = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("getsubscribedTopics");
                url = url.replace("{uid}", queryKey[1].sub);
                return [4 /*yield*/, axios.get(url, config)];
            case 1: return [2 /*return*/, _c.sent()];
        }
    });
}); };
/**
 *
 *This method is used to subscribe to topics
 */
var subscribeToTopics = function (options) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(options.userDetails.token),
                    },
                };
                url = IP + getAPIMap("subscribeTopic");
                return [4 /*yield*/, axios.post(url, options.req, config)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export { getNotifications, sendNotifications, deleteNotifications, updateNotificationStatus, getUnreadCount, getTopics, subscribeToTopics, getSubscribedTopics, };
