var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useContext } from "react";
import routes from "../../routes/sidebar";
import MENU from "./Menu";
import { SidebarContext } from "../../context/SidebarContext";
import { ReactComponent as Closeicon } from "../../icons/leftArrow.svg";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "react-query";
import getAPIMap from "../../api_map.js";
import LeftArrow from "../../assets/img/Icon ionic-ios-arrow-down.svg";
import { RightArrow } from "../../icons/index.js";
import getIP from "../../getIP.js";
var IP = getIP();
import { useTheme } from "../../context/ThemeContext.js";
import { hexToRgb } from "../../utils/colorHelper";
function SidebarContent() {
    var _this = this;
    var _a, _b;
    var closeSidebar = useContext(SidebarContext).closeSidebar;
    var userDetails = useContext(AuthContext).userDetails;
    var _c = useContext(AuthContext), collapsed = _c.collapsed, setCollapsed = _c.setCollapsed;
    var theme = useTheme().theme;
    //const [twoLines, setTwoLines] = useState(false);
    var menudata = [];
    var isLineAdded = false;
    var _d = useQuery(["/menu", userDetails], function () { return __awaiter(_this, void 0, void 0, function () {
        var URL, config;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    URL = IP + getAPIMap("menu");
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(userDetails.token),
                        },
                    };
                    return [4 /*yield*/, axios.get(URL, config)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }), data = _d.data, isLoading = _d.isLoading;
    if (!isLoading) {
        menudata = (_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.filter(function (e) { return e.name !== "_Chat"; });
    }
    return (_jsxs("div", { style: { backgroundColor: theme.sideMenuColor }, className: collapsed
            ? "w-[70px] h-100vh rounded-[15px] pb-[28px] flex flex-col fixed top-0 lg:top-[105px] bottom-0 lg:bottom-5 overflow-y-scroll"
            : "w-[201px] h-100vh  rounded-[15px] pb-[28px] flex flex-col fixed top-0 lg:top-[105px] bottom-0 lg:bottom-5 overflow-y-scroll", children: [_jsx(Closeicon, { onClick: closeSidebar, className: "h-[20px] w-[20px] fixed top-[15px] ltr:left-[170px] rtl:right-[170px] lg:hidden cursor-pointer" }), _jsx("div", { className: " sticky top-24 ml-auto w-3   ", children: _jsx("button", { className: "  w-3 h-4 justify-center rounded flex place-items-center bg-red-500 ", onClick: function () {
                        setCollapsed(!collapsed);
                    }, children: !collapsed ? (_jsx("img", { src: LeftArrow, className: "h-2 w-2", alt: "arrow" })) : (_jsx(RightArrow, { className: "h-2 w-2", style: { color: "white" } })) }) }), menudata === null || menudata === void 0 ? void 0 : menudata.map(function (route, i) {
                if (route.name) {
                    isLineAdded = false;
                    return _jsx(MENU, { route: route }, "".concat(route.name).concat(i));
                }
                else {
                    if (!isLineAdded) {
                        isLineAdded = !isLineAdded;
                        return (_jsx("div", { className: "mt-[5px] mb-[5px]  px-6 py-3", children: _jsx("hr", { style: {
                                    borderTop: "1px solid rgba(".concat(hexToRgb(theme === null || theme === void 0 ? void 0 : theme.menuTextColor), ", 0.2)"),
                                } }) }, "".concat(route.name).concat(i)));
                    }
                    else {
                        return _jsx("div", {}, i);
                    }
                }
            })] }));
}
export default SidebarContent;
