import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
//import React from "react";
import Checkmark from "../../assets/img/Icon ionic-ios-checkmark-circle.svg";
import error from "../../assets/img/error.svg";
export var ErrorIcon = function () { return (
// <svg
//   {...props}
//   fill="none"
//   strokeLinecap="round"
//   strokeLinejoin="round"
//   strokeWidth="2"
//   viewBox="0 0 24 24"
//   stroke="currentColor"
// >
//   <path d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
// </svg>
_jsx("img", { className: "w-5 h-5 absolute left-0 top-0 ml-4 mt-5", alt: "success", src: error })); };
export var SuccessIcon = function () { return (
// <svg
//   {...props}
//   fill="green"
//   width="12px"
//   height="10px"
//   strokeLinecap="round"
//   strokeLinejoin="round"
//   strokeWidth="10"
//   viewBox="0 0 24 24"
//   strokeBorder="gray"
// >
//   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
// </svg>
_jsx("img", { className: "w-5 h-5 absolute left-0 top-0 ml-4 mt-5", alt: "success", src: Checkmark })); };
function Snackbar(_a) {
    var message = _a.message, isOpen = _a.isOpen, onClose = _a.onClose, type = _a.type;
    var alert = {
        base: "p-5 pl-12 relative   rounded-lg leading-5",
        withClose: "pr-12",
        success: "dark:bg-gray-50 dark:text-gray-900 bg-gray-600 text-white",
        danger: " dark:bg-gray-50 dark:text-gray-900 bg-gray-600 text-white",
        warning: "dark:bg-yellow-50 dark:text-gray-900 bg-gray-600 text-white",
        neutral: "dark:bg-white dark:text-black bg-white text-black",
        info: "dark:bg-blue-50 dark:text-blue-900 bg-blue-600 text-white",
        icon: {
            base: "h-5 w-5",
            success: "dark:text-gray-400 text-gray-300",
            danger: "dark:text-red-400 text-red-300",
            warning: "dark:text-yellow-400 text-yellow-100",
            neutral: "dark:text-black text-black",
            info: "dark:text-blue-400 text-blue-300",
        },
    };
    if (!type)
        type = "neutral";
    var baseStyle = alert.base;
    var withCloseStyle = alert.withClose;
    var typeStyle = alert[type];
    var iconBaseStyle = alert.icon.base;
    var iconTypeStyle = alert.icon[type];
    var cls = baseStyle + " " + typeStyle;
    if (onClose)
        cls += " " + withCloseStyle;
    // const iconCls = iconBaseStyle + " " + iconTypeStyle + ' absolute left-0 top-0 ml-4 mt-4'
    var closeCls = iconBaseStyle + " " + iconTypeStyle;
    var Icon;
    switch (type) {
        case "success":
            Icon = SuccessIcon;
            break;
        case "danger":
            Icon = ErrorIcon;
            break;
        case "warning":
            Icon = ErrorIcon;
            break;
        default:
            Icon = SuccessIcon;
    }
    if (!isOpen) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: type === "neutral"
            ? "z-40 fixed inset-x-0 lg:bottom-1/2 lg:left-0 lg:w-2/3   bottom-0 flex sm:justify-center rounded-lg m-8"
            : "z-40 fixed inset-x-0    bottom-0 flex sm:justify-center rounded-lg m-8", children: _jsx("div", { className: "sm:min-w-1/4 overflow-hidden  shadow-lg sm:max-w-1/3", children: _jsxs("div", { className: cls, role: "alert", children: [onClose && (_jsx("button", { onClick: onClose, className: "absolute top-0 right-0 mt-5 mr-4", "aria-label": "close", children: _jsx("svg", { className: closeCls, fill: type === "neutral" ? "black" : "white", viewBox: "0 0 20 20", role: "img", "aria-hidden": "true", children: _jsx("path", { d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z", clipRule: "evenodd", fillRule: "evenodd" }) }) })), _jsx(Icon, {}), _jsx("span", { className: "mr-10", children: message })] }) }) }));
}
export default Snackbar;
