import { Buffer } from "buffer";
export var decodingJWT = function (token) {
    if (token !== null || token !== undefined) {
        try {
            var base64String = token === null || token === void 0 ? void 0 : token.split(".")[1];
            var decodedValue = JSON.parse(Buffer.from(base64String, "base64").toString("ascii"));
            return decodedValue;
        }
        catch (err) {
            return "";
        }
    }
    return null;
};
