var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import getAPIMap from "../api_map.js";
import axios from "axios";
import getIP from "../getIP.js";
var IP = getIP();
/**
 * This method is used to get the balance amount of user
 * @param {*} param0
 * @returns
 */
export var getBalance = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, response;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("getWalletBalance");
                url = url.replace("{userId}", queryKey[2]);
                return [4 /*yield*/, axios.get(url, config)];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * This method is used to get the balance amount of logged in user
 * @param {*} param0
 * @returns
 */
export var getMyBalance = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, response;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("getMyBalance");
                return [4 /*yield*/, axios.get(url, config)];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * This method is used to credit amount by PA to EA
 * @param {*} id
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */
export var creditAmount = function (id_1, amount_1, userDetails_1) {
    var args_1 = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args_1[_i - 3] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([id_1, amount_1, userDetails_1], args_1, true), void 0, function (id, amount, userDetails, ponumber) {
        var config, url, obj, response;
        if (ponumber === void 0) { ponumber = ""; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    config = {
                        headers: {
                            Authorization: "bearer ".concat(userDetails.token),
                        },
                    };
                    url = IP + getAPIMap("getCredit");
                    obj = { userId: id, amount: amount, txnId: ponumber };
                    return [4 /*yield*/, axios.post(url, obj, config)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    });
};
export var creditAmountTransferType = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url, obj, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "bearer ".concat(payload.userDetails.token),
                    },
                };
                url = IP + getAPIMap("updateCreditLimit");
                obj = {
                    userId: payload.payload.UserId,
                    creditLimit: payload.payload.Amount.toString(),
                };
                return [4 /*yield*/, axios.put(url, obj, config)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
export var debitAmountTransferType = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url, obj, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "bearer ".concat(payload.userDetails.token),
                    },
                };
                url = IP + getAPIMap("updateCreditLimit");
                obj = {
                    userId: payload.payload.UserId,
                    creditLimit: payload.payload.Amount.toString(),
                };
                return [4 /*yield*/, axios.put(url, obj, config)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
//airtel money
export var paymentGateway = function (token, amount, phoneNum) { return __awaiter(void 0, void 0, void 0, function () {
    var config, url, body, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(token),
                    },
                };
                url = IP + getAPIMap("paymentGateway");
                body = {
                    amount: amount,
                    msisdn: phoneNum,
                };
                return [4 /*yield*/, axios.post(url, body, config)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
export var getUsers = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, response;
    var queryKey = _b.queryKey;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(queryKey[1].token),
                    },
                };
                url = IP + getAPIMap("getuser");
                return [4 /*yield*/, axios.get(url, config)];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * This method is used to debit amount from EA by PA
 * @param {*} id
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */
export var debitAmount = function (id_1, amount_1, userDetails_1) {
    var args_1 = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args_1[_i - 3] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([id_1, amount_1, userDetails_1], args_1, true), void 0, function (id, amount, userDetails, ponumber) {
        var config, url, obj, response, error_1;
        if (ponumber === void 0) { ponumber = ""; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    config = {
                        headers: {
                            Authorization: "bearer ".concat(userDetails.token),
                        },
                    };
                    url = IP + getAPIMap("debit");
                    obj = { userId: id, amount: amount, txnId: ponumber };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post(url, obj, config)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _a.sent();
                    return [2 /*return*/, Promise.reject(error_1)];
                case 4: return [2 /*return*/];
            }
        });
    });
};
/**
 * This method is used to transfer amount from EA to EU
 * @param {*} srcId
 * @param {*} destId
 * @param {*} amount
 * @param {*} userDetails
 * @returns
 */
export var transferAmount = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, obj, response, error_2;
    var srcId = _b.srcId, destId = _b.destId, amount = _b.amount, userDetails = _b.userDetails;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "bearer ".concat(userDetails.token),
                    },
                };
                url = IP + getAPIMap("transfer");
                obj = { srcUser: srcId, destUser: destId, amount: amount };
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.post(url, obj, config)];
            case 2:
                response = _c.sent();
                return [2 /*return*/, response];
            case 3:
                error_2 = _c.sent();
                return [2 /*return*/, Promise.reject(error_2)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export function getEnterpriseList(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var config, url, response;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(queryKey[1].token),
                        },
                    };
                    url = IP + getAPIMap("listAccounts");
                    return [4 /*yield*/, axios.get(url, config)];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export function getBuffer(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var config, url, response;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    config = {
                        headers: {
                            Authorization: "Bearer ".concat(queryKey[1].token),
                        },
                    };
                    url = IP + getAPIMap("getBuffer");
                    url = url.replace("{userId}", queryKey[1].sub);
                    return [4 /*yield*/, axios.get(url, config)];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, response];
            }
        });
    });
}
export var updateBuffer = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var config, url, body, response;
    var userId = _b.userId, buffer = _b.buffer, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = {
                    headers: {
                        Authorization: "Bearer ".concat(token),
                    },
                };
                url = IP + getAPIMap("updateBuffer");
                body = { buffer: buffer, userId: userId };
                return [4 /*yield*/, axios.put(url, body, config)];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response];
        }
    });
}); };
